import { queryClient } from 'common/helpers';

import { useMutation } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { createBenefitsRequest } from 'common/services';

const title = 'general.notifications.modules.benefit';

export const useCreateBenefitsList = ({ t }) => {
  const noticeID = 'creatingBenefitsList';

  return useMutation({
    mutationFn: requestData => {
      return createBenefitsRequest(requestData);
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.adding')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.benefits] });

      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.added')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};
