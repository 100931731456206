import { API_LIST } from 'common/constants';
import { patch, post } from 'common/helpers';

const path = API_LIST.PAYMENTS.GENERAL;

export const updateSubscriptionDurationRequest = async ({ id, reqData }) => {
  const { data } = await patch(`${path}/price-community-payment/${id}`, reqData);

  return data;
};

export const createSubscriptionForUserRequest = async ({ reqData }) => {
  const { data } = await post(`${path}/price-community-payment`, reqData);

  return data;
};
