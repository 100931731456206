/* eslint-disable no-underscore-dangle */
import {
  ActionIcon,
  Button,
  Group,
  Input,
  Paper,
  SimpleGrid,
  Space,
  Stack,
  Title,
} from '@mantine/core';
import { cloneElement, useCallback } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';

// Icons
import { IconCirclePlus, IconTrash } from '@tabler/icons';

import { LANGUAGES_COUNT } from 'common/constants';

import { useTranslation } from 'react-i18next';
import translations from './translations';

export const FormListManager = ({
  name,
  title,
  titleColor,
  titleOrder = 5,
  maxItems = LANGUAGES_COUNT,
  buttonTitle = translations.button,
  buttonColor = 'violet',
  buttonVariant = 'filled',
  disabled = false,
  newItemModel = {},
  onRemove,
  renderComponent: Component,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const { fieldState } = useController({
    name,
    control,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const fieldsLength = fields.length;

  const isMaxedFiles = maxItems <= fieldsLength;
  const isCanRemoveItem = !disabled;

  const removeItem = useCallback(
    ({ index, ...rest }) =>
      () => {
        if (typeof onRemove === 'function' && rest?._meta?.toDelete) {
          onRemove(rest);
        }

        return remove(index);
      },
    [remove, onRemove],
  );

  const addItem = useCallback(() => append(newItemModel), [append, newItemModel]);

  return (
    <SimpleGrid>
      {Boolean(fields?.length) && (
        <Stack>
          {fields.map(({ id, ...rest }, index) => {
            const handleRemove = removeItem({ index, ...rest });
            const fieldName = `${name}.${index}`;

            return (
              <Paper shadow="xs" p="sm" key={id}>
                <Group position="apart" spacing="xs">
                  <Title order={titleOrder} color={titleColor ?? buttonColor}>
                    {title}
                  </Title>
                  <Group position="right" spacing="xs">
                    <ActionIcon
                      variant="outline"
                      color="red"
                      disabled={!isCanRemoveItem}
                      onClick={handleRemove}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>
                </Group>
                <Space h="xs" />
                {cloneElement(Component, { originalName: name, fieldName })}
              </Paper>
            );
          })}
        </Stack>
      )}

      {!isMaxedFiles && (
        <Button
          fullWidth
          variant={buttonVariant}
          color={buttonColor}
          disabled={disabled}
          leftIcon={<IconCirclePlus />}
          onClick={addItem}
        >
          {t(buttonTitle)}
        </Button>
      )}
      {Boolean(fieldState.error) && <Input.Error ml="sm">{fieldState.error?.message}</Input.Error>}
    </SimpleGrid>
  );
};
