import { useController, useFormContext } from 'react-hook-form';

// UIKit
import { TextInput } from '@mantine/core';

export const FormControl = ({ name, inputElement: Element = TextInput, ...otherProps }) => {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return <Element error={error?.message} {...field} value={field.value ?? ''} {...otherProps} />;
};
