import { useQuery } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { getUsersRequest } from 'common/services';

export const useGetUsers = ({ queryParams, enabled = true }) => {
  const adaptQueryParams = () => {
    if (queryParams.search) {
      return { search: queryParams.search };
    }

    return queryParams;
  };

  return useQuery({
    queryKey: [API_TAGS.users, queryParams, enabled],
    queryFn: () => getUsersRequest(adaptQueryParams()),
    enabled,
  });
};
