import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DragAndDropItem } from './DragAndDropItem';

export const DragAndDrop = ({
  list,
  direction = 'vertical',
  onChange,
  listItem,
  listItemProps,
  droppableId = 'dnd-list',
}) => {
  return (
    <DragDropContext onDragEnd={onChange}>
      <Droppable direction={direction} droppableId={droppableId}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((item, index) => (
              <DragAndDropItem
                key={item.id}
                item={item}
                index={index}
                listItem={listItem}
                listItemProps={listItemProps}
                draggableId={item.id}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
