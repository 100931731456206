import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { IconDeviceFloppy } from '@tabler/icons';

import { Button, Select, SimpleGrid, Tabs, Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';

import { Form, FormControl, Loader, SearchableDropdown, Tooltip } from 'common/components/ui';

import { useLangForDatePicker, useTableParams } from 'common/hooks';
import { adaptMetaFormDataForAPI } from 'common/utils';
import { useGetUsers } from 'pages/users/hooks/queries';

import { useTranslation } from 'react-i18next';
import { DEFAULT_VALUES } from './GroupForm.constants';
import { notificationSchema } from './GroupForm.schemas';

import { UserSelectItem } from './UserSelectItem';
import { GroupStreams } from '../GroupStreams/GroupStreams';
import { UsersList } from '../UsersList/UsersList';
import { useAddUserToGroup } from '../../hooks/useAddUserToGroup';
import { useUpdateGroup } from '../../hooks/useUpdateGroup';
import { useGetLessons, useUpdateLessonSchedule } from '../../hooks/useGetLessons';

import translations from '../../translations';
import { usePermissionConfig } from '../../../../common/hooks/usePermissionConfig';
import { PERMISSION_IDS } from '../../../../common/constants';

export const GroupForm = ({ formValues }) => {
  const { t } = useTranslation();
  const [permission] = usePermissionConfig(PERMISSION_IDS.user);

  const lang = useLangForDatePicker();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [lessonStartDate, setLessonStartDate] = useState(null);

  const [queryParams, setQueryParams] = useTableParams({
    page: currentPage,
    search: null,
  });

  const { isLoading: isGetLessons, data: lessons } = useGetLessons(formValues.courseId);

  const formMethods = useForm({
    values: formValues,
    resolver: yupResolver(notificationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const startDate = formMethods.watch('startDate');
  const endDate = formMethods.watch('endDate');
  const meta = formMethods.watch('groupMetas');
  const adaptedMeta = adaptMetaFormDataForAPI(meta.map(item => ({ ...item, lang: 'ua' })));

  const { data, isFetching, isLoading } = useGetUsers({ queryParams, enabled: permission.read });
  const { mutate: addUser } = useAddUserToGroup(formValues?.id);
  const { mutate: updateGroup } = useUpdateGroup({ groupId: formValues?.id, t });
  const { mutate: updateLessonSchedule } = useUpdateLessonSchedule();

  const totalPagesCount = data && Math.ceil(data.pagination.count / data.pagination.limit);

  const onSubmit = () => {
    return updateGroup({
      startDate,
      endDate,
      groupMetas: adaptedMeta,
    });
  };

  const submitLessonSchedule = () => {
    updateLessonSchedule({
      courseID: formValues.courseId,
      lessonID: currentLesson,
      reqData: {
        groupId: formValues.id,
        startDate: lessonStartDate,
      },
    });
  };

  const { handleSubmit } = formMethods;

  const excludeDate = useCallback(
    date => {
      return dayjs(date).isBefore(dayjs(startDate), 'date');
    },
    [startDate],
  );

  if (isGetLessons) {
    return <Loader />;
  }

  return (
    <>
      {permission.read && (
        <SearchableDropdown
          renderElement={({ item }) => <UserSelectItem addUser={addUser} item={item} />}
          data={data?.users}
          totalPagesCount={totalPagesCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          placeholder={t(translations.children.edit.fields.search.placeholder)}
          label={t(translations.children.edit.fields.search.label)}
          fetching={isFetching || isLoading}
        />
      )}

      <Form formMethods={formMethods} onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid cols="3">
          <FormControl
            label={t(translations.children.edit.fields.groupId.label)}
            placeholder={t(translations.children.edit.fields.groupId.placeholder)}
            name="id"
            readOnly
          />
          <FormControl
            label={t(translations.children.edit.fields.courseId.label)}
            placeholder={t(translations.children.edit.fields.courseId.placeholder)}
            name="courseId"
            readOnly
          />
          <FormControl
            label={t(translations.children.edit.fields.courseName.label)}
            placeholder={t(translations.children.edit.fields.courseName.placeholder)}
            name="courseName"
            readOnly
          />
        </SimpleGrid>

        <SimpleGrid cols="3">
          <Controller
            control={formMethods.control}
            name="startDate"
            render={({ field }) => (
              <DatePicker
                label={t(translations.children.edit.fields.startDate.label)}
                placeholder={t(translations.children.edit.fields.startDate.placeholder)}
                value={field.value}
                onChange={field.onChange}
                locale={lang}
              />
            )}
          />
          <Controller
            control={formMethods.control}
            name="endDate"
            render={({ field }) => (
              <DatePicker
                label={t(translations.children.edit.fields.endDate.label)}
                placeholder={t(translations.children.edit.fields.endDate.placeholder)}
                value={field.value}
                onChange={field.onChange}
                excludeDate={excludeDate}
                locale={lang}
              />
            )}
          />
          <FormControl
            name="groupMetas.0.title"
            label={t(translations.children.edit.fields.title.label)}
            placeholder={t(translations.children.edit.fields.title.placeholder)}
            withAsterisk
          />
        </SimpleGrid>

        <Tabs defaultValue="users" mt="xl" variant="pills">
          <Tabs.List grow>
            <Tabs.Tab value="users">
              {t(translations.children.edit.tabs.users)} ({formValues?.usersCount})
            </Tabs.Tab>
            <Tabs.Tab value="streams">{t(translations.children.edit.tabs.group)}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="users" pt="xs">
            <UsersList groupId={formValues?.id} />
          </Tabs.Panel>

          <Tabs.Panel value="streams" pt="xs">
            <GroupStreams />
          </Tabs.Panel>
        </Tabs>
      </Form>

      <Text
        sx={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>{t(translations.children.edit.fields.lessonSchedule)}</span>
        <Tooltip label={t('tooltips.groups-lesson-schedule')} />
      </Text>
      <SimpleGrid cols="2">
        <Select
          label={t(translations.children.edit.fields.pickLesson.label)}
          placeholder={t(translations.children.edit.fields.pickLesson.placeholder)}
          data={lessons}
          onChange={setCurrentLesson}
        />
        <DatePicker
          placeholder={t(translations.children.edit.fields.pickDate.placeholder)}
          label={t(translations.children.edit.fields.pickDate.label)}
          value={lessonStartDate}
          onChange={setLessonStartDate}
          locale={lang}
        />
      </SimpleGrid>
      <Button
        leftIcon={<IconDeviceFloppy />}
        onClick={submitLessonSchedule}
        disabled={!currentLesson || !lessonStartDate}
      >
        {t(translations.children.edit.buttons.updateLesson)}
      </Button>
    </>
  );
};
