const translations = {
  itemLesson: 'pages.courses.children.create.step-2.item-lesson',
  sections: {
    content: {
      title: 'pages.courses.children.create.step-2.sections.content.title',
    },
  },
  fields: {
    delay: {
      label: 'pages.courses.children.create.step-2.fields.delay.label',
      placeholder: 'pages.courses.children.create.step-2.fields.delay.placeholder',
    },
    title: {
      label: 'pages.courses.children.create.step-2.fields.title.label',
      placeholder: 'pages.courses.children.create.step-2.fields.title.placeholder',
    },
    description: {
      label: 'pages.courses.children.create.step-2.fields.description.label',
      placeholder: 'pages.courses.children.create.step-2.fields.description.placeholder',
    },
  },
};

export default translations;
