export const adaptCategoriesForSelect = (categories, lang) => {
  if (categories) {
    return categories.map(item => ({
      value: item.id,
      label: item.meta.find(metaItem => metaItem.lang === lang).value,
    }));
  }

  return [];
};
