import { Grid, NumberInput, Textarea, Box } from '@mantine/core';

import { BenefitsForm } from 'features/benefits/BenefitsForm';
import { useGetCategories } from 'common/hooks/api/categories';
import { FormControl, FormSelect, LabelWithTooltip } from 'common/components/ui';
import { FormFileInput } from 'common/components/ui/FormFileInput/FormFileInput';
import { COURSE_STATUS_OPTIONS } from 'common/constants';
import { adaptCategoriesForSelect } from 'common/utils';
import { useGetBenefits } from 'common/hooks/api/benefits/useGetBenefits';

import { useTranslation } from 'react-i18next';
import { FormComponent } from '../FormComponent/FormComponent';
import { createCourseDefaultValues } from './CourseForm.constants';
import { courseSchema } from './CourseForm.schemas';

import translations from './translations';

export const FormCourse = props => {
  const { t, i18n } = useTranslation();
  const { courseId } = props;

  const { data } = useGetCategories();
  const {
    data: benefits,
    isFetching: isBenefitsFetching,
    isLoading: isBenefitsLoading,
  } = useGetBenefits({ courseId, limit: 9 });

  return (
    <>
      <FormComponent
        {...props}
        defaultValues={createCourseDefaultValues('ua')}
        validationSchema={courseSchema(t)}
      >
        <Grid>
          <Grid.Col xs={9}>
            <FormControl
              name="courseMetas[0].title"
              label={t(translations.step1.fields.name.label)}
              placeholder={t(translations.step1.fields.name.placeholder)}
              withAsterisk
            />
            <Box sx={{ marginTop: 16 }}>
              <FormControl
                name="courseMetas[0].description"
                label={t(translations.step1.fields.description.label)}
                placeholder={t(translations.step1.fields.description.placeholder)}
                inputElement={Textarea}
                minRows={8}
                withAsterisk
              />
            </Box>
          </Grid.Col>
          <Grid.Col xs={3}>
            <FormSelect
              label={
                <LabelWithTooltip
                  text={t(translations.step1.fields.status.label)}
                  tooltipText={t('tooltips.course-status')}
                />
              }
              placeholder={t(translations.step1.fields.status.placeholder)}
              name="status"
              data={COURSE_STATUS_OPTIONS}
              sx={{ Label: { display: 'inline-flex' } }}
              withAsterisk
            />
            <Box sx={{ marginTop: 16 }}>
              <FormControl
                label={
                  <LabelWithTooltip
                    text={t(translations.step1.fields.groupCapacity.label)}
                    tooltipText={t('tooltips.course-group-capacity')}
                  />
                }
                placeholder={t(translations.step1.fields.groupCapacity.placeholder)}
                name="groupCapacity"
                inputElement={NumberInput}
                sx={{ Label: { display: 'inline-flex' } }}
                hideControls
                withAsterisk
              />
            </Box>
            <Box sx={{ marginTop: 16 }}>
              <FormSelect
                label={t(translations.step1.fields.category.label)}
                placeholder={t(translations.step1.fields.category.placeholder)}
                name="categoryId"
                data={adaptCategoriesForSelect(data?.categories, i18n.language)}
                withAsterisk
              />
            </Box>
            <Box sx={{ marginTop: 16 }}>
              <FormFileInput
                label={t(translations.step1.fields.logo.label)}
                placeholder={t(translations.step1.fields.logo.placeholder)}
                name="generalImage"
              />
            </Box>
          </Grid.Col>
        </Grid>
      </FormComponent>

      {benefits?.benefits && courseId && (
        <BenefitsForm
          benefits={benefits?.benefits}
          courseId={courseId}
          isLoading={isBenefitsLoading}
          isFetching={isBenefitsFetching}
        />
      )}
    </>
  );
};
