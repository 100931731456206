export const isFileInstance = file => file instanceof File;

export const checkIfFilesAreCorrectType = (file, fileType = ['image/png']) => {
  let isValid = true;

  if (isFileInstance(file) && !fileType.includes(file.type)) {
    isValid = false;
  }

  return isValid;
};

export const checkIfFilesAreCorrectSize = (file, maxSize = 1) => {
  let isValid = true;

  if (isFileInstance(file)) {
    const fileSize = +(file.size / 1024 / 1024).toFixed(4); // To mb

    if (fileSize > maxSize) {
      isValid = false;
    }
  }

  return isValid;
};
