import { API_LIST } from 'common/constants';
import { destroy, get, post, put } from 'common/helpers';

const path = API_LIST.LOCALES.GENERAL;

export const getLocalesRequest = async (params = { page: 1, limit: 10 }) => {
  const { data } = await get(path, { params });

  return data;
};

export const getLocaleByIdRequest = async localeID => {
  const { data } = await get(`${path}/${localeID}`);

  return data;
};

export const deleteLocaleByIdRequest = async localeID => {
  const { data } = await destroy(`${path}/${localeID}`);

  return data;
};

export const updateLocaleByIdRequest = async localeID => {
  const { data } = await put(`${path}/${localeID}`);

  return data;
};

export const createLocaleRequest = async () => {
  const { data } = await post(path);

  return data;
};
