import { useQuery } from '@tanstack/react-query';
import { API_TAGS } from '../../constants';
import { getUsersMe } from '../../services';

export const useGetUserData = isAuthenticated => {
  return useQuery({
    queryKey: [API_TAGS.userMy],
    queryFn: () => getUsersMe(),
    initialData: () => ({}),
    enabled: Boolean(isAuthenticated),
  });
};
