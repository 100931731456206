export const META_ITEM = {
  lang: '',
  title: '',
};

export const DEFAULT_VALUES = {
  endDate: '',
  groupStreams: [],
  users: [],
  startDate: '',
  groupMetas: [META_ITEM],
};
