import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { queryClient } from 'common/helpers';
import { API_TAGS } from 'common/constants';

import { adaptFormCourseDatForAPI } from '../CourseEditor.utils';
import { mutateCourse } from '../CourseEditor.lib';

const title = 'general.notifications.modules.course';

export const useUpdateCourse = ({ courseID, onSuccess, t }) => {
  const noticeID = 'course';

  return useMutation({
    mutationFn: requestData => {
      const adaptedData = adaptFormCourseDatForAPI(requestData);

      return mutateCourse({ courseID, requestData: adaptedData });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updating')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.course, courseID] });
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updated')}`,
      });

      if (onSuccess) onSuccess();
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
