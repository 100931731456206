import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, Tabs } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Loader } from 'common/components/ui/Loader/Loader';
import { useGetMyBusinessAccount } from 'common/hooks/api/useGetMyBusinessAccount';
import { pricesTabs } from 'common/constants';
import { getCurrentTabPrices } from 'common/utils/prices';
import { getUserBusinessAccountSelector } from 'app/store/user/user.selectors';

import { useGetCoursePrices } from './hooks';
import { PricingForm } from '../../../../ui/PricingForm/PricingForm';

export const PricesEditor = ({ courseID }) => {
  const [currentTab, setCurrentTab] = useState(pricesTabs.all);

  const { t } = useTranslation();

  const myBusinessAccount = useSelector(getUserBusinessAccountSelector);

  const { data, isFetching, isLoading } = useGetCoursePrices(
    courseID,
    {
      isActive: getCurrentTabPrices(currentTab),
    },
    currentTab,
  );
  const { data: myBusinessAccountInfo } = useGetMyBusinessAccount(myBusinessAccount?.id);

  if (isFetching || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Text size={14} color="#C1C2C5">
        {t('features.pricing.title')}
      </Text>

      {!!data.length && (
        <Tabs defaultValue={currentTab} variant="pills">
          <Tabs.List grow>
            <Tabs.Tab value={pricesTabs.all} onClick={() => setCurrentTab(pricesTabs.all)}>
              {t('features.pricing.fields.all')}
            </Tabs.Tab>
            <Tabs.Tab value={pricesTabs.active} onClick={() => setCurrentTab(pricesTabs.active)}>
              {t('features.pricing.fields.active-plural')}
            </Tabs.Tab>
            <Tabs.Tab
              value={pricesTabs.inactive}
              onClick={() => setCurrentTab(pricesTabs.inactive)}
            >
              {t('features.pricing.fields.inactive')}
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
      )}

      <PricingForm
        pricingList={data}
        courseId={courseID}
        myBusinessAccount={myBusinessAccountInfo?.businessAccount}
        country={myBusinessAccountInfo?.country}
      />
    </>
  );
};
