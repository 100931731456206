import { useState } from 'react';
import { Outlet } from 'react-router-dom';

// UIKit
import { AppShell } from '@mantine/core';

// Configs
import { appShellConfig } from 'app/configs/theme';

// Components
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';

export const MainLayout = () => {
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      styles={appShellConfig}
      header={<Header opened={opened} setOpened={setOpened} />}
      navbar={<Sidebar opened={opened} />}
    >
      <Outlet />
    </AppShell>
  );
};
