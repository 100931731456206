import { API_LIST } from 'common/constants';
import { get, post } from 'common/helpers';

const path = API_LIST.STRIPE.GENERAL;

export const createConnectedAccountRequest = async () => {
  const { data } = await post(`${path}/create-connected-account`);

  window.open(data.url, '_blank');

  return data;
};

export const getLoginLinkRequest = async () => {
  const { data } = await get(`${path}/login-link`);

  window.open(data.url, '_blank');

  return data;
};
