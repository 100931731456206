import { adaptMetaAPIDataForForm, adaptMetaFormDataForAPI } from 'common/utils';

export const adaptPriceAPIDataForForm = price => {
  const titleMeta = adaptMetaAPIDataForForm(price.meta.filter(item => item.property === 'title'));
  const listMeta = price.meta
    .filter(item => item.property === 'list')
    .map(item => ({ lang: item.lang, [item.property]: item.value }));

  return {
    ...price,
    listMeta,
    titleMeta,
    title: titleMeta[0]?.title,
    priceCourseLessons: price.priceCourseLessons.map(item => item.lessonId),
  };
};

export const adaptPriceFormDataForAPI = ({
  price,
  title,
  listMeta,
  isActive,
  isTrialEnabled,
  trialDayDuration,
  courseId,
}) => {
  const meta = [
    ...adaptMetaFormDataForAPI(listMeta),
    ...adaptMetaFormDataForAPI([{ title, lang: 'ua' }]),
  ];

  return {
    price,
    isActive,
    isTrialEnabled,
    trialDayDuration,
    meta,
    courseId,
  };
};
