import { destroy, get, post, put } from 'common/helpers';
import { API_LIST } from 'common/constants';

const path = API_LIST.NOTIFICATIONS.NOTIFICATIONS;

export const getNotificationsRequest = async (params = { page: 1, limit: 10 }) => {
  const { data } = await get(path, { params });

  return data;
};

export const createNotificationRequest = async reqData => {
  const { data } = await post(path, reqData);

  return data;
};

export const postNotificationAttachUsersRequest = async ({ notificationID, reqData }) => {
  const { data } = await post(`${path}/${notificationID}/attach`, reqData);

  return data;
};

export const postNotificationAttachGroupsRequest = async ({ notificationID, reqData }) => {
  const { data } = await post(`${path}/${notificationID}/attach-group`, reqData);

  return data;
};

export const postNotificationAttachAllUsersRequest = async ({ notificationID, reqData }) => {
  const { data } = await post(`${path}/${notificationID}/attach-all-users`, reqData);

  return data;
};

export const deleteNotificationByIdRequest = async notificationID => {
  const { data } = await destroy(`${path}/${notificationID}`);

  return data;
};

export const getNotificationByIdRequest = async notificationID => {
  const { data } = await get(`${path}/${notificationID}`);

  return data;
};

export const updateNotificationByIDRequest = async (notificationID, reqData) => {
  const { data } = await put(`${path}/${notificationID}`, reqData);

  return data;
};
