import { API_LIST } from 'common/constants';
import { destroy, get, patch, post, put } from 'common/helpers';

const path = API_LIST.BUSINESS_ACCOUNT.GENERAL;

// my account

export const getMyBusinessAccountRequest = async () => {
  const { data } = await get(`${path}/my`);

  return data;
};

export const updateMyBusinessAccountLogoRequest = async ({ businessAccountId, image }) => {
  const formData = new FormData();
  formData.append('image', image);

  const { data } = await post(
    `attachment/lms/images/business-accounts/${businessAccountId}`,
    formData,
    {
      baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
    },
  );

  return data;
};

export const createBusinessAccountRequest = async ({ requestData, files }) => {
  const { data } = await post(path, requestData);

  if (files.length) {
    await updateMyBusinessAccountLogoRequest({
      businessAccountId: data?.businessAccount.id,
      image: files[0],
    });
  }

  return data;
};

export const updateMyBusinessAccountRequest = async ({ requestData, files, id }) => {
  const { data } = await patch(`${path}/my`, requestData);

  if (files.length) {
    await updateMyBusinessAccountLogoRequest({
      businessAccountId: id,
      image: files[0],
    });
  }

  return data;
};

// access rules

export const getBusinessAccountRole = async () => {
  const { data } = await get(`${path}/role`);

  return data;
};

export const getBusinessAccountRoleById = async id => {
  const { data } = await get(`${path}/role/${id}`);

  return data;
};

export const createBusinessAccountRole = async reqData => {
  const { data } = await post(`${path}/role`, reqData);

  return data;
};

export const deleteBusinessAccountRoleById = async id => {
  const { data } = await destroy(`${path}/role/${id}`);

  return data;
};

export const updateRolePermissionsById = async ({ id, reqData }) => {
  const { data } = await put(`${path}/role/${id}/permissions`, reqData);

  return data;
};

export const updateRoleById = async ({ id, reqData }) => {
  const { data } = await patch(`${path}/role/${id}`, reqData);

  return data;
};

export const getMyBusinessAccountEmployeesRequest = async params => {
  const { data } = await get(`${path}/employees`, { params });

  return data;
};

export const deleteBusinessAccountEmployeeById = async employeeID => {
  const { data } = await destroy(`${path}/employees/${employeeID}`);

  return data;
};

export const getBusinessAccountRoles = async () => {
  const { data } = await get(`${path}/role`);

  return data;
};

export const updateEmployeeRoleById = async ({ id, reqData }) => {
  const { data } = await patch(`${path}/employees/${id}/role`, reqData);

  return data;
};

export const getMyBusinessAccountCommunityCategoriesRequest = async () => {
  const { data } = await get(`${path}/community-categories`);

  return data;
};

export const createCommunityRequest = async () => {
  const { data } = await post(`${path}/communities`);

  return data;
};

export const getMyBusinessAccountEarningsRequest = async () => {
  const { data } = await get(`${path}/earnings`);

  return data;
};

export const getMyBusinessAccountTransactionsRequest = async params => {
  const { data } = await get(`${path}/transactions`, { params });

  return data;
};
