import { useQuery } from '@tanstack/react-query';
import { getCourseQuery } from '../../CourseEditor/hooks';
import { adaptLessonsAPIDataForForm } from '../LessonsEditor.utils';

export const useGetLessons = (courseID, lang) => {
  return useQuery({
    ...getCourseQuery(courseID),
    select: ({ course }) => adaptLessonsAPIDataForForm(course, lang),
  });
};
