import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isUserHasAccessSelector } from '../../features/auth/auth.selectors';

const useAuthStatus = () => {
  const isUserHasAccess = useSelector(isUserHasAccessSelector);
  const [isAuthenticated, setIsAuthenticated] = useState(isUserHasAccess);

  useEffect(() => {
    setIsAuthenticated(isUserHasAccess);
  }, [isUserHasAccess]);

  return isAuthenticated;
};

export default useAuthStatus;
