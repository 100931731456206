import { useMemo } from 'react';

import { queryClient } from 'common/helpers';

// Components
import { PageDetails } from 'common/components/layouts';

// Hooks
import { useStepper } from 'common/hooks';

import { useParams, useSearchParams } from 'react-router-dom';

import { Edit } from './components/containers/Edit/Edit';
import { Create } from './components/containers/Create/Create';

import { CourseStepper } from './components/ui/CourseStepper/CourseStepper';
import { getCourseQuery } from './components/containers/Edit/components/CourseEditor/hooks';

export const courseLoader = async ({ params }) => {
  const query = getCourseQuery(params.id);
  const data = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));

  return data;
};

const Course = () => {
  const { id: courseID } = useParams();
  const [searchParams] = useSearchParams();

  const newCourseId = useMemo(() => {
    return searchParams.get('newCourseId');
  }, [searchParams]);

  const isEditMode = Boolean(courseID);

  const [activeStep, toPrevStep, toNextStep, setActiveStep] = useStepper(0);

  return (
    <PageDetails>
      <CourseStepper
        activeStep={activeStep}
        onStepClick={setActiveStep}
        newCourseId={newCourseId}
        courseID={courseID}
      />

      {isEditMode && (
        <Edit
          courseID={courseID}
          activeStep={activeStep}
          toPrevStep={toPrevStep}
          toNextStep={toNextStep}
        />
      )}

      {!isEditMode && (
        <Create
          courseID={newCourseId}
          activeStep={activeStep}
          toPrevStep={toPrevStep}
          toNextStep={toNextStep}
        />
      )}
    </PageDetails>
  );
};

export default Course;
