import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Loader } from 'common/components/ui';

import { publicRoutes } from './publicRoutes';
import { privateRoutes } from './privateRoutes';

const router = createBrowserRouter([...publicRoutes, ...privateRoutes]);

export const Router = () => (
  <Suspense fallback={<Loader />}>
    <RouterProvider router={router} />
  </Suspense>
);
