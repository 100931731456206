import { Checkbox } from '@mantine/core';

// UIKit
import { useController, useFormContext } from 'react-hook-form';

export const FormCheckbox = ({ name, ...otherProps }) => {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <Checkbox
      color="violet"
      error={error?.message}
      {...field}
      checked={field.value}
      {...otherProps}
      delay={0}
    />
  );
};
