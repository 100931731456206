import { MultiSelect } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { FormSelect } from 'common/components/ui';
import { API_TAGS } from 'common/constants';
import { getGroupsRequest } from 'common/services';

const config = {
  queryKey: [API_TAGS.groups, 'select'],
  initialData: () => ({ groups: [] }),
  queryFn: () => getGroupsRequest({ limit: 1000, lang: 'ua' }),
  select: ({ groups }) => groups.map(({ id, name }) => ({ value: id, label: `${name ?? id}` })),
};

export const GroupsSelect = props => {
  const { isLoading, isFetching, data } = useQuery(config);

  return (
    <FormSelect
      searchable
      renderElement={MultiSelect}
      loading={isLoading || isFetching}
      data={data}
      {...props}
    />
  );
};
