import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSearchParams, getCurrentPage } from 'common/hooks';

import { Table } from './Table';
import { DEFAULT_PERMISSION_CONFIG } from '../../../constants';

export const TableWithQueries = ({ permission = DEFAULT_PERMISSION_CONFIG, ...props }) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = getCurrentPage(searchParams.get('page'));

  const toDetailsPage = useCallback(
    ({ id }) => {
      if (permission.update) navigate(`${id}`);
    },
    [navigate, permission.update],
  );

  const onChangePage = useCallback(page => setSearchParams({ page }), [setSearchParams]);

  return (
    <Table onRowClick={toDetailsPage} page={currentPage} onPageChange={onChangePage} {...props} />
  );
};
