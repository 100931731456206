import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './resources';
import { getLanguage } from '../common/utils/language';

const initializeLanguage = getLanguage().key;

i18n.use(initReactI18next).init({
  resources,
  lng: initializeLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
