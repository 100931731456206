import {
  // ActionIcon,
  Burger,
  Header as UIHeader,
  MediaQuery,
  Title,
  // useMantineColorScheme,
  useMantineTheme,
  Flex,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
// import { IconMoonStars, IconSun } from '@tabler/icons';

import { THEMES_ENUM } from 'common/enums';
import { ReactComponent as LogoSvg } from 'common/assets/icons/logo.svg';
import { ReactComponent as LogoDarkSvg } from 'common/assets/icons/logo-dark.svg';
import { UserAvatar } from 'common/components/ui';

import LanguagePicker from '../../ui/LanguagePicker/LanguagePicker';

export const Header = ({ opened = true, setOpened = () => {} }) => {
  // const { toggleColorScheme } = useMantineColorScheme();
  const { colorScheme } = useMantineTheme();
  const darkTheme = colorScheme === THEMES_ENUM.DARK;

  const isPhoneScreen = useMediaQuery('(min-width: 768px)');

  return (
    <UIHeader height={{ base: 50, md: 70 }} p="md" sx={{ backgroundColor: '#000' }}>
      <Flex justify="space-between" align="center" sx={{ height: '100%' }}>
        <Title order={4} color={darkTheme ? 'white' : 'dark'}>
          {darkTheme ? <LogoSvg width={120} /> : <LogoDarkSvg width={120} />}
        </Title>

        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger color="#595959" opened={opened} onClick={() => setOpened(o => !o)} size="sm" />
        </MediaQuery>

        {isPhoneScreen && (
          <>
            <LanguagePicker />
            <UserAvatar />
          </>
        )}

        {/* <ActionIcon variant="filled" onClick={toggleColorScheme}>
          {darkTheme ? <IconSun size={18} /> : <IconMoonStars size={18} />}
        </ActionIcon> */}
      </Flex>
    </UIHeader>
  );
};
