import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { deleteCourseLessonRequest } from 'common/services';
import { invalidateQueries } from 'common/helpers';
import { API_TAGS } from 'common/constants';

const title = 'general.notifications.modules.lesson';

export const useRemoveLesson = ({ courseID, t }) => {
  const noticeID = 'lesson-id';

  return useMutation({
    mutationFn: async ({ lessonID }) => {
      await deleteCourseLessonRequest({ courseID, lessonID });
      await invalidateQueries({ queryKey: [API_TAGS.course, courseID] });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
