const translations = {
  buttons: {
    newLesson: 'pages.courses.children.create.step-2.buttons.new-lesson',
    newTimeCode: 'pages.courses.children.create.step-2.buttons.new-time-code',
    convert: 'pages.courses.children.create.step-2.buttons.convert',
  },
  sections: {
    timeCodes: {
      title: 'pages.courses.children.create.step-2.sections.time-codes.title',
    },
  },
  fields: {
    type: {
      label: 'pages.courses.children.create.step-2.fields.type.label',
      placeholder: 'pages.courses.children.create.step-2.fields.type.placeholder',
    },
    videoDuration: {
      label: 'pages.courses.children.create.step-2.fields.video-duration.label',
      placeholder: 'pages.courses.children.create.step-2.fields.video-duration.placeholder',
    },
    video: {
      label: 'pages.courses.children.create.step-2.fields.video.label',
      placeholder: 'pages.courses.children.create.step-2.fields.video.placeholder',
    },
    timeCode: {
      label: 'pages.courses.children.create.step-2.fields.time-code.label',
      placeholder: 'pages.courses.children.create.step-2.fields.time-code.placeholder',
    },
    timeCodeName: {
      label: 'pages.courses.children.create.step-2.fields.time-code-name.label',
      placeholder: 'pages.courses.children.create.step-2.fields.time-code-name.placeholder',
    },
  },
};

export default translations;
