import {
  createCourseImageRequest,
  createCourseLandingBgImageRequest,
  createCourseLandingImageRequest,
  createCourseRequest,
} from 'common/services';

import { adaptCourseFormDataForAPI } from './Create.utils';

export const createCourseMutate = async ({ requestData, navigate }) => {
  const requests = [];

  const { generalImage, landingImage, landingBackgroundImage, ...courseData } =
    adaptCourseFormDataForAPI(requestData);

  const { course } = await createCourseRequest(courseData);
  const { id: courseID } = course;

  navigate(`${window.location.pathname}?newCourseId=${courseID}`);

  if (generalImage) {
    const request = createCourseImageRequest({
      courseID,
      file: generalImage,
    });
    requests.push(request);
  }

  if (landingImage) {
    const request = createCourseLandingImageRequest({
      courseID,
      file: landingImage,
    });
    requests.push(request);
  }

  if (landingBackgroundImage) {
    const request = createCourseLandingBgImageRequest({
      courseID,
      file: landingBackgroundImage,
    });
    requests.push(request);
  }

  return Promise.all(requests);
};
