import { getButtonBgrStylesByType, getButtonBorderStylesByType, inputsStyles } from './theme.utils';

// TODO: add reusable variables for themeConfig

export const themeConfig = colorScheme => ({
  colorScheme,
  primaryColor: 'blue',
  fontFamily: 'Lato, sans-serif',
  components: {
    Input: {
      styles: inputsStyles,
    },
    Checkbox: {
      styles: theme => ({
        input: {
          background: 'transparent',
          cursor: 'pointer',
          '&:checked': {
            background: theme.fn.linearGradient(
              40,
              '#2BB3FF',
              '#858FEB',
              '#B17CE1',
              '#C785CD',
              '#D28AC3',
            ),
            border: 'none',
          },
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
        },
      }),
    },
    Button: {
      styles: (theme, params) => ({
        root: {
          borderRadius: 10,
          border: getButtonBorderStylesByType({ theme, ...params }),

          color: theme.white,
          background: getButtonBgrStylesByType({ theme, ...params }),

          '&:hover': {
            opacity: 0.9,
          },
          '&[data-disabled]': {
            borderColor: 'transparent',
            backgroundColor: '#373a4070',
            color: '#6d6b6d',
            cursor: 'notAllowed',
            backgroundImage: 'none',
            pointerEvents: 'none',
          },
        },
      }),
    },
    Switch: {
      styles: theme => ({
        track: {
          background: '#3E3E3E',
          border: 'none',
          cursor: 'pointer',
          width: '46px',
          height: '24px',
        },
        thumb: {
          width: '18px',
          height: '18px',
          left: '3px',
        },
        input: {
          '&:checked ~ .mantine-Switch-track': {
            background: `${theme.fn.linearGradient(
              40,
              '#2BB3FF',
              '#858FEB',
              '#B17CE1',
              '#C785CD',
              '#D28AC3',
            )}`,
          },
          '&:disabled ~ .mantine-Switch-track': {
            background: '#373A40',
          },
          '&:checked + * > .mantine-Switch-thumb': {
            left: 'calc(100% - 18px - 3px)',
          },
        },
      }),
    },
    Radio: {
      styles: theme => ({
        radio: {
          background: 'transparent',
          cursor: 'pointer',
          '&:checked': {
            border: 'none',
            background: theme.fn.linearGradient(
              40,
              '#2BB3FF',
              '#858FEB',
              '#B17CE1',
              '#C785CD',
              '#D28AC3',
            ),
          },
        },
      }),
    },
    Navbar: {
      styles: theme => ({
        root: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
          borderColor: '#E9ECEF',
        },
        icon: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
        },
      }),
    },
    Header: {
      styles: theme => ({
        root: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
          borderColor: '#E9ECEF',
        },
      }),
    },
    Pagination: {
      styles: theme => ({
        item: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
          backgroundColor: 'transparent',
          border: '1px solid #595959',
          '&[data-active]': {
            border: 'none',
            background: theme.fn.linearGradient(
              40,
              '#2BB3FF',
              '#858FEB',
              '#B17CE1',
              '#C785CD',
              '#D28AC3',
            ),
          },
        },
      }),
    },
    TextInput: {
      styles: () => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
        inputWrapper: {
          borderColor: '#fff',
        },
      }),
    },
    Textarea: {
      styles: () => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
        inputWrapper: {
          borderColor: '#fff',
        },
      }),
    },
    DatePicker: {
      styles: () => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
        day: {
          '&[data-selected]': {
            background: '#B17CE1',
            color: '#fff',
          },
        },
        calendarHeaderControl: {
          color: '#B17CE1',
        },
        calendarHeaderLevel: {
          color: '#fff',
        },
        monthPickerControlActive: {
          background: '#B17CE1',
          '&:hover': {
            background: '#B17CE1',
            opacity: 0.9,
          },
        },
        yearPickerControlActive: {
          background: '#B17CE1',
          '&:hover': {
            background: '#B17CE1',
            opacity: 0.9,
          },
        },
        inputWrapper: {
          borderColor: '#fff',
        },
      }),
    },
    FileInput: {
      styles: () => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
        inputWrapper: {
          borderColor: '#fff',
        },
      }),
    },
    Select: {
      styles: theme => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
        item: {
          '&:hover': {
            color: theme.white,
            background: '#3E3E3E',
          },

          '&[data-disabled]': {
            background: 'none',
            opacity: 0.5,
            pointerEvents: 'none',
          },

          '&[data-selected]': {
            border: 'none',
            background: theme.fn.linearGradient(
              40,
              '#2BB3FF',
              '#858FEB',
              '#B17CE1',
              '#C785CD',
              '#D28AC3',
            ),
          },
        },
      }),
    },
    MultiSelect: {
      styles: theme => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus-within': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
        item: {
          '&:hover': {
            color: theme.white,
            background: theme.fn.linearGradient(
              40,
              '#2BB3FF',
              '#858FEB',
              '#B17CE1',
              '#C785CD',
              '#D28AC3',
            ),
          },
          '&[data-disabled]': {
            background: 'none',
            opacity: 0.5,
            pointerEvents: 'none',
          },
        },
        defaultValue: {
          color: theme.white,
          borderRadius: '8px',
          background: '#3E3E3E',
        },
      }),
    },
    TimeInput: {
      styles: () => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus-within': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
      }),
    },
    NumberInput: {
      styles: () => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus-within': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
      }),
    },
    PasswordInput: {
      styles: () => ({
        input: {
          borderRadius: '8px',
          background:
            'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #595959, #595959) border-box',
          transition: 'background 100ms ease',
          '&:disabled': {
            background: '#222222',
            border: '1px solid #595959',
          },
          '&:focus-within': {
            borderRadius: '8px',
            border: '1px solid transparent',
            background:
              'linear-gradient(#222, #222) padding-box, linear-gradient(to right, #2BB3FF, #858FEB, #B17CE1, #C785CD, #D28AC3) border-box',
            transition: 'background 100ms ease',
          },
        },
      }),
    },
    Stepper: {
      styles: () => ({
        stepIcon: {
          borderColor: 'transparent',
          backgroundColor: '#222',
          color: '#fff',
          '&[data-progress]': {
            borderColor: '#B17CE1',
            backgroundColor: '#222',
          },
          '&[data-completed]': {
            borderColor: '#B17CE1',
            backgroundColor: '#B17CE1',
          },
        },
        separatorActive: {
          backgroundColor: '#B17CE1',
        },
        stepLabel: {
          color: '#fff',
        },
      }),
    },
    DateRangePicker: {
      styles: () => ({
        day: {
          '&[data-in-range]': {
            backgroundColor: 'rgba(177,124,225,0.12)',
          },
          '&[data-selected]': {
            backgroundColor: '#B17CE1',
            '&hover': {
              backgroundColor: '#B17CE1',
              opacity: 0.9,
            },
          },
          '&[data-first-in-range]': {
            backgroundColor: '#B17CE1',
            opacity: 1,
          },
          '&[data-last-in-range]': {
            backgroundColor: '#B17CE1',
            opacity: 1,
          },
        },
        calendarHeaderControl: {
          color: '#B17CE1',
        },
        calendarHeaderLevel: {
          color: '#fff',
        },
      }),
    },
    Tabs: {
      styles: theme => ({
        tab: {
          borderRadius: '10px',
          '&[data-active]': {
            background: theme.fn.linearGradient(
              40,
              '#2BB3FF',
              '#858FEB',
              '#B17CE1',
              '#C785CD',
              '#D28AC3',
            ),
          },
        },
      }),
    },
  },
  globalStyles: theme => ({
    '::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[6],
      borderRadius: '2px',
    },
  }),
});

export const appShellConfig = theme => ({
  main: {
    backgroundColor: theme.colorScheme === 'dark' ? '#0B0B0B' : theme.colors.gray[0],
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
  },
});
