import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { useNavigate } from 'react-router-dom';
import { createCourseMutate } from '../Create.lib';

const title = 'general.notifications.modules.course';

export const useCreateCourse = ({ t }) => {
  const noticeID = 'course';

  const navigate = useNavigate();

  return useMutation({
    mutationFn: requestData => createCourseMutate({ requestData, navigate }),
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.adding')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
    onSuccess: () => {
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.added')}`,
      });
    },
  });
};
