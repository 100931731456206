export const adaptMetaAPIDataForForm = meta => {
  const adaptedMeta = [];

  meta.forEach(item => {
    const itemIndex = adaptedMeta.findIndex(metaItem => metaItem.lang === item.lang);
    const value = { [item.property]: item.value };

    if (itemIndex === -1) {
      adaptedMeta.push({ lang: item.lang, ...value });
      return;
    }
    const { id, ...metaItem } = adaptedMeta[itemIndex];

    adaptedMeta[itemIndex] = { ...metaItem, ...value };
  });

  return adaptedMeta;
};

export const adaptMetaFormDataForAPI = meta => {
  if (!meta) return [];

  const adaptedMeta = [];

  meta.forEach(({ lang, ...metaItems }) => {
    const lowerLang = lang.toLowerCase();

    Object.keys(metaItems).forEach(metaIKey => {
      adaptedMeta.push({
        lang: lowerLang,
        property: metaIKey,
        value: metaItems[metaIKey],
      });
    });
  });

  return adaptedMeta;
};

export const filterArrayOfObjectsByProperty = (item, findingProperty) => {
  return item.property === findingProperty;
};

export const getTitleFromMeta = meta => {
  for (let i = 0; i < meta.length; i++) {
    if (meta[i].property === 'title') {
      return meta[i].value;
    }
  }
  return null;
};
