import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { deleteCourseLessonContentMetaRequest } from 'common/services';

const title = 'general.notifications.modules.content-meta';

export const useRemoveContentMeta = ({ courseID, t }) => {
  const noticeID = 'content-meta';

  return useMutation({
    mutationFn: ({ lang, _meta: { lessonID, contentID } }) => {
      return deleteCourseLessonContentMetaRequest({
        courseID,
        lessonID,
        contentID,
        params: { lang },
      });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
