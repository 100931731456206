import { Accordion, ActionIcon, Flex, Group, Text } from '@mantine/core';
import { IconGripVertical, IconSquareX } from '@tabler/icons';

export const Control = ({ dragHandleProps, label, onRemoveItem }) => {
  return (
    <Accordion.Control>
      <Flex align="center">
        <Group sx={{ flexShrink: 0 }}>
          <ActionIcon
            component="div"
            variant="transparent"
            sx={{ color: '#595959' }}
            onClick={onRemoveItem}
          >
            <IconSquareX />
          </ActionIcon>
          <ActionIcon
            component="div"
            variant="transparent"
            sx={{ color: '#595959' }}
            {...dragHandleProps}
          >
            <IconGripVertical />
          </ActionIcon>
        </Group>

        <Text sx={{ marginLeft: '10px' }}>{label}</Text>
      </Flex>
    </Accordion.Control>
  );
};
