import { ActionIcon, Group, Tooltip } from '@mantine/core';
import {
  IconChecklist,
  IconEdit,
  IconReload,
  IconRocket,
  IconSquareRoundedCheck,
  IconTrash,
  IconSquareLetterX,
} from '@tabler/icons';
import { useTranslation } from 'react-i18next';

export const TableControls = ({
  onTrashClick,
  onEditClick,
  onStartClick,
  onCompleteClick,
  onTestClick,
  onReloadClick,
  onCancelClick,
  disableConfig = {
    test: false,
    start: false,
    complete: false,
    delete: false,
    reload: false,
    edit: false,
    cancel: false,
  },
  tooltipConfig = {
    test: 'Test this item',
    start: 'Start this item',
    complete: 'Complete live stream',
    reload: 'Reload this item',
    delete: 'Delete this item',
    edit: 'Edit this item',
    cancel: 'Cancel this item',
  },
}) => {
  const { t } = useTranslation();

  return (
    <Group spacing={4} position="center" noWrap>
      {onCancelClick && (
        <Tooltip label={tooltipConfig.cancel}>
          <ActionIcon
            disabled={disableConfig.cancel}
            variant="outline"
            color="yellow"
            onClick={onCancelClick}
          >
            <IconSquareLetterX size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      {onTestClick && (
        <Tooltip
          label={t('tooltips.stream-test-button')}
          sx={{ maxWidth: '500px', background: '#B17CE1', borderRadius: 8 }}
          position="top-start"
          multiline
        >
          <ActionIcon
            disabled={disableConfig.test}
            variant="outline"
            color="blue"
            onClick={onTestClick}
          >
            <IconChecklist size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      {onStartClick && (
        <Tooltip label={tooltipConfig.start}>
          <ActionIcon
            disabled={disableConfig.start}
            variant="outline"
            color="green"
            onClick={onStartClick}
          >
            <IconRocket size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      {onCompleteClick && (
        <Tooltip label={tooltipConfig.complete}>
          <ActionIcon
            disabled={disableConfig.complete}
            variant="outline"
            color="yellow"
            onClick={onCompleteClick}
          >
            <IconSquareRoundedCheck size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      {onReloadClick && (
        <Tooltip label={tooltipConfig.reload}>
          <ActionIcon
            disabled={disableConfig.reload}
            variant="outline"
            color="blue"
            onClick={onReloadClick}
          >
            <IconReload size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      {onTrashClick && (
        <Tooltip label={tooltipConfig.delete}>
          <ActionIcon
            disabled={disableConfig.delete}
            variant="outline"
            color="red"
            onClick={onTrashClick}
          >
            <IconTrash size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      {onEditClick && (
        <Tooltip label={tooltipConfig.edit}>
          <ActionIcon
            disabled={disableConfig.edit}
            variant="outline"
            color="green"
            onClick={onEditClick}
          >
            <IconEdit size={16} />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
};
