// UIKit
import { BackgroundImage, Button, Flex, Text, Title, Box } from '@mantine/core';

import { asHtml } from 'common/utils';

const sx = () => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: '130px',
  borderRadius: '16px',
  boxSizing: 'border-box',
});

export const Banner = ({ backgroundUrl, title, description, buttonText, buttonLink }) => {
  return (
    <BackgroundImage src={backgroundUrl} sx={sx}>
      <Box
        sx={{
          width: '100%',
          minHeight: '130px',
          background: 'rgba(0, 0, 0, 0.3)',
          padding: '2em',
          borderRadius: '16px',
          boxSizing: 'border-box',
        }}
      >
        <Flex justify="space-between" align="center" sx={{ width: '100%' }}>
          <Flex justify="flex-start" align="flex-start" direction="column">
            {description && <Text color="#fff">{asHtml(description)}</Text>}
            {title && (
              <Title order={5} color="#fff">
                {asHtml(title)}
              </Title>
            )}
          </Flex>
          <Button component="a" target="_blank" href={buttonLink}>
            {buttonText}
          </Button>
        </Flex>
      </Box>
    </BackgroundImage>
  );
};
