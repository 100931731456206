import { PRICE_TYPE_ENUM } from 'common/enums';
import { capitalize } from 'common/utils';

export const PRICE_TYPE_OPTIONS = Object.values(PRICE_TYPE_ENUM).map(status => ({
  label: capitalize(status),
  value: status,
}));

export const PRICE_TYPE_OPTIONS_COUNT = Object.keys(PRICE_TYPE_OPTIONS).length;

export const pricesTabs = {
  all: 'all',
  active: 'active',
  inactive: 'inactive',
};
