import { adaptMetaAPIDataForForm, adaptMetaFormDataForAPI } from 'common/utils';
import { pricesTabs } from 'common/constants';

export const adaptPriceAPIDataForForm = price => {
  const titleMeta = adaptMetaAPIDataForForm(price.meta.filter(item => item.property === 'title'));
  const listMeta = price.meta
    .filter(item => item.property === 'list')
    .map(item => ({ lang: item.lang, [item.property]: item.value }));

  return {
    ...price,
    listMeta,
    titleMeta,
    title: titleMeta[0]?.title,
    communityCategories: price.communityCategories.map(item => item.categoryId),
  };
};

export const adaptPriceFormDataForAPI = ({
  price,
  title,
  dayDuration,
  listMeta,
  isFree,
  isActive,
  isHidden,
  isSubscription,
  isTrialEnabled,
  trialDayDuration,
}) => {
  const meta = [
    ...adaptMetaFormDataForAPI(listMeta),
    ...adaptMetaFormDataForAPI([{ title, lang: 'ua' }]),
  ];

  return {
    price,
    dayDuration,
    trialDayDuration,
    isFree,
    isActive,
    isHidden,
    isSubscription,
    isTrialEnabled,
    meta,
  };
};

export const getCurrentTabPrices = currentTab => {
  if (currentTab === pricesTabs.active) {
    return 1;
  }

  if (currentTab === pricesTabs.inactive) {
    return 0;
  }

  return null;
};
