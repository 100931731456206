import { Accordion, Box } from '@mantine/core';
import { Draggable } from 'react-beautiful-dnd';

import { getValueFromExtractedMetaArray, asHtml, removeHtmlElementsFromString } from 'common/utils';
import { LANGUAGES_LIST } from 'common/constants';

import { useTranslation } from 'react-i18next';
import { Panel } from './Panel';
import { Control } from './Control';

import translations from './translations';

export const Item = ({
  isOpen,
  lesson,
  index,
  updateLesson,
  removeLesson,
  removeLessonContent,
  removeContentMeta,
  removeTimecode,
}) => {
  const { t } = useTranslation();
  const title = getValueFromExtractedMetaArray(lesson.lessonMetas, 'title', LANGUAGES_LIST.UA);
  const label = `${t(translations.itemLesson)}: ${
    removeHtmlElementsFromString(title) || lesson.position
  }`;

  const onRemoveItem = () => {
    removeLesson({ lessonID: lesson.lessonID });
  };

  return (
    <Draggable index={index} draggableId={`${lesson.lessonID}`}>
      {providedItem => (
        <Box {...providedItem.draggableProps} ref={providedItem.innerRef} pt="xs">
          <Accordion.Item value={`${lesson.lessonID}`}>
            <Control
              label={asHtml(label)}
              onRemoveItem={onRemoveItem}
              dragHandleProps={providedItem.dragHandleProps}
            />

            <Panel
              lesson={lesson}
              isOpen={isOpen}
              onSubmit={updateLesson}
              removeTimecode={removeTimecode}
              removeLessonContent={removeLessonContent}
              removeContentMeta={removeContentMeta}
            />
          </Accordion.Item>
        </Box>
      )}
    </Draggable>
  );
};
