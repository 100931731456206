import { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button } from '@mantine/core';

import { useTranslation } from 'react-i18next';
import { createLessonItem } from '../../FormLessons.constants';
import { LessonsFields } from '../LessonsFields/LessonsFields';

import translations from './translations';

const originalName = 'lessons';

export const LessonsList = () => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, replace, append, remove } = useFieldArray({
    control,
    name: originalName,
  });

  const lastField = fields.at(-1);
  const lastPosition = lastField?.position ?? 0;

  const listItemProps = useMemo(
    () => ({ originalName, onRemove: remove, errors }),
    [errors, remove],
  );

  const addNewLesson = () => append({ ...createLessonItem('ua'), position: lastPosition + 1 });

  const onUpdatePosition = useCallback(
    (index, position) => {
      setValue(`${originalName}.${index}.position`, position);
    },
    [setValue],
  );

  return (
    <>
      <LessonsFields
        fields={fields}
        listItemProps={listItemProps}
        onReplace={replace}
        onUpdatePosition={onUpdatePosition}
      />
      <div>
        <Button variant="filled" onClick={addNewLesson}>
          {t(translations.buttons.newLesson)}
        </Button>
      </div>
    </>
  );
};
