import { Text } from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';

export const showConfirmationModal = ({
  title,
  labels = { confirm: 'Yes, delete', cancel: 'No, cancel' },
  caption = '',
  confirmProps,
  onConfirm = () => {},
}) =>
  openConfirmModal({
    title,
    centered: true,
    labels,
    children: <Text size="sm">{caption}</Text>,
    confirmProps,
    onConfirm,
  });

export const showContextModal = ({
  title,
  labels = { confirm: 'Save', cancel: 'Cancel' },
  children,
}) =>
  openModal({
    title,
    centered: true,
    labels,
    children,
  });
