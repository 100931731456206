import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuthStatus from '../hooks/useAuthStatus';
import { useGetUserData } from '../hooks/api/useGetUserData';
import { setUserData } from '../../app/store/user/user.slice';
import { Loader } from '../components/ui';

const AuthProvider = ({ children }) => {
  const isAuthenticated = useAuthStatus();
  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetUserData(isAuthenticated);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      dispatch(setUserData(data));
    }
  }, [data, dispatch]);

  if (isLoading || isFetching) return <Loader />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AuthProvider;
