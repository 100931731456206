import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const compareLangFunc = (value, property) => value[property];

const getMetaItems = (arrayValues, field) => {
  return arrayValues
    .filter(arrayValue => compareLangFunc(arrayValue, field))
    .map(arrayValue => compareLangFunc(arrayValue, field));
};

export const useGetDisabledOptions = ({ name, getOptions = getMetaItems, field = 'lang' }) => {
  const { control } = useFormContext();

  const arrayValues = useWatch({ control, name, exact: true });

  const disabledOptions = useMemo(() => {
    const values = arrayValues ?? [];

    return getOptions.call(null, values, field);
  }, [arrayValues, getOptions, field]);

  return disabledOptions;
};
