import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from 'reportWebVitals';

// UIKIT
import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';

// Store
import { persistor, store } from 'app/store/store';

// Helpers
import { httpClient, queryClient } from 'common/helpers';

// Components
import { useLocalStorage } from '@mantine/hooks';
import { Router } from 'app/router/Router';
import { ErrorBoundary } from 'common/components/layouts';
import { THEMES_ENUM } from 'common/enums';

import { useCallback } from 'react';
import { setIsUserHasAccess } from 'features/auth/auth.slice';
import { themeConfig } from './configs/theme';
import { CustomFonts } from '../common/components/ui/CustomFonts/CustomFonts';

// HOC
import AuthProvider from '../common/hocs/AuthProvider';

import 'dayjs/locale/uk';

export const App = () => {
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: 'color-scheme',
    defaultValue: THEMES_ENUM.DARK,
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = useCallback(
    () =>
      setColorScheme(prevState =>
        prevState === THEMES_ENUM.DARK ? THEMES_ENUM.LIGHT : THEMES_ENUM.DARK,
      ),
    [setColorScheme],
  );

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
              <MantineProvider inherit withNormalizeCSS theme={themeConfig(colorScheme)}>
                <CustomFonts />
                <NotificationsProvider position="top-right" zIndex={2000} limit={3}>
                  <ModalsProvider modalProps={{ zIndex: 1000 }}>
                    <AuthProvider>
                      <Router />
                      <ReactQueryDevtools position="bottom-right" />
                    </AuthProvider>
                  </ModalsProvider>
                </NotificationsProvider>
              </MantineProvider>
            </ColorSchemeProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

// TODO: Remove and use axios interceptors in other place.
httpClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch(setIsUserHasAccess(false));
    }

    if (error.response.status === 403) {
      /* empty */
    }

    return Promise.reject(error);
  },
);

reportWebVitals();
