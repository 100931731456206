import { useMemo, forwardRef } from 'react';

// UIKit
import { Select as UISelect, Text } from '@mantine/core';
import { IconMoodSad } from '@tabler/icons';
import { Loader } from '../Loader/Loader';

export const Select = forwardRef(
  (
    {
      error,
      value,
      loading,
      label,
      data = [],
      disabledOptions = [],
      renderElement: Element = UISelect,
      placeholder,
      withAsterisk,
      ...otherProps
    },
    ref,
  ) => {
    const options = useMemo(() => {
      if (!disabledOptions.length) return data;

      return data.map(option => ({
        ...option,
        disabled: disabledOptions.includes(option.value),
      }));
    }, [disabledOptions, data]);

    const hasValueInOptions = useMemo(() => {
      if (!value) return true;

      return Boolean(options.find(option => option.value === value));
    }, [options, value]);

    const rightSection = useMemo(
      () => (
        <div style={{ padding: '2px' }}>
          <Loader size="xs" />
        </div>
      ),
      [],
    );

    const nothingFound = useMemo(
      () => (
        <>
          <IconMoodSad size={26} strokeWidth={1.5} />
          <Text>Items not found</Text>
        </>
      ),
      [],
    );

    return (
      <Element
        ref={ref}
        rightSection={loading && rightSection}
        error={error}
        data={options}
        nothingFound={nothingFound}
        placeholder={hasValueInOptions ? placeholder : value}
        label={label}
        value={value}
        withAsterisk={withAsterisk}
        {...otherProps}
      />
    );
  },
);
