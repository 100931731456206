import { useMutation } from '@tanstack/react-query';

import { queryClient } from 'common/helpers';
import { API_TAGS } from 'common/constants';
import {
  getUserByIdRequest,
  updateSubscriptionDurationRequest,
  createSubscriptionForUserRequest,
} from 'common/services';
import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';

const title = 'Subscription';

// Queries
export const getUserByIDQuery = userID => ({
  queryKey: [API_TAGS.user, userID],
  queryFn: () => getUserByIdRequest({ userID }),
  enabled: Boolean(userID),
});

export const useUpdateSubscriptionDuration = id => {
  const noticeID = 'update';

  return useMutation({
    mutationFn: reqData => {
      return updateSubscriptionDurationRequest({ id, reqData });
    },
    onMutate: () => {
      showLoadingNotification({ id: noticeID, message: `${title} is updating` });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.payments] });
      showUpdateNotification({
        id: noticeID,
        message: `${title} was successfully updated!`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};

export const useCreateSubscriptionForUser = () => {
  const noticeID = 'create';

  return useMutation({
    mutationFn: reqData => {
      return createSubscriptionForUserRequest({ reqData });
    },
    onMutate: () => {
      showLoadingNotification({ id: noticeID, message: `${title} is creating` });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.payments, API_TAGS.user] });
      showUpdateNotification({
        id: noticeID,
        message: `${title} was successfully created!`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};
