import { API_LIST } from 'common/constants';
import { get, post, put, patch, destroy } from 'common/helpers';

const path = API_LIST.HOMEWORK.GENERAL;

export const getQuestionRequest = async ({ lessonID }) => {
  const { data } = await get(`${path}/writing/lesson/${lessonID}`);

  return data;
};

export const addWritingAttachmentsRequest = async ({ homeworkId, writingId, files }) => {
  const formData = new FormData();
  formData.append('payload', JSON.stringify({ lang: 'ua' }));
  files.forEach(file => {
    formData.append('file', file);
  });

  const { data } = await post(
    `attachment/lms/homework/${homeworkId}/writing/${writingId}`,
    formData,
    {
      baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
    },
  );

  return data;
};

export const addTestAttachmentsRequest = async ({ homeworkId, testId, files }) => {
  const formData = new FormData();
  formData.append('payload', JSON.stringify({ lang: 'ua' }));
  files.forEach(file => {
    formData.append('file', file);
  });

  const { data } = await post(`attachment/lms/homework/${homeworkId}/test/${testId}`, formData, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};

export const postQuestionRequest = async ({ reqData, files }) => {
  const { data } = await post(`${path}/writing`, reqData);

  if (files.length) {
    await addWritingAttachmentsRequest({
      homeworkId: data.homework.id,
      writingId: data.homework.writing[0].id,
      files,
    });
  }

  return data;
};

export const patchQuestionRequest = async ({ reqData, files }) => {
  const { data } = await patch(`${path}/writing/${reqData.id}`, { meta: reqData.meta });

  if (files.length) {
    await addWritingAttachmentsRequest({
      homeworkId: reqData.homeworkId,
      writingId: reqData.id,
      files,
    });
  }

  return data;
};

export const deleteQuestionRequest = async id => {
  const { data } = await destroy(`${path}/writing/lesson/${id}`);

  return data;
};

export const getPollRequest = async ({ lessonID }) => {
  const { data } = await get(`${path}/test/lesson/${lessonID}`);

  return data;
};

export const postPollRequest = async ({ reqData, files }) => {
  const { data } = await post(`${path}/test`, reqData);

  if (files.length) {
    await addTestAttachmentsRequest({
      homeworkId: data.homework.id,
      testId: data.homework.test[0].id,
      files,
    });
  }

  return data;
};

export const patchPollRequest = async ({ reqData, id, homeworkId, files }) => {
  const { data } = await patch(`${path}/test/${id}`, reqData);

  if (files.length) {
    await addTestAttachmentsRequest({
      homeworkId,
      testId: id,
      files,
    });
  }

  return data;
};

export const putPollRequest = async ({ reqData }) => {
  const { data } = await put(`${path}/test`, reqData);

  return data;
};

export const deletePollRequest = async id => {
  const { data } = await destroy(`${path}/test/${id}`);

  return data;
};

export const postAnswerRequest = async ({ answerID, params, reqData }) => {
  const { data } = await post(`${path}/${answerID}/check`, reqData, { params });

  return data;
};

export const getHomeworksRequest = async params => {
  const { data } = await get(`${path}`, { params });

  return data;
};

export const getHomeworkByIdRequest = async id => {
  const { data } = await get(`${path}/user-group-homework/${id}`);

  return data;
};

export const deleteAttachmentsRequest = async ({ pathname }) => {
  const { data } = await destroy(`attachment/lms/${pathname}`, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};
