import React from 'react';

import { Button, Paper, Stack, Text, Title } from '@mantine/core';

// Icons
import { ReactComponent as FrontErrorIcon } from 'common/assets/icons/front-error.svg';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      errorData: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorData) {
    this.setState(prevState => ({ ...prevState, error, errorData }));
  }

  downloadReport() {
    const { error } = this.state;

    const link = document.createElement('a');
    link.download = 'report.txt';

    const errorData = {
      data: error?.message,
      stack: error?.stack,
    };

    const blob = new Blob([JSON.stringify(errorData)], {
      type: 'data:text/plain;charset=utf-8',
    });

    link.href = URL.createObjectURL(blob);
    link.click();
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Stack sx={{ height: '100%' }} align="center" justify="center">
          <Paper radius="lg">
            <Stack align="center" justify="center" spacing="sm" p="xl">
              <FrontErrorIcon />
              <Title order={3} color="dark">
                Something went wrong :(
              </Title>
              <Text color="dark">Please download the report and send it to us</Text>
              <Button color="dark" onClick={() => this.downloadReport()}>
                Download the report
              </Button>
            </Stack>
          </Paper>
        </Stack>
      );
    }

    return children;
  }
}
