import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

// UIKit
import { Button, SimpleGrid } from '@mantine/core';

// Components
import { Form } from 'common/components/ui';

import { FormFileInput } from 'common/components/ui/FormFileInput/FormFileInput';
import { DEFAULT_VALUES } from './LocaleForm.constants';

import { localeSchema } from './LocaleForm.schemas';

export const LocaleForm = ({ formValues, onSubmit }) => {
  const isEditMode = Boolean(formValues);

  const formMethods = useForm({
    values: formValues,
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(localeSchema),
  });

  return (
    <Form formMethods={formMethods} onSubmit={formMethods.handleSubmit(onSubmit)}>
      <SimpleGrid cols="2" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {isEditMode && (
          <>
            <Button>Download translation</Button>
            <div />
          </>
        )}

        <FormFileInput
          name="translationFile"
          accept="application/JSON"
          placeholder="Upload new translation"
        />
        <FormFileInput name="translationIcon" placeholder="Upload icon translation" />
      </SimpleGrid>
    </Form>
  );
};
