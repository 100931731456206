import { useQuery } from '@tanstack/react-query';
import { API_TAGS } from 'common/constants';
import { getCourseModulesRequest } from 'common/services';

export const useGetCourseModules = ({ courseID, params }) =>
  useQuery({
    queryKey: [API_TAGS.courseModules, params],
    queryFn: () => getCourseModulesRequest({ courseID, params }),
    enabled: !!courseID,
  });
