import { useMutation } from '@tanstack/react-query';

import { queryClient } from 'common/helpers';
import { API_TAGS } from 'common/constants';
import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { updatePriceCourseRequest } from 'common/services';

const title = 'general.notifications.modules.price';

export const useUpdateCoursePrice = ({ t }) => {
  const noticeID = 'updateCoursePrices';

  return useMutation({
    mutationFn: ({ reqData, id, lessons }) => {
      return updatePriceCourseRequest({ reqData, id, lessons });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updating')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.pricesCourse] });

      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updated')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};
