export const ROUTER_BOOK = Object.freeze({
  private: {
    // DASHBOARD: {
    //   GENERAL: '/dashboard',
    // },
    BUSINESS_ACCOUNT: {
      GENERAL: '/business-account',

      BRAND_INFO: {
        GENERAL: '/business-account/info',
      },
      ACCESS_RULES: {
        GENERAL: '/business-account/access-rules',
      },
      EMPLOYEES: {
        GENERAL: '/business-account/employees',
      },
      INVITATION: {
        GENERAL: '/business-account/invitation',
      },
      PRICINGS: {
        GENERAL: '/business-account/pricings',
      },
    },
    STREAMS: {
      GENERAL: '/streams',
      ID: '/streams/:id',
      CREATE: '/streams/new',
    },

    BANNERS: {
      GENERAL: '/banners',
      CREATE: '/banners/new',
      ID: '/banners/:id',
    },

    COURSES: {
      GENERAL: '/courses',
      CREATE: '/courses/management/new',
      ID: '/courses/management/:id',
      MANAGEMENT: '/courses/management',
      S3_VIDEOS: '/courses/s3-videos',
      GROUPS: {
        GENERAL: '/courses/groups',
        ID: '/courses/groups/:id',
      },
    },

    HOME_WORKS: {
      GENERAL: '/homeworks',

      CREATE: {
        GENERAL: '/homeworks/new',
        LESSON: '/homeworks/new/:courseID',
        ENTITIES: '/homeworks/new/:courseID/:lessonID',
        POLL: '/homeworks/new/:courseID/:lessonID/poll',
        SINGLE: '/homeworks/new/:courseID/:lessonID/single',
      },
      CHECK: {
        GENERAL: '/homeworks/check',
      },
    },

    USERS: {
      GENERAL: '/users',
      ID: '/users/:userID',
    },

    LOCALES: {
      ID: '/locales/:id',
      CREATE: '/locales/new',
      GENERAL: '/locales',
    },
    PRICES: {
      GENERAL: '/prices',
    },

    PAYMENTS: {
      GENERAL: '/payments',
    },

    // NOTIFICATIONS: {
    //   SEND_NOTIFICATION: '/send-notification',

    //   NOTIFICATIONS: '/notifications',

    //   NOTIFICATIONS_ID: '/notifications/:id',
    //   NOTIFICATION_CREATE: '/notifications/new',

    //   TEMPLATES: '/templates',
    //   TEMPLATES_ID: '/templates/:id',
    //   TEMPLATE_CREATE: '/templates/new',
    // },

    COMMENTS: {
      GENERAL: '/comments',
      LESSONS: '/comments/:courseID',
      COMMENTS: '/comments/:courseID/:lessonID',
    },
    EVENTS: {
      GENERAL: '/events',
      ID: '/events/:id',
      CREATE: '/events/new',
    },
    OWNER_SETUP: {
      GENERAL: '/owner-setup',
      ADD_INFO: '/owner-setup/add-info',
      SETUP_COMMUNITY: '/owner-setup/setup-community',
      COMMUNITY_PRICES: '/owner-setup/community-prices',
    },
    EARNINGS: {
      GENERAL: '/earnings',
    },
  },

  public: {
    AUTH: {
      SIGN_IN: '/sign-in',
      SIGN_UP: '/sign-up',
      FORGOT_PASSWORD: '/forgot-password',
      NEW_PASSWORD: '/new-password',
      CONFIRMATION: '/confirmation',
    },
    INVITATION: {
      ACCEPT_INVITATION: '/accept-invitation',
    },
  },
});
