import { createStyles } from '@mantine/core';

export const useSidebarStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef('icon');

  return {
    links: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
      padding: '0 10px',
    },

    footer: {
      paddingTop: theme.spacing.xs,
      borderTop: `1px solid ${theme.colors.violet[3]}`,
    },

    link: {
      ...theme.fn.focusStyles(),
      borderRadius: '4px',
      color: '#C8C8C8',

      '&[data-active]': {
        color: '#fff',
        background:
          'linear-gradient(226deg, rgba(255, 156, 156, 0.25) 0%, rgba(177, 124, 225, 0.25) 34.85%, rgba(43, 179, 255, 0.25) 69.59%, rgba(48, 118, 255, 0.25) 95.95%)',
        fontWeight: 600,
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },
  };
});
