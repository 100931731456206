import { Flex, Text, Button, Group } from '@mantine/core';

export const UserSelectItem = ({ item, addUser }) => (
  <Flex
    align="center"
    justify="space-between"
    sx={{ borderBottom: '1px solid #2f9e44', padding: '5px 10px' }}
  >
    <Group align="center">
      <div>
        <Text size="sm" opacity={0.5}>
          ID:
        </Text>
        <Text size="sm">{item.id}</Text>
      </div>
      {item.name && (
        <div>
          <Text size="sm" opacity={0.5}>
            Name:
          </Text>
          <Text size="sm">{item.name}</Text>
        </div>
      )}
      {item.email && (
        <div>
          <Text size="sm" opacity={0.5}>
            Email:
          </Text>
          <Text size="sm">{item.email}</Text>
        </div>
      )}
      {item.phoneNumber && (
        <div>
          <Text size="sm" opacity={0.5}>
            Phone:
          </Text>
          <Text size="sm">{item.phoneNumber}</Text>
        </div>
      )}
    </Group>
    <Button onClick={() => addUser(item.id)}>Add user</Button>
  </Flex>
);
