// UIKit
import { Stepper } from '@mantine/core';
import { IconCash, IconInfoSquareRounded, IconSchool, IconHexagons } from '@tabler/icons';

import { useTranslation } from 'react-i18next';
import translations from './translations';

export const CourseStepper = ({ activeStep, onStepClick, newCourseId, courseID }) => {
  const { t } = useTranslation();

  const STEPS = [
    {
      description: translations.step1,
      icon: IconInfoSquareRounded,
      allow: true,
    },
    { description: translations.step2, icon: IconSchool, allow: true },
    { description: translations.step3, icon: IconHexagons, allow: !!newCourseId || !!courseID },
    { description: translations.step4, icon: IconCash, allow: !!newCourseId || !!courseID },
  ];

  return (
    <Stepper active={activeStep} onStepClick={onStepClick} breakpoint="xs">
      {STEPS.map(({ description, icon: Icon, allow }, index) => {
        return (
          <Stepper.Step
            key={description}
            icon={<Icon size={22} />}
            label={`${t(translations.step)} ${index + 1}`}
            description={t(description)}
            allowStepClick={allow}
            allowStepSelect={allow}
          />
        );
      })}
    </Stepper>
  );
};
