import { COURSE_STATUS_ENUM, COURSE_TYPE_ENUM } from 'common/enums';

const createCourseMetaItem = lang => ({
  lang,
  title: '',
  description: '',
});

export const createCourseDefaultValues = lang => ({
  generalImage: '',
  landingImage: '',
  landingBackgroundImage: '',
  type: COURSE_TYPE_ENUM.PAID,
  status: COURSE_STATUS_ENUM.TEMPLATE,
  groupCapacity: 1,
  courseMetas: [createCourseMetaItem(lang)],
});
