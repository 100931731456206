import { post, get } from 'common/helpers';
import { API_LIST } from 'common/constants';

export const postInviteEmployeeRequest = async (reqData = {}) => {
  const { data } = await post(API_LIST.ADMIN_INVITATION.GENERAL, reqData);

  return data;
};

export const getAdminInvitationRequest = async (
  params = {
    page: 1,
    limit: 10,
    status: '',
  },
) => {
  const { data } = await get(API_LIST.ADMIN_INVITATION.GENERAL, { params });

  return data;
};

export const cancelAdminInvitationByIDRequest = async invitationID => {
  const { data } = await post(`${API_LIST.ADMIN_INVITATION.GENERAL}/${invitationID}/cancel`);

  return data;
};

export const resendAdminInvitationByIDRequest = async invitationID => {
  const { data } = await post(`${API_LIST.ADMIN_INVITATION.GENERAL}/${invitationID}/resend`);

  return data;
};

export const adminInvitationAcceptRequest = async token => {
  const { data } = await post(`${API_LIST.ADMIN_INVITATION.GENERAL}/accept/${token}`);

  return data;
};
