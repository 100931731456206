import { queryClient } from 'common/helpers';

import { useMutation } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { deleteCourseModuleRequest } from 'common/services';

const title = 'general.notifications.modules.module';

export const useDeleteModule = ({ t }) => {
  const noticeID = 'deleteModule';

  return useMutation({
    mutationFn: ({ courseId, moduleId }) => {
      return deleteCourseModuleRequest({ courseId, moduleId });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.courseModules] });

      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};
