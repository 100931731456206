import { useTranslation } from 'react-i18next';
import { lessonsSchema } from './FormLessons.schemas';
import { createDefaultValues } from './FormLessons.constants';
import { LessonsList } from './components/LessonsList/LessonsList';
import { FormComponent } from '../FormComponent/FormComponent';

export const FormLessons = props => {
  const { t } = useTranslation();
  return (
    <FormComponent
      {...props}
      defaultValues={createDefaultValues('ua')}
      validationSchema={lessonsSchema(t)}
    >
      <LessonsList />
    </FormComponent>
  );
};
