import { Button, Group, SimpleGrid } from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons';
import { FormProvider } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import translations from './translations';

export const Form = ({
  renderFooter: Footer,
  onSubmit,
  children,
  gridProps,
  formMethods,
  id = null,
  isTextEditorField = false,
}) => {
  const { t } = useTranslation();

  return (
    <FormProvider {...formMethods}>
      <SimpleGrid component="form" id={id} onSubmit={onSubmit} {...gridProps}>
        {children}

        {Footer ?? (
          <Group position="right">
            <Button
              type="submit"
              leftIcon={<IconDeviceFloppy />}
              disabled={!formMethods.formState.isDirty}
              sx={{ marginTop: isTextEditorField && '28px' }}
            >
              {t(translations.button)}
            </Button>
          </Group>
        )}
      </SimpleGrid>
    </FormProvider>
  );
};
