import {
  adaptMetaAPIDataForForm,
  adaptMetaFormDataForAPI,
  createFormData,
  isObject,
} from 'common/utils';

export const adaptFormCourseDatForAPI = ({
  courseMetas,
  generalImage,
  landingImage,
  landingBackgroundImage,
  ...rest
}) => {
  const adaptedFormValues = {
    ...rest,
    courseMetas: adaptMetaFormDataForAPI(courseMetas),
  };

  if (isObject(generalImage)) {
    adaptedFormValues.generalImage = createFormData({ image: generalImage });
  }

  if (isObject(landingImage)) {
    adaptedFormValues.landingImage = createFormData({ image: landingImage });
  }

  if (isObject(landingBackgroundImage)) {
    adaptedFormValues.landingBackgroundImage = createFormData({ image: landingBackgroundImage });
  }

  return adaptedFormValues;
};

export const adaptCourseAPIDataForForm = courseAPIData => ({
  generalImage: courseAPIData.generalImage,
  landingImage: courseAPIData.landingImage,
  landingBackgroundImage: courseAPIData.landingBackgroundImage,
  status: courseAPIData.status,
  groupCapacity: courseAPIData.groupCapacity,
  courseMetas: adaptMetaAPIDataForForm(courseAPIData.courseMetas),
  categoryId: courseAPIData?.category?.id,
});
