import { showNotification, updateNotification } from '@mantine/notifications';

import { ReactComponent as SuccessIcon } from 'common/assets/icons/success-icon.svg';

export const showLoadingNotification = ({ id = 'loadingNotification', ...rest }) => {
  showNotification({
    id,
    loading: true,
    autoClose: false,
    disallowClose: true,
    styles: { loader: { stroke: '#B17CE1' } },
    sx: { backgroundColor: '#222', borderRadius: 8 },
    ...rest,
  });
};

export const showUpdateNotification = ({ id = 'updateNotification', message, ...rest }) => {
  updateNotification({
    id,
    message,
    icon: <SuccessIcon />,
    styles: { icon: { background: 'none !important' } },
    closeButtonProps: { iconSize: 12 },
    sx: { backgroundColor: '#222', borderRadius: 8 },
    ...rest,
  });
};
