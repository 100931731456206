import { Flex, Button, Text } from '@mantine/core';
import { useForm, useFieldArray } from 'react-hook-form';
import { IconPlus } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { PricingList } from './components/PricingList';
import translations from './translations';

export const PricingForm = ({ pricingList, courseId, myBusinessAccount, country }) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    getFieldState,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pricingList,
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'pricingList',
    keyName: 'fieldId',
  });

  const addPricingFieldHandler = () => {
    append({
      price: null,
      title: '',
      isActive: false,
      isTrialEnabled: false,
      trialDayDuration: 0,
    });
  };

  const newFieldsLength = fields.filter(field => !('id' in field)).length;

  return (
    <form>
      <Flex direction="column">
        {!myBusinessAccount?.isChargesEnabled &&
          !myBusinessAccount?.isDetailsSubmitted &&
          !myBusinessAccount?.isPayoutsEnabled &&
          country?.isSupportStripeConnect && (
            <Text size={12} color="#e03131">
              {t(translations.warningMessage)}
            </Text>
          )}

        <PricingList
          fields={fields}
          fieldName="pricingList"
          control={control}
          watch={watch}
          getFieldState={getFieldState}
          handleSubmit={handleSubmit}
          errors={errors}
          pricingList={pricingList}
          courseId={courseId}
          myBusinessAccount={myBusinessAccount}
          isSupportStripeConnect={country?.isSupportStripeConnect}
        />

        <Flex justify="flex-start" sx={{ marginTop: '16px', padding: '0 16px' }}>
          <Button
            variant="outline"
            leftIcon={<IconPlus size={18} />}
            onClick={addPricingFieldHandler}
            sx={{ border: 'none' }}
            disabled={newFieldsLength === 1}
          >
            {t(translations.buttons.addPrice)}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
