import { BUTTON_VARIANTS } from './theme.constants';

export const inputsStyles = theme => ({
  input: {
    borderColor: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[4],
  },
});

export const getButtonBgrStylesByType = ({ theme, variant }) => {
  switch (variant) {
    case BUTTON_VARIANTS.filled:
      return theme.fn.linearGradient(40, '#2BB3FF', '#858FEB', '#B17CE1', '#C785CD', '#D28AC3');
    case BUTTON_VARIANTS.outline:
      return 'transparent';
    case BUTTON_VARIANTS.light:
      return 'transparent';
    case BUTTON_VARIANTS.default:
      return 'transparent';
    case BUTTON_VARIANTS.subtle:
      return 'transparent';
    default:
      return theme.fn.linearGradient(40, '#2BB3FF', '#858FEB', '#B17CE1', '#C785CD', '#D28AC3');
  }
};

export const getButtonBorderStylesByType = ({ variant, color }) => {
  const defaultBorder = `1px solid ${color || '#D0B0EDFF'}`;
  switch (variant) {
    case BUTTON_VARIANTS.filled:
      return 'none';
    case BUTTON_VARIANTS.outline:
      return defaultBorder;
    case BUTTON_VARIANTS.light:
      return defaultBorder;
    case BUTTON_VARIANTS.default:
      return defaultBorder;
    case BUTTON_VARIANTS.subtle:
      return 'none';
    default:
      return 'none';
  }
};
