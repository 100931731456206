import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryClient } from 'common/helpers';

// Components
import { PageDetails } from 'common/components/layouts';

// Hooks
import { useTranslation } from 'react-i18next';
import { LocaleForm } from './components/LocaleForm/LocaleForm';
import { getLocaleByIdQuery, useCreateLocale, useUpdateLocaleByID } from './hooks/queries';

export const localeLoader = async ({ params }) => {
  const query = getLocaleByIdQuery(params.id);
  const data = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));

  return data;
};

const Locale = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  const { isInitialLoading, data } = useQuery(getLocaleByIdQuery(id));
  const { mutate: createLocale } = useCreateLocale(t);
  const { mutate: updateLocale } = useUpdateLocaleByID({ localeID: id, t });

  return (
    <PageDetails loading={isInitialLoading}>
      <LocaleForm formValues={data?.locale} onSubmit={isEditMode ? updateLocale : createLocale} />
    </PageDetails>
  );
};

export default Locale;
