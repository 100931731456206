import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  businessAccount: {},
  permissions: {
    course: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    group: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    homework: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    price: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    calendar: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    notification: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    stream: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    s3: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    communityCategory: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    communityCommunicationItem: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    communityMessage: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    benefit: {
      read: false,
      delete: false,
      create: false,
      update: false,
    },
    businessAccount: {
      read: false,
      update: false,
      delete: false,
    },
    category: {
      read: false,
    },
    lessonComment: {
      delete: false,
    },
    user: {
      read: false,
      create: false,
    },
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, { payload }) {
      state.permissions = payload.permissions;
      state.businessAccount = payload.businessAccount;
      state.user = payload.user;
    },
  },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
