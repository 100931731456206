import { useNavigate } from 'react-router-dom';

// UIKit
import { Button, Group, SimpleGrid, Title } from '@mantine/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { Loader } from 'common/components/ui';
import { useTranslation } from 'react-i18next';
import translations from './translations';

export const PageDetails = ({
  title = '',
  extra,
  loading = false,
  children,
  renderFooter,
  back = true,
  sx,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => navigate(-1);

  if (loading) {
    return <Loader />;
  }

  return (
    <SimpleGrid sx={sx}>
      {back && (
        <div>
          <Button variant="outline" leftIcon={<IconArrowNarrowLeft />} onClick={goBack}>
            {t(translations.buttons.back)}
          </Button>
        </div>
      )}

      {(title || extra) && (
        <Group position="apart">
          {title && <Title order={2}>{title}</Title>}

          {extra && extra}
        </Group>
      )}

      {children}

      {renderFooter}
    </SimpleGrid>
  );
};
