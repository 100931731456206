import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { invalidateQueries } from 'common/helpers';
import { API_TAGS } from 'common/constants';
import { postCourseLessonRequest } from 'common/services';

import { adaptLessonsFormDatForAPI } from '../LessonsEditor.utils';

const title = 'general.notifications.modules.lesson';

export const useCreateLesson = ({ courseID, t }) => {
  const noticeID = 'lesson';

  return useMutation({
    mutationFn: async lesson => {
      const reqData = { lessons: [adaptLessonsFormDatForAPI(lesson)] };

      await postCourseLessonRequest({ reqData, courseID });
      await invalidateQueries({ queryKey: [API_TAGS.course, courseID] });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.adding')}`,
      });
    },
    onSuccess: () => {
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.added')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
