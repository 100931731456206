import { API_LIST } from 'common/constants';
import { destroy, get, post, put } from 'common/helpers';

const path = API_LIST.STREAMS.GENERAL;
const pathBanners = API_LIST.DASHBOARD.BLOCKS;

export const getStreamsRequest = async (params = { page: 1, limit: 10 }) => {
  const { data } = await get(path, { params });

  return data;
};

export const postStreamRequest = async reqData => {
  const { data } = await post(path, reqData);

  return data;
};

export const postStreamChangeStatusRequest = async (streamID, reqData) => {
  const { data } = await post(`${path}/${streamID}/change-status`, reqData);

  return data;
};

export const postStreamAttachUserRequest = async ({ streamID, attachInfo }) => {
  const { data } = await post(`${path}/${streamID}/attach-user`, attachInfo);

  return data;
};

export const putStreamByIDRequest = async (streamId, transformedData) => {
  const { data } = await put(`${path}/${streamId}`, transformedData);

  return data;
};

export const deleteStreamByIDRequest = async streamID => {
  const { data } = await destroy(`${path}/${streamID}`);

  return data;
};

export const getStreamByIDRequest = async streamID => {
  const { data } = await get(`${path}/${streamID}`);

  return data;
};

export const postAuthSetCredentialsRequest = async reqData => {
  const { data } = await post(`${path}/set-credentials`, reqData);

  return data;
};

export const getStreamBannersRequest = async (params = { page: 1, limit: 100, lang: 'ua' }) => {
  const { data } = await get(`${pathBanners}/banners`, { params });

  return data;
};

export const getStreamBannerByIDRequest = async (
  streamBannerId,
  params = { page: 1, limit: 10, lang: 'ua' },
) => {
  const { data } = await get(`${pathBanners}/banners/${streamBannerId}`, { params });

  return data;
};

export const postStreamBannerRequest = async reqData => {
  const { data } = await post(`${pathBanners}/banners`, reqData);

  return data;
};

export const putStreamBannerByIDRequest = async (streamBannerId, transformedData) => {
  const data = await put(`${pathBanners}/banners/${streamBannerId}`, transformedData);

  return data;
};

export const deleteStreamBannerByIDRequest = async streamBannerId => {
  const { data } = await destroy(`${pathBanners}/banners/${streamBannerId}`);

  return data;
};

export const postStreamBannerImageRequest = async ({ streamBannerId, image }) => {
  const data = await post(
    `attachment/lms/images/banners/${streamBannerId}/upload-stream-banner-image`,
    image,
    {
      baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
    },
  );
  return data;
};

export const postStreamChatActionRequest = async ({ chatId, reqData }) => {
  const data = await post(`${path}/${chatId}/chat-action`, reqData);

  return data;
};
