import { useController, useFormContext } from 'react-hook-form';
import { MultiSelect } from '@mantine/core';
import { useState } from 'react';
import { Select } from '../Select/Select';
import { removeEmptyElements } from '../../../utils';

export const FormMultiSelect = ({ name, ...otherProps }) => {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const [intermediateData, setIntermediateData] = useState([...otherProps.data]);

  const onCreate = query => {
    const item = { value: query, label: query };
    setIntermediateData([...intermediateData, item]);
    return item;
  };

  const getErrorMessage = formError => {
    const result = removeEmptyElements(formError);

    if (result?.message) {
      return result.message;
    }

    if (Array.isArray(result) && result[0]?.message) {
      return result[0].message;
    }

    return '';
  };

  return (
    <Select
      error={getErrorMessage(error)}
      {...field}
      {...otherProps}
      data={intermediateData}
      renderElement={MultiSelect}
      searchable
      creatable
      getCreateLabel={query => `+ Create ${query}`}
      onCreate={onCreate}
    />
  );
};
