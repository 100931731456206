import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { removeUserFromGroupRequest } from 'common/services';
import { invalidateQueries } from 'common/helpers';
import { API_TAGS } from 'common/constants';

const title = 'general.notifications.modules.user';

export const useRemoveUserFromGroup = ({ groupId, t }) => {
  const noticeID = 'user';

  return useMutation({
    mutationFn: userId => {
      return removeUserFromGroupRequest({ groupId, userId });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      invalidateQueries({ queryKey: [API_TAGS.group] });
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
