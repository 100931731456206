import { Accordion, Paper, SimpleGrid, ActionIcon, Flex } from '@mantine/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { IconTrash } from '@tabler/icons';

import { FormControl, NotFound } from 'common/components/ui';
import { showConfirmationModal } from 'common/utils';

import { useTranslation } from 'react-i18next';
import { useRemoveUserFromGroup } from '../../hooks/useRemoveUserFromGroup';

import translations from '../../translations';

const name = 'users';

export const UsersList = ({ groupId }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const list = useWatch({
    name,
    control,
  });

  const hasItems = Boolean(list?.length);

  const { mutate: remove } = useRemoveUserFromGroup({ groupId, t });

  const openModal = userId => {
    return showConfirmationModal({
      title: t(translations.children.edit.usersList.modal.title),
      caption: t(translations.children.edit.usersList.modal.caption),
      labels: {
        confirm: t(translations.children.edit.usersList.modal.buttons.confirm),
        cancel: t(translations.children.edit.usersList.modal.buttons.cancel),
      },
      onConfirm: () => remove(userId),
    });
  };

  return (
    <>
      {!hasItems && <NotFound />}

      {hasItems && (
        <Accordion variant="contained">
          {list.map((item, index) => (
            <Flex align="center" key={item.id}>
              <Accordion.Item value={`item_${item.id}`} sx={{ width: '100%' }}>
                <Accordion.Control>
                  <span>{`${t(translations.children.edit.usersList.user)} ${item.id}, ${
                    item.phone
                      ? `${t(translations.children.edit.usersList.phone)}: ${item.phone}`
                      : `${t(translations.children.edit.usersList.phoneNotFound)}`
                  }`}</span>
                </Accordion.Control>
                <Accordion.Panel>
                  <Paper shadow="xs" p="md">
                    <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                      <FormControl
                        name={`${name}.${index}.id`}
                        disabled
                        label={t(translations.children.edit.usersList.fields.id.label)}
                        placeholder={t(translations.children.edit.usersList.fields.id.placeholder)}
                      />
                      <FormControl
                        name={`${name}.${index}.name`}
                        disabled
                        label={t(translations.children.edit.usersList.fields.name.label)}
                        placeholder={t(
                          translations.children.edit.usersList.fields.name.placeholder,
                        )}
                      />
                      <FormControl
                        name={`${name}.${index}.email`}
                        disabled
                        label={t(translations.children.edit.usersList.fields.email.label)}
                        placeholder={t(
                          translations.children.edit.usersList.fields.email.placeholder,
                        )}
                      />
                    </SimpleGrid>
                  </Paper>
                </Accordion.Panel>
              </Accordion.Item>

              <ActionIcon
                variant="outline"
                color="red"
                onClick={() => openModal(item.id)}
                sx={{ marginLeft: '10px' }}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </Flex>
          ))}
        </Accordion>
      )}
    </>
  );
};
