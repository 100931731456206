export const COURSE_TYPE_ENUM = Object.freeze({
  FREE: 'free',
  PAID: 'paid',
});

export const COURSE_STATUS_ENUM = Object.freeze({
  ARCHIVE: 'archive',
  TEMPLATE: 'template',
  ACTIVE: 'active',
});
