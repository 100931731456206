import { useQuery } from '@tanstack/react-query';
import { API_TAGS } from 'common/constants';
import { getCourseRequest } from 'common/services';
import { adaptCourseAPIDataForForm } from '../CourseEditor.utils';

export const getCourseQuery = courseID => ({
  queryKey: [API_TAGS.course, courseID],
  queryFn: () => getCourseRequest(courseID),
  enabled: Boolean(courseID),
});

export const useGetCourse = courseID =>
  useQuery({
    ...getCourseQuery(courseID),
    select: ({ course }) => adaptCourseAPIDataForForm(course),
  });
