// TODO: Remove Axios and create own class based on Fetch

import axios from 'axios';

const baseURL = process.env.REACT_APP_DEFAULT_API_URL_PATH;

export const httpClient = axios.create({ baseURL, withCredentials: true });

httpClient.interceptors.request.use(
  config => {
    const contentType = config.ContentType ?? 'application/json';

    config.headers['Access-Control-Allow-Credentials'] = true;
    config.headers['Content-Type'] = contentType;

    return config;
  },
  error => Promise.reject(error),
);

export const { get, post, put, delete: destroy, patch } = httpClient;
