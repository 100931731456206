import * as yup from 'yup';

export const courseSchema = t =>
  yup.object({
    courseMetas: yup
      .array()
      .min(1, t('general.errors.min', { count: 1 }))
      .of(
        yup.object({
          lang: yup.string(t('general.errors.string')).required(t('general.errors.required')),
          title: yup.string(t('general.errors.string')).required(t('general.errors.required')),
          description: yup
            .string(t('general.errors.string'))
            .required(t('general.errors.required')),
        }),
      )
      .required(t('general.errors.required')),
    status: yup.string().required(t('general.errors.required')),
    groupCapacity: yup
      .number()
      .typeError(t('general.errors.required'))
      .required(t('general.errors.required')),
    categoryId: yup
      .number()
      .typeError(t('general.errors.required'))
      .required(t('general.errors.required')),
  });
