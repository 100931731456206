import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'common/components/ui';
import { useForm } from 'react-hook-form';
import { StepFooter } from '../StepFooter/StepFooter';

export const FormComponent = ({
  defaultValues,
  formValues,
  validationSchema,
  children,
  onSubmit,
  loading,
  activeStep,
  toPrevStep,
  disabledSubmit = true,
}) => {
  const formMethods = useForm({
    mode: 'onChange',
    values: formValues,
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, formState } = formMethods;

  const isDirtyForm = !formState.isDirty;

  return (
    <Form
      formMethods={formMethods}
      onSubmit={handleSubmit(onSubmit)}
      renderFooter={
        <StepFooter
          loading={loading}
          disabled={isDirtyForm && disabledSubmit}
          activeStep={activeStep}
          onBackClick={toPrevStep}
        />
      }
    >
      {children}
    </Form>
  );
};
