import { API_LIST } from 'common/constants';
import { get, patch, post } from 'common/helpers';

const path = API_LIST.DASHBOARD.BLOCKS;

export const getDashboardBlocksRequest = async () => {
  const { data } = await get(path);

  return data;
};

export const patchBannerRequest = async ({
  dashboardBlockId,
  dashboardContentId,
  bannerID,
  requestData,
}) => {
  const localPath = `${path}/${dashboardBlockId}/content/${dashboardContentId}/banner/${bannerID}`;
  const { data } = await patch(localPath, requestData);

  return data;
};

export const postBannerImageRequest = async ({ bannerID, file }) => {
  const localPath = `/attachment/lms/images/banners/${bannerID}/upload-banner-image`;
  const { data } = await post(localPath, file, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};

export const postBannerMainImageRequest = async ({ bannerID, file }) => {
  const localPath = `/attachment/lms/images/banners/${bannerID}/upload-banner-main-image`;
  const { data } = await post(localPath, file, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};
