import { patch, post } from 'common/helpers';
import { API_LIST } from 'common/constants';

export const signInRequest = async (reqData = {}) => {
  const { data } = await post(API_LIST.AUTH.SIGN_IN, reqData);

  return data;
};

export const signInAppleRequest = async (reqData = {}) => {
  const { data } = await post(API_LIST.AUTH.SIGN_IN_APPLE, reqData);

  return data;
};

export const signInGoogleRequest = async (reqData = {}) => {
  const { data } = await post(API_LIST.AUTH.SIGN_IN_GOOGLE, reqData);

  return data;
};

export const otpRequest = async (reqData = { email: '', otp: '11111' }) => {
  const { data } = await post(API_LIST.AUTH.VERIFY, reqData);

  return data;
};

export const logoutRequest = () => post(API_LIST.AUTH.LOGOUT);

export const getReCaptchaTokenRequest = async () => {
  const captcha = await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY);

  return captcha;
};

export const signUpRequest = async (reqData = {}) => {
  const { data } = await post(API_LIST.AUTH.SIGN_UP, reqData);

  return data;
};

export const forgotPasswordFirstStepRequest = async (reqData = {}) => {
  const { data } = await patch(API_LIST.AUTH.FORGOT_PASSWORD_FIRST_STEP, reqData);

  return data;
};

export const forgotPasswordSecondStepRequest = async (reqData = {}) => {
  const { data } = await patch(API_LIST.AUTH.FORGOT_PASSWORD_SECOND_STEP, reqData);

  return data;
};
