import { MultiSelect } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { FormSelect } from 'common/components/ui';
import { API_TAGS } from 'common/constants';
import { getUsersRequest } from 'common/services';

const config = {
  queryKey: [API_TAGS.users, 'select'],
  initialData: () => ({ users: [] }),
  queryFn: () => getUsersRequest({ limit: 1000 }),
  select: ({ users }) => users.map(({ id, name }) => ({ value: id, label: `${name ?? id}` })),
};

export const UsersSelect = props => {
  const { isLoading, data } = useQuery(config);

  return (
    <FormSelect searchable renderElement={MultiSelect} loading={isLoading} data={data} {...props} />
  );
};
