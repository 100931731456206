import { API_LIST } from 'common/constants';
import { destroy, get, post, put } from 'common/helpers';

const path = API_LIST.EVENTS.GENERAL;
const imgPath = 'attachment/lms/images/calendar';

export const getEventsRequest = async (
  params = {
    page: 1,
    limit: 10,
    dateFrom: '',
    dateTo: '',
  },
) => {
  const { data } = await get(path, { params });

  return data;
};

export const postEventRequest = async reqData => {
  const { data } = await post(path, reqData);

  return data;
};

export const deleteEventByIDRequest = async eventID => {
  const { data } = await destroy(`${path}/${eventID}`);

  return data;
};

export const putEventByIDRequest = async ({ eventId, transformedData }) => {
  const { data } = await put(`${path}/${eventId}`, transformedData);

  return data;
};

export const getEventByIDRequest = async eventID => {
  const { data } = await get(`${path}/${eventID}`);

  return data;
};

export const postEventImageRequest = async ({ eventID, file }) => {
  const { data } = await post(`/${imgPath}/${eventID}`, file, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};
