import { useMutation, useQuery } from '@tanstack/react-query';
import { ROUTER_BOOK } from 'app/router/routerBook';

import { API_TAGS } from 'common/constants';
import { queryClient } from 'common/helpers';
import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { useNavigate } from 'react-router-dom';
import {
  adaptLocaleAPIDataForForm,
  adaptLocaleFormDataForAPI,
} from '../components/LocaleForm/LocaleForm.utils';
import {
  createLocaleRequest,
  deleteLocaleByIdRequest,
  getLocaleByIdRequest,
  getLocalesRequest,
  updateLocaleByIdRequest,
} from '../Locales.services';

const title = 'general.notifications.modules.locale';

export const useGetLocales = params => {
  return useQuery({
    queryKey: [API_TAGS.locales, params.page],
    queryFn: () => getLocalesRequest(params),
  });
};

export const useDeleteLocaleByID = t => {
  const noticeID = 'delete';

  return useMutation({
    mutationFn: deleteLocaleByIdRequest,
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.locales] });
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};

export const useCreateLocale = t => {
  const navigate = useNavigate();
  const noticeID = 'creating';

  return useMutation({
    mutationFn: requestData => {
      const transformedData = adaptLocaleAPIDataForForm(requestData);
      return createLocaleRequest(transformedData);
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.creating')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.locales] });
      navigate(ROUTER_BOOK.private.LOCALES.GENERAL);
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.created')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};

export const useUpdateLocaleByID = ({ localeID, t }) => {
  const navigate = useNavigate();
  const noticeID = 'updating';

  return useMutation({
    mutationFn: requestData => {
      const transformedData = adaptLocaleFormDataForAPI(requestData);
      return updateLocaleByIdRequest(localeID, transformedData);
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updating')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.locales] });
      navigate(ROUTER_BOOK.private.LOCALES.GENERAL);

      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updated')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};

// Queries
export const getLocaleByIdQuery = localeID => ({
  queryKey: [API_TAGS.locale, localeID],
  queryFn: () => getLocaleByIdRequest(localeID),
  enabled: Boolean(localeID),
});
