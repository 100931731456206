import { useQuery } from '@tanstack/react-query';
import { API_TAGS } from 'common/constants';
import { getPricesCourseRequest } from 'common/services';
import { adaptPriceAPIDataForForm } from '../PricesEditor.utils';

export const useGetCoursePrices = (courseID, params, currentTab) =>
  useQuery({
    queryKey: [API_TAGS.pricesCourse, currentTab, courseID],
    queryFn: () => getPricesCourseRequest({ courseID, params }),
    select: data => data.prices.map(adaptPriceAPIDataForForm),
    enabled: !!courseID,
  });
