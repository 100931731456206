import { Tooltip as MantineTooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons';

export const Tooltip = ({ label = '', position = 'top-start', multiline = true }) => (
  <MantineTooltip
    label={label}
    position={position}
    multiline={multiline}
    sx={{ maxWidth: '500px', background: '#B17CE1', borderRadius: 8 }}
  >
    <div style={{ height: '18px', marginLeft: '4px' }}>
      <IconInfoCircle size={18} height={18} color="#B17CE1" />
    </div>
  </MantineTooltip>
);
