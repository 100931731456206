import { API_LIST } from 'common/constants';
import { get } from 'common/helpers';

const path = API_LIST.CATEGORIES.GENERAL;

export const getCategoriesRequest = async () => {
  const { data } = await get(path);

  return data;
};
