export const checkFieldForHtmlElements = str => {
  const div = document.createElement('div');
  div.innerHTML = str;

  const text = div.textContent;
  div.remove();

  return !!text;
};

export const removeHtmlElementsFromString = str => {
  const div = document.createElement('div');
  div.innerHTML = str;

  const text = div.textContent;
  div.remove();

  return text;
};
