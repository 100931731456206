import { Global } from '@mantine/core';

import bold from '../../../assets/fonts/lato/Lato-Bold.ttf';
import medium from '../../../assets/fonts/lato/Lato-Medium.ttf';
import regular from '../../../assets/fonts/lato/Lato-Regular.ttf';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Lato',
            src: `url('${regular}') format("ttf")`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Lato',
            src: `url('${medium}') format("ttf")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Lato',
            src: `url('${bold}') format("ttf")`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  );
}
