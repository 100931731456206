import { useQuery } from '@tanstack/react-query';
import { FormSelect } from 'common/components/ui';
import { API_TAGS } from 'common/constants';
import { getS3VideosListRequest } from 'common/services';

const toSelect = videoName => ({ value: videoName, label: videoName });

const config = {
  queryKey: [API_TAGS.s3VideosList],
  initialData: () => ({ downloads: [] }),
  queryFn: () => getS3VideosListRequest(),
  select: ({ downloads }) => {
    return downloads.map(toSelect);
  },
};

export const VideosListSelect = ({ name, label, placeholder, withAsterisk, ...props }) => {
  const { isInitialLoading, data } = useQuery(config);

  return (
    <FormSelect
      data={data}
      name={name}
      label={label}
      loading={isInitialLoading}
      placeholder={placeholder}
      withAsterisk={withAsterisk}
      searchable
      {...props}
    />
  );
};
