import { useRouteError } from 'react-router-dom';
import { createStyles, Title, Text, Container, Center } from '@mantine/core';

const useStyles = createStyles(theme => ({
  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export const PageRouterError = () => {
  const { classes } = useStyles();
  const error = useRouteError();

  return (
    <Center sx={{ width: '100%', height: '100%' }} dir="">
      <Container>
        <div className={classes.label}>{error.response?.status}</div>
        <Title className={classes.title}>You have found a secret place.</Title>
        <Text color="dimmed" size="lg" align="center" className={classes.description}>
          {error.statusText || error.message}
        </Text>
      </Container>
    </Center>
  );
};
