import { Box, Space, Title } from '@mantine/core';

const defaultStyle = theme => ({
  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4],
  padding: theme.spacing.md,
  borderRadius: theme.radius.md,
});

export const LessonArea = ({ title, titleOrder = 4, sx = defaultStyle, children }) => {
  return (
    <Box sx={sx}>
      {title && (
        <>
          <Title order={titleOrder}>{title}</Title>
          <Space h="md" />
        </>
      )}

      {children}
    </Box>
  );
};
