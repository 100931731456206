import { useMantineTheme, Text } from '@mantine/core';
import ReactQuill from 'react-quill';
import { useFormContext, useController } from 'react-hook-form';

import 'react-quill/dist/quill.snow.css';
import './textEditor.css';

const textEditorModules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link'],
      ['clean'],
    ],
  },
  clipboard: { matchVisual: false },
};

const textEditorFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'align',
];

export const TextEditor = ({ label = '', name }) => {
  const theme = useMantineTheme();

  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {label && (
        <Text size={14} weight={500}>
          {label}
          <Text span sx={{ color: '#e03131' }}>
            {' '}
            *
          </Text>
        </Text>
      )}
      <ReactQuill
        theme="snow"
        value={field.value}
        onChange={field.onChange}
        formats={textEditorFormats}
        modules={textEditorModules}
        className={`${theme.colorScheme === 'light' && 'quill-light'} ${error && 'quill-error'}`}
        ref={field.ref}
      />
      <Text size={12} color="#e03131">
        {error?.message}
      </Text>
    </>
  );
};
