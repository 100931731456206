import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { invalidateQueries } from 'common/helpers';
import { API_TAGS } from 'common/constants';
import { putCourseLessonsRequest } from 'common/services';

const title = 'general.notifications.modules.lessons';

export const useUpdateLessons = ({ courseID, t }) => {
  const noticeID = 'lessons';

  return useMutation({
    mutationFn: async data => {
      const { lessons } = data;
      const reqData = {
        lessons: lessons.map(({ lessonID }, index) => ({
          position: index + 1,
          id: lessonID,
        })),
      };

      await putCourseLessonsRequest({
        courseID,
        reqData,
      });
      await invalidateQueries({ queryKey: [API_TAGS.course, courseID] });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updating')}`,
      });
    },
    onSuccess: () => {
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updated')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
