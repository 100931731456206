import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { deleteCourseLessonTimecodeRequest } from 'common/services';

const title = 'general.notifications.modules.time-code';

export const useRemoveTimecode = ({ courseID, t }) => {
  const noticeID = 'time-code';

  return useMutation({
    mutationFn: props => {
      const {
        _meta: { lessonID, contentID, timecodeID },
      } = props;

      return deleteCourseLessonTimecodeRequest({ courseID, lessonID, contentID, timecodeID });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
