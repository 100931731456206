const translations = {
  title: 'features.benefits.title',
  fields: {
    title: 'features.benefits.fields.title',
    description: 'features.benefits.fields.description',
  },
  buttons: {
    add: 'features.benefits.buttons.add',
    save: 'features.benefits.buttons.save',
  },
  modals: {
    delete: {
      title: 'features.benefits.modals.delete.title',
      caption: 'features.benefits.modals.delete.caption',
      confirm: 'features.benefits.modals.delete.buttons.confirm',
      cancel: 'features.benefits.modals.delete.buttons.cancel',
    },
  },
};

export default translations;
