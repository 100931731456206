import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserPermissionsSelector } from '../../app/store/user/user.selectors';

import { comparePermissionObjects } from '../utils';
import { DEFAULT_PERMISSION_CONFIG, INITIAL_PERMISSION_CONFIG } from '../constants';

export const usePermissionConfig = permissionModuleId => {
  const [permission, setPermission] = useState(INITIAL_PERMISSION_CONFIG);

  const permissions = useSelector(getUserPermissionsSelector);

  useEffect(() => {
    const isAllowed = () => {
      if (permissionModuleId in permissions) {
        const result = permissions[permissionModuleId];
        if (comparePermissionObjects(DEFAULT_PERMISSION_CONFIG, result)) {
          setPermission({ ...DEFAULT_PERMISSION_CONFIG, ...result });
        }
      } else {
        console.error('Wrong permission name!!!');
      }
    };

    isAllowed();
  }, [permissionModuleId, permissions]);

  return [permission];
};
