const translations = {
  title: 'pages.users.title',
  fields: {
    name: 'pages.users.fields.name',
    role: 'pages.users.fields.role',
    email: 'pages.users.fields.email',
    phone: 'pages.users.fields.phone',
    telegram: 'pages.users.fields.telegram',
    subscription: 'pages.users.fields.subscription',
  },
  subscriptionForm: {
    titleCreate: 'pages.users.subscription-form.title-create',
    titleChange: 'pages.users.subscription-form.title-change',
    chooseSubscription: 'pages.users.subscription-form.choose-subscription',
    startDate: 'pages.users.subscription-form.start-date',
    endDate: 'pages.users.subscription-form.end-date',
    create: 'pages.users.subscription-form.create',
    save: 'pages.users.subscription-form.save',
  },
};

export default translations;
