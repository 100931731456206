import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { deleteCourseLessonContentRequest } from 'common/services';

const title = 'general.notifications.modules.content';

export const useRemoveLessonContent = ({ courseID, t }) => {
  const noticeID = 'content';

  return useMutation({
    mutationFn: ({ _meta: { lessonID, contentID } }) => {
      return deleteCourseLessonContentRequest({ courseID, lessonID, contentID });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
