import { queryClient } from 'common/helpers';

import { useMutation } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { removeBenefitRequest } from 'common/services';

const title = 'general.notifications.modules.benefit';

export const useRemoveBenefit = ({ t }) => {
  const noticeID = 'removingBenefitsList';

  return useMutation({
    mutationFn: id => {
      return removeBenefitRequest(id);
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleting')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.benefits] });

      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.deleted')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};
