import { Flex, TextInput, ActionIcon, Divider, Box } from '@mantine/core';
import { IconTrash, IconGripVertical } from '@tabler/icons';
import { Draggable } from 'react-beautiful-dnd';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { showConfirmationModal } from 'common/utils';

import translations from '../translations';
import { useRemoveBenefit } from '../hooks';

export const BenefitsDragItem = ({ index, field, control, removeField }) => {
  const { t } = useTranslation();

  const { mutate: remove } = useRemoveBenefit({ t });

  const fieldHasId = 'id' in field;

  const openModal = id => {
    return showConfirmationModal({
      title: t(translations.modals.delete.title),
      caption: t(translations.modals.delete.caption),
      labels: {
        confirm: t(translations.modals.delete.confirm),
        cancel: t(translations.modals.delete.cancel),
      },
      onConfirm: () => remove(id),
    });
  };

  const removeHandler = () => {
    if (fieldHasId) {
      openModal(field.id);
    } else {
      removeField(index);
    }
  };

  return (
    <Draggable index={index} draggableId={field.fieldId} key={field.id}>
      {provided => (
        <Box {...provided.draggableProps} ref={provided.innerRef}>
          <Flex
            justify="space-between"
            align="center"
            sx={{
              margin: '8px 0',
              padding: '0 16px',
            }}
          >
            <ActionIcon
              component="div"
              variant="transparent"
              sx={{ cursor: 'grab', color: '#595959' }}
              {...provided.dragHandleProps}
            >
              <IconGripVertical />
            </ActionIcon>
            <Controller
              control={control}
              name={`benefits.${index}.title`}
              render={({ field: inputField }) => (
                <TextInput
                  placeholder={`${t(translations.fields.title)}*`}
                  sx={{ width: '30%' }}
                  {...inputField}
                />
              )}
            />
            <Controller
              control={control}
              name={`benefits.${index}.description`}
              render={({ field: inputField }) => (
                <TextInput
                  placeholder={`${t(translations.fields.description)}*`}
                  sx={{ width: '58%' }}
                  {...inputField}
                />
              )}
            />
            <ActionIcon variant="outline" color="red" onClick={removeHandler}>
              <IconTrash size={16} />
            </ActionIcon>
          </Flex>

          <Divider />
        </Box>
      )}
    </Draggable>
  );
};
