import { API_LIST } from 'common/constants';
import { get, patch, post, put } from 'common/helpers';

const pathCourse = API_LIST.PRICES.COURSE.GENERAL;
const pathCommunity = API_LIST.PRICES.COMMUNITY.GENERAL;

export const getPricesCourseRequest = async ({ courseID, params }) => {
  const { data } = await get(`${pathCourse}/${courseID}`, { params });

  return data;
};

export const addLessonsToPriceRequest = async ({ reqData, id }) => {
  const { data } = await put(`${pathCourse}/${id}/attach-lessons`, reqData);

  return data;
};

export const createPriceCourseRequest = async ({ requestData, lessons }) => {
  const { data } = await post(pathCourse, requestData);

  if (lessons) {
    await addLessonsToPriceRequest({
      reqData: { lessonIds: lessons },
      id: data.price.id,
    });
  }

  return data;
};

export const updatePriceCourseRequest = async ({ reqData, id, lessons }) => {
  const { data } = await patch(`${pathCourse}/${reqData.courseId}/${id}`, reqData);

  if (lessons) {
    await addLessonsToPriceRequest({
      reqData: { lessonIds: lessons },
      id,
    });
  }

  return data;
};

export const getPricesCommunityRequest = async params => {
  const { data } = await get(pathCommunity, { params });

  return data;
};

export const addCategoriesToPriceRequest = async ({ reqData, id }) => {
  const { data } = await put(`${pathCommunity}/${id}/attach-categories`, reqData);

  return data;
};

export const createPriceCommunityRequest = async ({ requestData, categoriesToAdd }) => {
  const { data } = await post(pathCommunity, requestData);

  if (categoriesToAdd) {
    await addCategoriesToPriceRequest({
      reqData: { categoryIds: categoriesToAdd },
      id: data.price.id,
    });
  }

  return data;
};

export const updatePriceCommunityRequest = async ({ reqData, id, categoriesToAdd }) => {
  const { data } = await put(`${pathCommunity}/${id}`, reqData);

  if (categoriesToAdd) {
    await addCategoriesToPriceRequest({
      reqData: { categoryIds: categoriesToAdd },
      id,
    });
  }

  return data;
};
