import { updateNotification } from '@mantine/notifications';
import { ReactComponent as AlertIcon } from 'common/assets/icons/alert-icon.svg';
import { isString } from './core';

export const getAPIErrorMessage = error => {
  const { response, message } = error;

  if (isString(response?.data?.error)) {
    return response?.data?.error;
  }

  if (isString(response?.data?.message)) {
    return response?.data?.message;
  }

  if (isString(message)) {
    return message;
  }

  return 'An error occurred';
};

export const handleQueryError = (error, id) => {
  updateNotification({
    id,
    message: getAPIErrorMessage(error),
    color: 'red',
    icon: <AlertIcon />,
    styles: { icon: { background: 'none !important' } },
    closeButtonProps: { iconSize: 12 },
    sx: { backgroundColor: '#222', borderRadius: 8 },
  });
};
