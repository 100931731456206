import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { MainLayout, PageRouterError, PrivateRoute } from 'common/components/layouts';

// Loaders
import { courseLoader } from 'pages/course/Course';
import { userLoader } from 'pages/user/User';
import { groupLoader } from 'pages/group/Group';
import { localeLoader } from 'pages/locales/Locale';
// import { notificationLoader } from 'pages/notification/Notification';
// import { templateLoader } from 'pages/template/Template';

import { ROUTER_BOOK } from './routerBook';

// Pages
const Courses = lazy(() => import('pages/courses/Courses'));
const Course = lazy(() => import('pages/course/Course'));

const Users = lazy(() => import('pages/users/Users'));
const User = lazy(() => import('pages/user/User'));

const Groups = lazy(() => import('pages/groups/Groups'));
const Group = lazy(() => import('pages/group/Group'));

const Locales = lazy(() => import('pages/locales/Locales'));
const Locale = lazy(() => import('pages/locales/Locale'));

const CheckAnswer = lazy(() => import('pages/homeWorks/nestedRoutes/check/CheckAnswer'));
const HomeworkCreate = lazy(() => import('pages/homeWorks/nestedRoutes/new/HomeworkCreate'));

const Payments = lazy(() => import('pages/payments/Payments'));

// const SendNotification = lazy(() => import('pages/sendNotification/SendNotification'));
// const Notifications = lazy(() => import('pages/notifications/Notifications'));
// const Notification = lazy(() => import('pages/notification/Notification'));
// const Templates = lazy(() => import('pages/templates/Templates'));
// const Template = lazy(() => import('pages/template/Template'));

const Comments = lazy(() => import('pages/comments/Comments'));
const CommentsCourses = lazy(() => import('pages/comments/CommentsCourses'));
const CommentsLessons = lazy(() => import('pages/comments/CommentsLessons'));

const Streams = lazy(() => import('pages/streams/Streams'));
const Stream = lazy(() => import('pages/stream/Stream'));

const StreamBanners = lazy(() => import('pages/streamBanners/StreamBanners'));
const StreamBanner = lazy(() => import('pages/streamBanner/StreamBanner'));

const Events = lazy(() => import('pages/events/Events'));
const Event = lazy(() => import('pages/event/Event'));

const Employee = lazy(() => import('pages/businessAccount/nestedRoutes/employee/Employee'));
const InvitedEmployees = lazy(() =>
  import('pages/businessAccount/nestedRoutes/invited-employees/InvitedEmployees'),
);

const AccessRules = lazy(() =>
  import('pages/businessAccount/nestedRoutes/access-rules/AccessRules'),
);

const OwnerSetup = lazy(() => import('pages/ownerSetup/OwnerSetup'));
const OwnerSetupAddInfo = lazy(() => import('pages/ownerSetup/nested-routes/AddInfo/AddInfo'));
const OwnerSetupCommunity = lazy(() =>
  import('pages/ownerSetup/nested-routes/SetupCommunity/SetupCommunity'),
);
const OwnerSetupCommunityPrices = lazy(() =>
  import('pages/ownerSetup/nested-routes/CommunityPrices/CommunityPrices'),
);

const S3Videos = lazy(() => import('pages/s3Videos/S3Videos'));

const Earnings = lazy(() => import('pages/earnings/Earnings'));

export const privateRoutes = [
  {
    element: (
      <PrivateRoute>
        <MainLayout />
      </PrivateRoute>
    ),
    children: [
      {
        errorElement: <PageRouterError />,
        children: [
          {
            path: ROUTER_BOOK.private.OWNER_SETUP.GENERAL,
            children: [
              { index: true, element: <OwnerSetup /> },
              { path: ROUTER_BOOK.private.OWNER_SETUP.ADD_INFO, element: <OwnerSetupAddInfo /> },
              {
                path: ROUTER_BOOK.private.OWNER_SETUP.SETUP_COMMUNITY,
                element: <OwnerSetupCommunity />,
              },
              {
                path: ROUTER_BOOK.private.OWNER_SETUP.COMMUNITY_PRICES,
                element: <OwnerSetupCommunityPrices />,
              },
            ],
          },
          {
            path: ROUTER_BOOK.private.BUSINESS_ACCOUNT.GENERAL,
            children: [
              {
                path: ROUTER_BOOK.private.BUSINESS_ACCOUNT.BRAND_INFO.GENERAL,
                element: <OwnerSetupAddInfo />,
              },
              {
                path: ROUTER_BOOK.private.BUSINESS_ACCOUNT.EMPLOYEES.GENERAL,
                element: <Employee />,
              },
              {
                path: ROUTER_BOOK.private.BUSINESS_ACCOUNT.INVITATION.GENERAL,
                element: <InvitedEmployees />,
              },
              {
                path: ROUTER_BOOK.private.BUSINESS_ACCOUNT.ACCESS_RULES.GENERAL,
                element: <AccessRules />,
              },
              {
                path: ROUTER_BOOK.private.BUSINESS_ACCOUNT.PRICINGS.GENERAL,
                element: <OwnerSetupCommunityPrices />,
              },
            ],
          },
          {
            path: ROUTER_BOOK.private.STREAMS.GENERAL,
            children: [
              {
                index: true,
                element: <Streams />,
              },
              {
                path: ROUTER_BOOK.private.STREAMS.CREATE,
                element: <Stream />,
              },
              {
                path: ROUTER_BOOK.private.STREAMS.ID,
                element: <Stream />,
              },
            ],
          },
          {
            path: ROUTER_BOOK.private.BANNERS.GENERAL,
            children: [
              {
                path: ROUTER_BOOK.private.BANNERS.GENERAL,
                element: <StreamBanners />,
              },
              {
                path: ROUTER_BOOK.private.BANNERS.CREATE,
                element: <StreamBanner />,
              },
              {
                path: ROUTER_BOOK.private.BANNERS.ID,
                element: <StreamBanner />,
              },
            ],
          },
          {
            path: ROUTER_BOOK.private.COURSES.GENERAL,
            children: [
              {
                index: true,
                element: <Courses />,
              },
              {
                path: ROUTER_BOOK.private.COURSES.MANAGEMENT,
                element: <Courses />,
              },
              {
                path: ROUTER_BOOK.private.COURSES.S3_VIDEOS,
                element: <S3Videos />,
              },
              {
                path: ROUTER_BOOK.private.COURSES.CREATE,
                element: <Course />,
              },
              {
                path: ROUTER_BOOK.private.COURSES.ID,
                element: <Course />,
                loader: courseLoader,
              },
              {
                path: ROUTER_BOOK.private.COURSES.GROUPS.GENERAL,
                children: [
                  {
                    index: true,
                    element: <Groups />,
                  },
                  {
                    path: ROUTER_BOOK.private.COURSES.GROUPS.ID,
                    loader: groupLoader,
                    element: <Group />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTER_BOOK.private.HOME_WORKS.GENERAL,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTER_BOOK.private.HOME_WORKS.CREATE.GENERAL} />,
              },
              {
                path: ROUTER_BOOK.private.HOME_WORKS.CREATE.GENERAL,
                children: [{ index: true, element: <HomeworkCreate /> }],
              },
              {
                path: ROUTER_BOOK.private.HOME_WORKS.CHECK.GENERAL,
                element: <CheckAnswer />,
              },
            ],
          },

          {
            path: ROUTER_BOOK.private.USERS.GENERAL,
            children: [
              {
                index: true,
                element: <Users />,
              },
              {
                path: ROUTER_BOOK.private.USERS.ID,
                element: <User />,
                loader: userLoader,
              },
            ],
          },

          {
            path: ROUTER_BOOK.private.LOCALES.GENERAL,
            children: [
              {
                index: true,
                element: <Locales />,
              },
              {
                path: ROUTER_BOOK.private.LOCALES.CREATE,
                element: <Locale />,
              },
              {
                path: ROUTER_BOOK.private.LOCALES.ID,
                loader: localeLoader,
                element: <Locale />,
              },
            ],
          },

          {
            path: ROUTER_BOOK.private.PAYMENTS.GENERAL,
            element: <Payments />,
          },

          // {
          //   path: ROUTER_BOOK.private.NOTIFICATIONS.SEND_NOTIFICATION,
          //   element: <SendNotification />,
          // },
          // {
          //   path: ROUTER_BOOK.private.NOTIFICATIONS.NOTIFICATIONS,
          //   children: [
          //     { index: true, element: <Notifications /> },
          //     {
          //       path: ROUTER_BOOK.private.NOTIFICATIONS.NOTIFICATION_CREATE,
          //       element: <Notification />,
          //     },
          //     {
          //       path: ROUTER_BOOK.private.NOTIFICATIONS.NOTIFICATIONS_ID,
          //       loader: notificationLoader,
          //       element: <Notification />,
          //     },
          //   ],
          // },
          // {
          //   path: ROUTER_BOOK.private.NOTIFICATIONS.TEMPLATES,
          //   children: [
          //     { index: true, element: <Templates /> },
          //     {
          //       path: ROUTER_BOOK.private.NOTIFICATIONS.TEMPLATE_CREATE,
          //       element: <Template />,
          //     },
          //     {
          //       path: ROUTER_BOOK.private.NOTIFICATIONS.TEMPLATES_ID,
          //       loader: templateLoader,
          //       element: <Template />,
          //     },
          //   ],
          // },

          {
            path: ROUTER_BOOK.private.COMMENTS.GENERAL,
            children: [
              { index: true, element: <CommentsCourses /> },
              { path: ROUTER_BOOK.private.COMMENTS.LESSONS, element: <CommentsLessons /> },
              { path: ROUTER_BOOK.private.COMMENTS.COMMENTS, element: <Comments /> },
            ],
          },
          {
            path: ROUTER_BOOK.private.EVENTS.GENERAL,
            children: [
              {
                index: true,
                element: <Events />,
              },
              {
                path: ROUTER_BOOK.private.EVENTS.CREATE,
                element: <Event />,
              },
              {
                path: ROUTER_BOOK.private.EVENTS.ID,
                element: <Event />,
              },
            ],
          },
          {
            path: ROUTER_BOOK.private.EARNINGS.GENERAL,
            element: <Earnings />,
          },

          {
            path: '*',
            element: <Navigate to={ROUTER_BOOK.private.OWNER_SETUP.GENERAL} />,
          },
        ],
      },
    ],
  },
];
