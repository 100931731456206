import { Accordion, Paper, Stack } from '@mantine/core';
import { FormControl, NotFound } from 'common/components/ui';
import { useFormContext, useWatch } from 'react-hook-form';

const name = 'groupStreams';

export const GroupStreams = () => {
  const { control } = useFormContext();

  const list = useWatch({
    name,
    control,
  });

  const hasItems = Boolean(list?.length);

  return (
    <>
      {!hasItems && <NotFound />}

      {hasItems && (
        <Accordion variant="contained">
          {list.map((item, index) => (
            <Accordion.Item value={`item_${item.id}`} key={item.id}>
              <Accordion.Control>{`User ${item.id}`}</Accordion.Control>
              <Accordion.Panel>
                <Paper shadow="xs" p="md">
                  <Stack>
                    <FormControl name={`${name}.${index}.id`} disabled label="Id" />
                    <FormControl name={`${name}.${index}.url`} disabled label="Url" />
                    <FormControl name={`${name}.${index}.startDate`} disabled label="Start Date" />
                    <FormControl name={`${name}.${index}.groupId`} disabled label="Group Id" />
                    <FormControl
                      disabled
                      name={`${name}.${index}.lessonContentId`}
                      label="Lesson Content Id"
                    />
                  </Stack>
                </Paper>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </>
  );
};
