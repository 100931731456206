import { showNotification } from '@mantine/notifications';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { getAPIErrorMessage } from 'common/utils/reactQueries';

import { ReactComponent as AlertIcon } from 'common/assets/icons/alert-icon.svg';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (err, query) => {
      if (!query.options.meta?.shouldNotBeHandledByGlobalErrorHandler) {
        showNotification({
          color: 'red',
          message: getAPIErrorMessage(err),
          icon: <AlertIcon />,
          styles: { icon: { background: 'none !important' } },
          closeButtonProps: { iconSize: 12 },
          sx: { backgroundColor: '#222', borderRadius: 8 },
        });
      }
    },
  }),
});

export const invalidateQueries = settings => queryClient.invalidateQueries(settings);
