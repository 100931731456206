import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FileInput } from '@mantine/core';
import { IconUpload } from '@tabler/icons';

import { isFileInstance } from 'common/utils';

export const FormFileInput = ({
  name,
  accept = 'image/png, image/jpeg, image/svg',
  ...otherProps
}) => {
  const { control } = useFormContext();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const file = useMemo(() => {
    if (!value || isFileInstance(value)) {
      return value;
    }

    return {
      name: value,
    };
  }, [value]);

  return (
    <FileInput
      onChange={onChange}
      accept={accept}
      icon={<IconUpload size={14} />}
      value={file}
      error={error?.message}
      {...otherProps}
    />
  );
};
