import { useCallback, useEffect } from 'react';
import { Flex, Button, Text, Divider } from '@mantine/core';
import { useForm, useFieldArray } from 'react-hook-form';
import { IconPlus } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { Tooltip, Loader } from 'common/components/ui';

import { BenefitsDragAndDrop } from './components/BenefitsDragAndDrop';
import { useUpdateBenefitsList, useCreateBenefitsList } from './hooks';
import translations from './translations';

export const BenefitsForm = ({
  benefits,
  businessAccountId = null,
  courseId = null,
  isLoading,
  isFetching,
}) => {
  const { t } = useTranslation();

  const { mutate: updateBenefitsList } = useUpdateBenefitsList({ t });
  const { mutate: createBenefitsList } = useCreateBenefitsList({ t });

  const {
    control,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      benefits,
    },
  });

  const { fields, append, swap, remove, replace } = useFieldArray({
    control,
    name: 'benefits',
    keyName: 'fieldId',
  });

  useEffect(() => {
    replace(benefits);
  }, [benefits, replace]);

  const lastField = fields.at(-1);
  const lastPosition = lastField?.position ?? 0;

  const addBenefitFieldHandler = () => {
    append({ title: '', description: '', position: lastPosition + 1 });
  };

  const onUpdatePosition = useCallback(
    (index, position) => {
      setValue(`benefits.${index}.position`, position);
    },
    [setValue],
  );

  const onChange = useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }

      const indexFrom = source.index;
      const indexTo = destination.index;

      const sourcePosition = fields[indexFrom].position;
      const destinationPosition = fields[indexTo].position;

      onUpdatePosition(indexFrom, destinationPosition);
      onUpdatePosition(indexTo, sourcePosition);

      swap(indexFrom, indexTo);
    },
    [swap, fields, onUpdatePosition],
  );

  const watchedFields = watch('benefits');

  const onSubmitHandler = () => {
    const isUpdate = watchedFields.every(item => 'id' in item);

    const newFields = watchedFields.filter(item => !('id' in item));

    if (isUpdate) {
      const benefitsToUpdate = watchedFields.map(({ id, title, description, position }) => ({
        id,
        title,
        description,
        position,
      }));

      updateBenefitsList(benefitsToUpdate);
    } else {
      const benefitsToCreate = newFields.map(({ title, description, position }) => {
        if (courseId) {
          return {
            courseId: +courseId,
            title,
            description,
            position,
          };
        }

        return {
          businessAccountId,
          title,
          description,
          position,
        };
      });

      createBenefitsList({ benefits: benefitsToCreate });
    }
  };

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <Flex
      direction="column"
      sx={{
        boxSizing: 'border-box',
        padding: '16px 0',
      }}
    >
      <Text
        size={14}
        color="#C1C2C5"
        sx={{
          width: 'fit-content',
          marginBottom: '8px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>{t(translations.title)}</span>
        <Tooltip label={t('tooltips.benefits')} />
      </Text>
      <Divider />

      <BenefitsDragAndDrop
        fields={fields}
        onChange={onChange}
        control={control}
        removeField={remove}
      />

      <Flex justify="space-between" sx={{ marginTop: '16px', paddingLeft: '16px' }}>
        <Button
          variant="outline"
          leftIcon={<IconPlus size={18} />}
          onClick={addBenefitFieldHandler}
          sx={{ border: 'none' }}
          disabled={watchedFields?.length === 9}
        >
          {t(translations.buttons.add)}
        </Button>

        <Button onClick={onSubmitHandler} disabled={!isDirty}>
          {t(translations.buttons.save)}
        </Button>
      </Flex>
    </Flex>
  );
};
