import { createSelector } from '@reduxjs/toolkit';

export const getUserPermissionsSelector = state => state.user.permissions;
export const getUserBusinessAccountSelector = state => state.user.businessAccount;
export const getUserInfoSelector = state => state.user.user;

// Selector to get the keys with read === true
export const getReadAccessKeysSelector = createSelector(getUserPermissionsSelector, permissions => {
  return Object.keys(permissions).filter(key => permissions[key].read === true);
});
