import {
  createCourseImageRequest,
  createCourseLandingBgImageRequest,
  createCourseLandingImageRequest,
  putCourseMetaRequest,
  updateCourseRequest,
} from 'common/services';

export const mutateCourse = ({ courseID, requestData }) => {
  const { generalImage, landingImage, landingBackgroundImage, courseMetas, ...courseData } =
    requestData;

  const requests = [];

  requests.push(updateCourseRequest({ reqData: courseData, courseID }));
  requests.push(putCourseMetaRequest({ reqData: { courseMetas }, courseID }));

  if (generalImage) {
    const request = createCourseImageRequest({
      courseID,
      file: generalImage,
    });

    requests.push(request);
  }

  if (landingImage) {
    const request = createCourseLandingImageRequest({
      courseID,
      file: landingImage,
    });

    requests.push(request);
  }

  if (landingBackgroundImage) {
    const request = createCourseLandingBgImageRequest({
      courseID,
      file: landingBackgroundImage,
    });

    requests.push(request);
  }

  return Promise.all(requests);
};
