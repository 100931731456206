import { useState } from 'react';
import { SimpleGrid, Grid, Button, Select, Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { IconDeviceFloppy, IconCirclePlus } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { Form, FormControl } from 'common/components/ui';
import { useGetPriceCommunity } from 'common/hooks/api/prices/queries';

import translations from '../../translations';
import { useUpdateSubscriptionDuration, useCreateSubscriptionForUser } from '../../hooks/queries';

export const Details = ({ formValues }) => {
  const { t } = useTranslation();

  const { activeCommunitySubscription } = formValues;

  const [startDate, setStartDate] = useState(new Date(dayjs(Date.now()).format('YYYY, MM, DD')));
  const [endDate, setEndDate] = useState(
    new Date(dayjs(activeCommunitySubscription?.endDate).format('YYYY, MM, DD')),
  );
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(null);

  const formMethods = useForm({
    values: formValues,
  });

  const { data } = useGetPriceCommunity();

  const dataForSelect = data
    ?.filter(item => item.isActive)
    .map(item => {
      return {
        value: item.id,
        label: item.title,
      };
    });

  const isSaveButtonDisabled =
    endDate.getTime() ===
    new Date(dayjs(activeCommunitySubscription?.endDate).format('YYYY, MM, DD')).getTime();

  const isCreateButtonDisabled = startDate.getTime() === endDate.getTime();

  const { mutate: updateSubscription } = useUpdateSubscriptionDuration(
    activeCommunitySubscription?.id,
  );
  const { mutate: createSubscription } = useCreateSubscriptionForUser();

  const updateSubscriptionDurationHandler = () =>
    updateSubscription({
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    });

  const createSubscriptionHandler = () =>
    createSubscription({
      userId: formValues.id,
      priceCommunityId: selectedSubscriptionType,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    });

  return (
    <>
      <Form formMethods={formMethods} renderFooter={<div />}>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }]}>
          <FormControl label="ID" name="id" readOnly />
          <FormControl label={t(translations.fields.name)} name="name" readOnly />
          <FormControl label={t(translations.fields.role)} name="role" readOnly />
          <FormControl label={t(translations.fields.email)} name="email" readOnly />
          <FormControl label={t(translations.fields.phone)} name="phoneNumber" readOnly />
          <FormControl label={t(translations.fields.telegram)} name="telegramUsername" readOnly />
          <FormControl label="Discord ID" name="discordId" readOnly />
          <FormControl
            label={t(translations.fields.subscription)}
            name="activeCommunitySubscription.type"
            readOnly
          />
        </SimpleGrid>
      </Form>
      <Grid>
        {activeCommunitySubscription ? (
          <Grid.Col span={4}>
            <Text size={20} sx={{ marginBottom: '15px' }}>
              {t(translations.subscriptionForm.titleChange)}
            </Text>
            <DatePicker
              value={endDate}
              onChange={setEndDate}
              label={t(translations.subscriptionForm.endDate)}
            />
            <Button
              onClick={updateSubscriptionDurationHandler}
              leftIcon={<IconDeviceFloppy />}
              disabled={isSaveButtonDisabled}
              sx={{ width: '100%', marginTop: '15px' }}
            >
              {t(translations.subscriptionForm.save)}
            </Button>
          </Grid.Col>
        ) : (
          dataForSelect && (
            <Grid.Col span={4}>
              <Text size={20} sx={{ marginBottom: '15px' }}>
                {t(translations.subscriptionForm.titleCreate)}
              </Text>
              <Select
                label={t(translations.subscriptionForm.chooseSubscription)}
                placeholder="Choose subscription"
                data={dataForSelect}
                value={selectedSubscriptionType}
                onChange={setSelectedSubscriptionType}
              />
              <DatePicker
                value={startDate}
                onChange={setStartDate}
                label={t(translations.subscriptionForm.startDate)}
                disabled
                sx={{ marginTop: '15px' }}
              />
              <DatePicker
                value={endDate}
                onChange={setEndDate}
                label={t(translations.subscriptionForm.endDate)}
                excludeDate={date => date.getTime() < endDate.getTime()}
                sx={{ marginTop: '15px' }}
              />
              <Button
                onClick={createSubscriptionHandler}
                leftIcon={<IconCirclePlus />}
                disabled={isCreateButtonDisabled}
                sx={{ width: '100%', marginTop: '15px' }}
              >
                {t(translations.subscriptionForm.create)}
              </Button>
            </Grid.Col>
          )
        )}
      </Grid>
    </>
  );
};
