import { LESSON_TYPE_ENUM } from 'common/enums';

export const createLessonContentMetaItem = lang => ({
  lang,
  video: '',
});

export const createLessonVideoTimecodeItem = lang => ({
  lang,
  title: '',
});

export const createLessonVideoItem = lang => ({
  time: '',
  videoTimecodeMetas: [createLessonVideoTimecodeItem(lang)],
});

export const createLessonContentItem = lang => ({
  type: LESSON_TYPE_ENUM.VIDEO,
  duration: '',
  videoTimecodes: [createLessonVideoItem(lang)],
  lessonContentMetas: [createLessonContentMetaItem(lang)],
});

export const createLessonMetaItem = lang => ({
  lang,
  title: '',
  description: '',
});

export const createLessonItem = lang => ({
  position: 1,
  lessonMetas: [createLessonMetaItem(lang)],
  lessonContents: [createLessonContentItem(lang)],
  delay: 0,
});

export const createLessonItemWithEmptyMeta = () => ({
  position: 1,
  lessonMetas: [],
  lessonContents: [],
  delay: 0,
});

export const createDefaultValues = lang => ({
  lessons: [createLessonItem(lang)],
});
