const translations = {
  step1: {
    fields: {
      name: {
        label: 'pages.courses.children.create.step-1.fields.name.label',
        placeholder: 'pages.courses.children.create.step-1.fields.name.placeholder',
      },
      description: {
        label: 'pages.courses.children.create.step-1.fields.description.label',
        placeholder: 'pages.courses.children.create.step-1.fields.description.placeholder',
      },
      status: {
        label: 'pages.courses.children.create.step-1.fields.status.label',
        placeholder: 'pages.courses.children.create.step-1.fields.status.placeholder',
      },
      groupCapacity: {
        label: 'pages.courses.children.create.step-1.fields.group-capacity.label',
        placeholder: 'pages.courses.children.create.step-1.fields.group-capacity.placeholder',
      },
      category: {
        label: 'pages.courses.children.create.step-1.fields.category.label',
        placeholder: 'pages.courses.children.create.step-1.fields.category.placeholder',
      },
      logo: {
        label: 'pages.courses.children.create.step-1.fields.logo.label',
        placeholder: 'pages.courses.children.create.step-1.fields.logo.placeholder',
      },
    },
  },
};

export default translations;
