import dayjs from 'dayjs';

// UIKit
import { DatePicker, DateRangePicker, TimeInput, TimeRangeInput } from '@mantine/dates';
import { Input, SimpleGrid } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { IconCalendar, IconClock } from '@tabler/icons';

const changeSingleTime = ({ newTime, prevDate }) => {
  const hour = dayjs(newTime).get('hour');
  const minute = dayjs(newTime).get('minute');

  return dayjs(prevDate).set('hour', hour).set('minute', minute).toDate();
};

export const DateTimePicker = ({
  label,
  withAsterisk,
  error,
  isRange = false,
  field,
  ...otherProps
}) => {
  const { value, onChange, ...fieldProps } = field;
  const DateElement = useMemo(() => (isRange ? DateRangePicker : DatePicker), [isRange]);
  const TimeElement = useMemo(() => (isRange ? TimeRangeInput : TimeInput), [isRange]);

  const disabledTimePicker = useMemo(() => {
    if (isRange) {
      return !value.every(val => dayjs(val).isValid());
    }

    return Boolean(!value);
  }, [isRange, value]);

  const onTimeChange = useCallback(
    time => {
      if (Array.isArray(time)) {
        const dateTimes = time.map((newTime, index) => {
          if (!newTime) return newTime;

          return changeSingleTime({ newTime, prevDate: value[index] });
        });

        return onChange(dateTimes);
      }

      const dateTime = changeSingleTime({ newTime: time, prevDate: value });
      return onChange(dateTime);
    },
    [value, onChange],
  );

  const excludeDate = useCallback(date => {
    return dayjs(date).isBefore(dayjs(), 'date');
  }, []);

  return (
    <Input.Wrapper
      label={label}
      withAsterisk={withAsterisk}
      sx={{ Label: { display: 'inline-flex' } }}
    >
      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1, spacing: 'sm' }]}>
        <DateElement
          placeholder="Pick date"
          icon={<IconCalendar size={16} />}
          error={error}
          clearable={false}
          value={value}
          onChange={onChange}
          excludeDate={excludeDate}
          {...fieldProps}
          {...otherProps}
        />

        <TimeElement
          error={error}
          value={value}
          onChange={onTimeChange}
          placeholder="Pick time"
          disabled={disabledTimePicker}
          icon={<IconClock size={16} />}
        />
      </SimpleGrid>
    </Input.Wrapper>
  );
};
