import { API_LIST } from 'common/constants';
import { destroy, get, patch, post } from 'common/helpers';

const path = API_LIST.BENEFITS.GENERAL;

export const getBenefitsRequest = async params => {
  const { data } = await get(path, { params });

  return data;
};

export const createBenefitsRequest = async reqData => {
  const { data } = await post(path, reqData);

  return data;
};

export const updateBenefitRequest = async (id, reqData) => {
  const { data } = await patch(`${path}/${id}`, reqData);

  return data;
};

export const updateBenefitsListRequest = async reqData => {
  const { data } = await patch(path, reqData);

  return data;
};

export const removeBenefitRequest = async id => {
  const { data } = await destroy(`${path}/${id}`);

  return data;
};
