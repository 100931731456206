import { useQuery } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { getBenefitsRequest } from 'common/services';

export const useGetBenefits = params => {
  return useQuery({
    queryKey: [API_TAGS.benefits, params],
    queryFn: () => getBenefitsRequest(params),
    enabled: !!params.businessAccountId || !!params.courseId,
  });
};
