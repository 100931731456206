// UIKit
import { Center, Loader as MantineLoader } from '@mantine/core';

const sx = theme => ({
  backgroundColor: theme.colorScheme === 'dark' ? '#0B0B0B' : theme.colors.gray[0],
  width: '100%',
  height: '100%',
});

export const Loader = ({ size = 'lg' }) => {
  return (
    <Center sx={sx}>
      <MantineLoader color="#B17CE1" size={size} />
    </Center>
  );
};
