/* eslint-disable no-restricted-syntax */
import { isUndef } from 'common/utils/core';
import { useCallback } from 'react';
import { useSearchParams as useDomSearchParams } from 'react-router-dom';

export const useSearchParams = () => {
  const [searchParams, updateSearchParams] = useDomSearchParams();

  const setSearchParams = useCallback(
    props => {
      for (const [key, value] of Object.entries(props)) {
        if (isUndef(value) || value === '') searchParams.delete(key);
        else searchParams.set(key, value);
      }

      updateSearchParams(searchParams);
    },
    [searchParams, updateSearchParams],
  );

  return [searchParams, setSearchParams];
};
