import { useCallback, useState } from 'react';

export const useStepper = (initialStep = 0) => {
  const [activeStep, setActiveStep] = useState(initialStep);

  const toNextStep = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, []);

  const toPrevStep = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, []);

  return [activeStep, toPrevStep, toNextStep, setActiveStep];
};
