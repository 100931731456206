export const PERMISSION_IDS = Object.freeze({
  businessAccount: 'businessAccount',
  stream: 'stream',
  course: 'course',
  homework: 'homework',
  group: 'group',
  calendar: 'calendar',
  lessonComment: 'lessonComment',
  price: 'price',
  user: 'user',
  stripe: 'stripe',
});

export const DEFAULT_PERMISSION_CONFIG = Object.freeze({
  create: true,
  read: true,
  update: true,
  delete: true,
});

export const INITIAL_PERMISSION_CONFIG = Object.freeze({
  create: false,
  read: false,
  update: false,
  delete: false,
});
