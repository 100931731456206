/**
 * Get intersection of arrays
 * @param {array} left.           Compared array.
 * @param {array} right.          Array to compare with.
 * @param {func} compareFunction. Function to compare array items.
 * @returns array with only the same items.
 */
export const intersectionOfArrays = (left, right, compareFunction) => {
  return left.filter(leftValue => right.some(rightValue => compareFunction(leftValue, rightValue)));
};

/**
 * Get different arrays items.
 * @param {array} left.           Compared array.
 * @param {array} right.          Array to compare with.
 * @param {func} compareFunction. Function to compare array items.
 * @returns array with only the different items.
 */
export const noIntersectionOfArrays = (left, right, compareFunction) => {
  return left.filter(
    leftValue => !right.some(rightValue => compareFunction(leftValue, rightValue)),
  );
};

/**
 * Compare two objects by ID.
 * @param {object} left. Object with ID field.
 * @param {object} right, Object with ID field.
 * @returns Boolean is same objects.
 */
const getObjectWithSameID = (left, right) => left?.id === right?.id;

// Intersections arrays of objects
export const intersOfArraysOfObjectsByID = (left, right) => {
  return intersectionOfArrays(left, right, getObjectWithSameID);
};

// No intersections arrays of objects
export const noIntersOfArraysOfObjectsByID = (left, right) => {
  return noIntersectionOfArrays(left, right, getObjectWithSameID);
};

export const isEvery = (list = []) => list.every(value => value);

export const removeEmptyElements = arr => {
  if (!Array.isArray(arr)) return arr;
  return arr.filter(element => element && Object.keys(element).length !== 0);
};
