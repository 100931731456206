import { Button, Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import translations from './translations';

export const StepFooter = ({ loading, disabled, activeStep, onBackClick, onForwardClick }) => {
  const { t } = useTranslation();

  const isFirstStep = activeStep === 0;
  const isSecondStep = activeStep === 1;

  return (
    <Group position="apart">
      <Button variant="default" onClick={onBackClick} disabled={isFirstStep}>
        {t(translations.buttons.previous)}
      </Button>

      {!onForwardClick && (
        <Button loading={loading} type="submit" disabled={disabled}>
          {t(translations.buttons.saveAndContinue)}
        </Button>
      )}

      {onForwardClick && (
        <Button loading={loading} onClick={onForwardClick}>
          {isSecondStep ? t(translations.buttons.next) : t(translations.buttons.saveAndContinue)}
        </Button>
      )}
    </Group>
  );
};
