import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { updateGroupRequest } from 'common/services';
import { invalidateQueries } from 'common/helpers';
import { API_TAGS } from 'common/constants';

const title = 'general.notifications.modules.user';

export const useUpdateGroup = ({ groupId, t }) => {
  const noticeID = 'user';

  return useMutation({
    mutationFn: requestData => {
      return updateGroupRequest({ groupId, requestData });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updating')}`,
      });
    },
    onSuccess: () => {
      invalidateQueries({ queryKey: [API_TAGS.group] });
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updated')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
