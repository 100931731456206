import ua from './ua.json';
import en from './en.json';

const resources = {
  ua: {
    translation: ua,
  },
  en: {
    translation: en,
  },
};

export default resources;
