import { TextInput } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { IconUserSearch } from '@tabler/icons';
import { useEffect } from 'react';

export const SearchInput = ({
  value = '',
  placeholder = '',
  label = '',
  onChange,
  onClick = () => {},
}) => {
  const [values, setValue] = useDebouncedState(value, 400);

  useEffect(() => {
    if (value === values) return;

    onChange({ search: values });
  }, [values, onChange, value]);

  return (
    <TextInput
      defaultValue={values}
      icon={<IconUserSearch size={14} />}
      placeholder={placeholder}
      label={label}
      onChange={event => setValue(event.currentTarget.value)}
      onClick={onClick}
    />
  );
};
