import { Paper, Stack, Text } from '@mantine/core';
import { IconMoodSad } from '@tabler/icons';

export const NotFound = ({ message = 'Items not found' }) => (
  <Paper shadow="xs" p="md">
    <Stack align="center">
      <IconMoodSad size={56} />
      <Text fz="lg">{message}</Text>
    </Stack>
  </Paper>
);
