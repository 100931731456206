import { useMutation } from '@tanstack/react-query';

import { queryClient } from 'common/helpers';
import { API_TAGS } from 'common/constants';
import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { updateCourseModuleRequest } from 'common/services';

const title = 'general.notifications.modules.module';

export const useUpdateModule = ({ t }) => {
  const noticeID = 'updateModule';

  return useMutation({
    mutationFn: ({ requestData, courseId, moduleId }) => {
      return updateCourseModuleRequest({ requestData, courseId, moduleId });
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updating')}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_TAGS.courseModules] });

      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updated')}`,
      });
    },
    onError: error => handleQueryError(error, noticeID),
  });
};
