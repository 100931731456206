import { IconCloudDownload } from '@tabler/icons';

import { ReactComponent as HomeIcon } from 'common/assets/icons/sidebar/home.svg';
import { ReactComponent as UserIcon } from 'common/assets/icons/sidebar/user.svg';
import { ReactComponent as StreamIcon } from 'common/assets/icons/sidebar/stream.svg';
import { ReactComponent as CoursesIcon } from 'common/assets/icons/sidebar/courses.svg';
import { ReactComponent as HomeworksIcon } from 'common/assets/icons/sidebar/homeworks.svg';
import { ReactComponent as GroupsIcon } from 'common/assets/icons/sidebar/groups.svg';
import { ReactComponent as CommentsIcon } from 'common/assets/icons/sidebar/messages.svg';
import { ReactComponent as EventsIcon } from 'common/assets/icons/sidebar/events.svg';
import { ReactComponent as EarningsIcon } from 'common/assets/icons/sidebar/earnings.svg';

import { ReactComponent as UsersIcon } from 'common/assets/icons/sidebar/users.svg';
import { ReactComponent as InvitationIcon } from 'common/assets/icons/sidebar/invitation.svg';
import { ReactComponent as AccessIcon } from 'common/assets/icons/sidebar/access.svg';
import { ReactComponent as PricesIcon } from 'common/assets/icons/sidebar/prices.svg';
import { ReactComponent as CreateIcon } from 'common/assets/icons/sidebar/create.svg';
import { ReactComponent as EditIcon } from 'common/assets/icons/sidebar/edit.svg';
import { ReactComponent as BannerIcon } from 'common/assets/icons/sidebar/banner.svg';
import { ReactComponent as StreamsIcon } from 'common/assets/icons/sidebar/streams.svg';

import { ReactComponent as LogoutIcon } from 'common/assets/icons/sidebar/logout.svg';

import { ROUTER_BOOK } from 'app/router/routerBook';
import { PERMISSION_IDS } from '../../../constants';

export const MENU_LOGOUT_ITEM = {
  label: 'sidebar.logout.general',
  icon: LogoutIcon,
};

export const MENU_HOME_ITEM = {
  link: ROUTER_BOOK.private.OWNER_SETUP.GENERAL,
  label: 'sidebar.home',
  icon: HomeIcon,
};

export const MENU_BUSINESS_ACCOUNT_SUBLINKS = [
  {
    link: ROUTER_BOOK.private.BUSINESS_ACCOUNT.BRAND_INFO.GENERAL,
    label: 'sidebar.business-account.brand-info',
    icon: UserIcon,
  },
  {
    link: ROUTER_BOOK.private.BUSINESS_ACCOUNT.EMPLOYEES.GENERAL,
    label: 'sidebar.business-account.employees',
    icon: UsersIcon,
  },
  {
    link: ROUTER_BOOK.private.BUSINESS_ACCOUNT.INVITATION.GENERAL,
    label: 'sidebar.business-account.invitation',
    icon: InvitationIcon,
  },
  {
    link: ROUTER_BOOK.private.BUSINESS_ACCOUNT.ACCESS_RULES.GENERAL,
    label: 'sidebar.business-account.access-rules',
    icon: AccessIcon,
  },
  {
    link: ROUTER_BOOK.private.BUSINESS_ACCOUNT.PRICINGS.GENERAL,
    label: 'sidebar.business-account.pricings',
    icon: PricesIcon,
  },
];

export const MENU_ITEMS = [
  {
    permissionId: PERMISSION_IDS.stream,
    link: ROUTER_BOOK.private.STREAMS.GENERAL,
    label: 'sidebar.youtube-stream.general',
    icon: StreamIcon,
    links: [
      {
        link: ROUTER_BOOK.private.STREAMS.GENERAL,
        label: 'sidebar.youtube-stream.streams',
        icon: StreamsIcon,
      },
      {
        link: ROUTER_BOOK.private.BANNERS.GENERAL,
        label: 'sidebar.youtube-stream.banners',
        icon: BannerIcon,
      },
    ],
  },
  {
    permissionId: PERMISSION_IDS.course,
    link: ROUTER_BOOK.private.COURSES.GENERAL,
    label: 'sidebar.courses.general',
    icon: CoursesIcon,
    links: [
      {
        link: ROUTER_BOOK.private.COURSES.MANAGEMENT,
        label: 'sidebar.courses.course-management',
        icon: CoursesIcon,
      },
      {
        link: ROUTER_BOOK.private.COURSES.S3_VIDEOS,
        label: 'sidebar.courses.lesson-uploads',
        icon: () => <IconCloudDownload color="#B17CE1" />,
      },
      {
        permissionId: PERMISSION_IDS.group,
        link: ROUTER_BOOK.private.COURSES.GROUPS.GENERAL,
        label: 'sidebar.users-and-groups.groups',
        icon: GroupsIcon,
      },
    ],
  },
  {
    permissionId: PERMISSION_IDS.homework,
    link: ROUTER_BOOK.private.HOME_WORKS.GENERAL,
    label: 'sidebar.homeworks.general',
    icon: HomeworksIcon,
    links: [
      {
        link: ROUTER_BOOK.private.HOME_WORKS.CREATE.GENERAL,
        label: 'sidebar.homeworks.create',
        icon: CreateIcon,
      },
      {
        link: ROUTER_BOOK.private.HOME_WORKS.CHECK.GENERAL,
        label: 'sidebar.homeworks.check',
        icon: EditIcon,
      },
    ],
  },
  {
    permissionId: PERMISSION_IDS.user,
    link: ROUTER_BOOK.private.USERS.GENERAL,
    label: 'sidebar.users-and-groups.users',
    icon: UserIcon,
  },

  // {
  //   link: ROUTER_BOOK.private.LOCALES.GENERAL,
  //   label: 'Locales',
  //   icon: IconLanguage,
  // },
  // {
  //   permissionId: PERMISSION_IDS.price,
  //   link: ROUTER_BOOK.private.PRICES.GENERAL,
  //   label: 'sidebar.community.general',
  //   icon: IconCash,
  // },
  // {
  //   link: ROUTER_BOOK.private.PAYMENTS.GENERAL,
  //   label: 'Payments',
  //   icon: IconWallet,
  // },
  // {
  //   label: 'Notifications',
  //   icon: IconBell,
  //   links: [
  //     {
  //       link: ROUTER_BOOK.private.NOTIFICATIONS.SEND_NOTIFICATION,
  //       label: 'Send notification',
  //       icon: IconMailFast,
  //     },
  //     {
  //       link: ROUTER_BOOK.private.NOTIFICATIONS.NOTIFICATIONS,
  //       label: 'Notifications',
  //       icon: IconBellRinging2,
  //     },
  //     {
  //       link: ROUTER_BOOK.private.NOTIFICATIONS.TEMPLATES,
  //       label: 'Templates',
  //       icon: IconTemplate,
  //     },
  //   ],
  // },
  {
    permissionId: PERMISSION_IDS.course,
    link: ROUTER_BOOK.private.COMMENTS.GENERAL,
    label: 'sidebar.comments.general',
    icon: CommentsIcon,
  },
  {
    permissionId: PERMISSION_IDS.calendar,
    label: 'sidebar.events.general',
    link: ROUTER_BOOK.private.EVENTS.GENERAL,
    icon: EventsIcon,
  },
  {
    permissionId: PERMISSION_IDS.businessAccount,
    label: 'sidebar.earnings.general',
    link: ROUTER_BOOK.private.EARNINGS.GENERAL,
    icon: EarningsIcon,
  },
];
