import * as yup from 'yup';

export const notificationSchema = yup.object({
  groupMetas: yup
    .array()
    .min(1)
    .of(
      yup.object({
        lang: yup.string().label('Language'),
        title: yup.string().label('Title').required(),
      }),
    )
    .label('Localizations')
    .required(),
  // startDate: yup.string().required(),
  // endDate: yup.string().required(),
});
