import { API_LIST } from 'common/constants';
import { get } from 'common/helpers';

const path = API_LIST.USERS.GENERAL;

export const getUsersRequest = async (params = { page: 1, limit: 10 }) => {
  const { data } = await get(path, { params });

  return data;
};

export const getUserByIdRequest = async ({ userID }) => {
  const { data } = await get(`${path}/${userID}`);

  return data;
};

export const getUsersMe = async () => {
  const { data } = await get(API_LIST.USERS.ME);

  return data;
};
