import { useState, useRef, useEffect } from 'react';
import { Stack, Pagination } from '@mantine/core';

import { SearchInput } from 'common/components/base/Inputs/SearchInput';

import { Loader } from '../Loader/Loader';

export const SearchableDropdown = ({
  data,
  renderElement: Element = 'div',
  setCurrentPage,
  totalPagesCount,
  currentPage,
  queryParams,
  setQueryParams,
  placeholder = '',
  label = '',
  fetching = false,
}) => {
  const [open, setOpen] = useState(false);
  const parentRef = useRef(null);
  const paginationRef = useRef(null);

  useEffect(() => {
    const handleClick = e => {
      if (
        !parentRef.current ||
        parentRef.current.contains(e.target) ||
        paginationRef.current.contains(e.target)
      )
        return;

      setOpen(false);
    };

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <Stack ref={parentRef} sx={{ position: 'relative' }}>
      <SearchInput
        onClick={() => setOpen(true)}
        onChange={setQueryParams}
        value={queryParams.search}
        placeholder={placeholder}
        label={label}
      />

      <Stack
        sx={theme => ({
          display: open ? 'block' : 'none',
          width: '100%',
          minHeight: '300px',
          paddingBottom: '20px',
          overflowY: 'scroll',
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
          border: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]
          }`,
          borderRadius: '5px',
          position: 'absolute',
          top: '65px',
          zIndex: 10,
        })}
      >
        {fetching ? (
          <Loader />
        ) : (
          <>
            {data?.map(item => (
              <Element key={item.id} item={item} />
            ))}

            <Pagination
              position="center"
              page={currentPage}
              onChange={setCurrentPage}
              total={totalPagesCount}
              siblings={1}
              ref={paginationRef}
              sx={{ marginTop: '20px' }}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
