import { Outlet } from 'react-router-dom';

// UIKit
import { AppShell } from '@mantine/core';

import { appShellConfig } from 'app/configs/theme';

export const AuthLayout = () => (
  <AppShell padding={0} style={{ overflowX: 'hidden' }} styles={appShellConfig}>
    <Outlet />
  </AppShell>
);
