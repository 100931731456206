import images from '../components/ui/LanguagePicker/images';

const languages = {
  en: { label: 'general.languages.en', key: 'en', image: images.english },
  ua: { label: 'general.languages.ua', key: 'ua', image: images.ukraine },
};

const checkLanguageForAvailability = (language = '') => {
  const isAvailable = Object.keys(languages).includes(language);
  return isAvailable ? languages[language] : languages.en;
};

export const getLanguage = () => {
  // const browserLang = window.navigator.language;
  const selectedLanguage = localStorage.getItem('language');
  const language = selectedLanguage || 'en';

  return checkLanguageForAvailability(language.substring(0, 2));
};
