import { adaptMetaFormDataForAPI, createFormData, isObject } from 'common/utils';

const adaptLessonVideoTimecodesForAPI = ({ videoTimecodeMetas, ...videoTimecode }) => ({
  ...videoTimecode,
  videoTimecodeMetas: adaptMetaFormDataForAPI(videoTimecodeMetas),
});

const adaptLessonContentsForAPI = ({ videoTimecodes, lessonContentMetas, ...lessonContent }) => {
  return {
    ...lessonContent,
    lessonContentMetas: adaptMetaFormDataForAPI(lessonContentMetas),
    videoTimecodes: videoTimecodes.map(adaptLessonVideoTimecodesForAPI),
  };
};

const adaptLessonForAPI = lesson => ({
  delay: lesson.delay,
  lessonMetas: adaptMetaFormDataForAPI(lesson.lessonMetas),
  lessonContents: lesson.lessonContents.map(adaptLessonContentsForAPI),
  position: lesson.position,
});

export const adaptCourseFormDataForAPI = ({ courseData, lessonsData }) => {
  const adaptedFormValues = {
    ...courseData,
    courseMetas: adaptMetaFormDataForAPI(courseData.courseMetas),
    lessons: lessonsData.lessons.map(adaptLessonForAPI),
  };

  if (isObject(courseData.generalImage)) {
    adaptedFormValues.generalImage = createFormData({ image: courseData.generalImage });
  }

  if (isObject(courseData.landingImage)) {
    adaptedFormValues.landingImage = createFormData({ image: courseData.landingImage });
  }

  if (isObject(courseData.landingBackgroundImage)) {
    adaptedFormValues.landingBackgroundImage = createFormData({
      image: courseData.landingBackgroundImage,
    });
  }

  return adaptedFormValues;
};
