import { useMemo } from 'react';

import { CourseEditor } from './components/CourseEditor/CourseEditor';
import { LessonsEditor } from './components/LessonsEditor/LessonsEditor';
import { ModulesEditor } from './components/ModulesEditor/ModulesEditor';
import { PricesEditor } from './components/PricesEditor/PricesEditor';

const steps = [CourseEditor, LessonsEditor, ModulesEditor, PricesEditor];

export const Edit = ({ courseID, activeStep, toPrevStep, toNextStep }) => {
  const CurrentStep = useMemo(() => steps[activeStep], [activeStep]);

  return (
    <CurrentStep
      courseID={courseID}
      activeStep={activeStep}
      toPrevStep={toPrevStep}
      toNextStep={toNextStep}
    />
  );
};
