import { Avatar, Flex, Text } from '@mantine/core';
import { useSelector } from 'react-redux';

import { getUserInfoSelector } from 'app/store/user/user.selectors';
import { generateImageUrl } from 'common/utils/images';

export const UserAvatar = () => {
  const userInfo = useSelector(getUserInfoSelector);

  return (
    <Flex align="center">
      <Text color="white" sx={{ marginRight: '10px' }}>
        {userInfo.name}
      </Text>
      <Avatar src={generateImageUrl(userInfo.image)} radius="xl" />
    </Flex>
  );
};
