import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// Constants
import { ROUTER_BOOK } from 'app/router/routerBook';
import { isUserHasAccessSelector } from 'features/auth/auth.selectors';

export const PrivateRoute = ({ children }) => {
  const isUserHasAccess = useSelector(isUserHasAccessSelector);

  if (isUserHasAccess) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <Navigate to={ROUTER_BOOK.public.AUTH.SIGN_IN} />;
};
