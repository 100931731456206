import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { addUserToGroupRequest } from 'common/services';
import { invalidateQueries } from 'common/helpers';
import { API_TAGS } from 'common/constants';

const title = 'User';

export const useAddUserToGroup = groupId => {
  const noticeID = 'add-user';

  return useMutation({
    mutationFn: userId => {
      return addUserToGroupRequest({ groupId, userId });
    },
    onMutate: () => {
      showLoadingNotification({ id: noticeID, message: `${title} is adding.` });
    },
    onSuccess: () => {
      invalidateQueries({ queryKey: [API_TAGS.group] });
      showUpdateNotification({
        id: noticeID,
        message: `${title} was added.`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
