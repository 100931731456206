import { LANGUAGES_LIST } from 'common/constants/languages/languages.list';

export const extractValueFromMetaArray = (meta, property) => {
  if (!Array.isArray(meta)) {
    throw new Error(`Meta isn't array`);
  }

  const currentMetaItem = meta.find(item => item.property === property);

  if (currentMetaItem?.value) {
    return currentMetaItem.value;
  }

  return '';
};

export const getValueFromExtractedMetaArray = (meta, property, lang = LANGUAGES_LIST.EN) => {
  if (!Array.isArray(meta)) {
    throw new Error(`Meta isn't array`);
  }

  const currentMetaItem = meta.find(item => item.lang.toUpperCase() === lang);

  if (currentMetaItem && currentMetaItem[property]) {
    return currentMetaItem[property];
  }

  return '';
};

/**
 *
 * @param {object} findObject - Object for search with defined structure.
 * @param {string} findObject.property - The name of the object property to find.
 * @param {string} findObject.value - The name of the object property value to find.
 * @param {object} arrayOfObjects - The array of objects to search for.
 *
 * @returns {boolean} Is has prop in array.
 */
export const hasObjectInArrayOfObjects = (findObject, arrayOfObjects) => {
  const result = arrayOfObjects.find(object => object[findObject.property] === findObject.value);

  return Boolean(result);
};

export const localizationHasEnLang = array => {
  const lang = { property: 'lang', value: LANGUAGES_LIST.EN.toLowerCase() };
  return hasObjectInArrayOfObjects(lang, array);
};
