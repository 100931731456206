import { Accordion, Flex, TextInput, Button, ActionIcon, MultiSelect } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconTrash } from '@tabler/icons';

import { showConfirmationModal } from 'common/utils';

import { useCreateModule, useUpdateModule, useDeleteModule } from '../hooks';
import translations from '../translations';

export const ModulesItem = ({
  field,
  index,
  control,
  watch,
  getFieldState,
  handleSubmit,
  fieldName,
  lessons,
  courseId,
  removeField,
}) => {
  const { t } = useTranslation();

  const { mutate: createModule } = useCreateModule({ t });
  const { mutate: updateModule } = useUpdateModule({ t });
  const { mutate: removeModule } = useDeleteModule({ t });

  const { isDirty, error } = getFieldState(`${fieldName}.${index}`);

  const watchedField = watch(`${fieldName}.${index}`);

  const fieldHasId = 'id' in field;

  const onSubmit = () => {
    const dataToSend = {
      courseId,
      lessonIds: watchedField.lessonIds,
      position: watchedField.position,
      meta: [
        { value: watchedField.title, property: 'title' },
        { value: watchedField.description, property: 'description' },
      ],
    };

    if (watchedField.id) {
      updateModule({
        requestData: dataToSend,
        courseId,
        moduleId: watchedField.id,
      });
    } else
      createModule({
        requestData: dataToSend,
      });
  };

  const openModal = ({ courseId: courseID, moduleId }) => {
    return showConfirmationModal({
      title: t(translations.modals.delete.title),
      caption: t(translations.modals.delete.caption),
      labels: {
        confirm: t(translations.modals.delete.confirm),
        cancel: t(translations.modals.delete.cancel),
      },
      onConfirm: () => removeModule({ courseId: courseID, moduleId }),
    });
  };

  const removeHandler = e => {
    e.stopPropagation();

    if (fieldHasId) {
      openModal({ courseId, moduleId: field.id });
    } else {
      removeField(index);
    }
  };

  return (
    <Accordion.Item value={field.fieldId}>
      <Accordion.Control>
        <Flex justify="space-between">
          <Controller
            control={control}
            name={`${fieldName}.${index}.title`}
            rules={{ required: t('general.errors.required') }}
            render={({ field: inputField }) => (
              <TextInput
                placeholder={`${t(translations.fields.title)}*`}
                onClick={e => e.stopPropagation()}
                error={error?.title?.message}
                sx={{ width: '30%' }}
                {...inputField}
              />
            )}
          />
          <Controller
            control={control}
            name={`${fieldName}.${index}.description`}
            rules={{ required: t('general.errors.required') }}
            render={({ field: inputField }) => (
              <TextInput
                placeholder={`${t(translations.fields.description)}*`}
                onClick={e => e.stopPropagation()}
                error={error?.description?.message}
                sx={{ width: '60%' }}
                {...inputField}
              />
            )}
          />
          <ActionIcon variant="outline" color="red" onClick={removeHandler}>
            <IconTrash size={16} />
          </ActionIcon>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel>
        <Controller
          control={control}
          name={`${fieldName}.${index}.lessonIds`}
          rules={{ required: t('general.errors.required') }}
          render={({ field: lessonField }) => (
            <MultiSelect
              placeholder={`${t(translations.fields.lessons)}*`}
              data={lessons}
              value={lessonField.value}
              onChange={lessonField.onChange}
              error={error?.lessonIds?.message}
              filter={(value, selected, item) => !selected && !item.moduleId}
              searchable
              {...lessonField}
            />
          )}
        />
        <Flex justify="flex-end" sx={{ marginTop: '16px' }}>
          <Button disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
            {t(translations.buttons.save)}
          </Button>
        </Flex>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
