import { Flex, MediaQuery, Navbar, ScrollArea } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  getReadAccessKeysSelector,
  getUserBusinessAccountSelector,
} from 'app/store/user/user.selectors';
import { sendLogoutOperation } from 'features/auth/auth.thunks';
import { UserAvatar } from 'common/components/ui';
import { PERMISSION_IDS } from 'common/constants';
import { generateImageUrl } from 'common/utils';

import {
  MENU_ITEMS,
  MENU_HOME_ITEM,
  MENU_LOGOUT_ITEM,
  MENU_BUSINESS_ACCOUNT_SUBLINKS,
} from './constants';
import { useSidebarStyles } from './hooks';
import { LinksGroup } from './NavbarLinksGroup';

import LanguagePicker from '../../ui/LanguagePicker/LanguagePicker';

export const Sidebar = ({ opened = true }) => {
  const { classes } = useSidebarStyles();
  const dispatch = useDispatch();

  const permissions = useSelector(getReadAccessKeysSelector);
  const myBusinessAccount = useSelector(getUserBusinessAccountSelector);

  const onLogout = () => dispatch(sendLogoutOperation());

  const MENU_BUSINESS_ACCOUNT_ITEM = {
    permissionId: PERMISSION_IDS.businessAccount,
    label: myBusinessAccount?.name,
    logo: generateImageUrl(myBusinessAccount?.logo),
    links: MENU_BUSINESS_ACCOUNT_SUBLINKS,
  };

  return (
    <Navbar
      py="lg"
      px="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 300 }}
      sx={{ backgroundColor: '#000' }}
    >
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <>
          <LinksGroup
            icon={MENU_HOME_ITEM.icon}
            label={MENU_HOME_ITEM.label}
            link={MENU_HOME_ITEM.link}
            classes={classes}
          />

          {permissions.includes(MENU_BUSINESS_ACCOUNT_ITEM.permissionId) && (
            <LinksGroup {...MENU_BUSINESS_ACCOUNT_ITEM} classes={classes} businessAccountLink />
          )}

          {MENU_ITEMS.map(item => {
            if (item.permissionId && !permissions.includes(item.permissionId)) return null;
            return <LinksGroup {...item} key={item.label} classes={classes} />;
          })}
        </>
      </Navbar.Section>

      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <Flex justify="space-between" mb={10}>
          <UserAvatar />
          <LanguagePicker />
        </Flex>
      </MediaQuery>
      <Navbar.Section className={classes.footer}>
        <LinksGroup
          icon={MENU_LOGOUT_ITEM.icon}
          label={MENU_LOGOUT_ITEM.label}
          onClick={onLogout}
          classes={classes}
        />
      </Navbar.Section>
    </Navbar>
  );
};
