import { queryClient } from 'common/helpers';

// Components
import { PageDetails } from 'common/components/layouts';

// Hooks
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getUserByIDQuery } from './hooks/queries';
import { Details } from './components/Details/Details';

export const userLoader = async ({ params }) => {
  const query = getUserByIDQuery(params.userID);
  const data = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));

  return data;
};

const User = () => {
  const { userID } = useParams();

  const { isInitialLoading, data } = useQuery(getUserByIDQuery(userID));

  return (
    <PageDetails loading={isInitialLoading}>
      <Details formValues={data?.user} />
    </PageDetails>
  );
};

export default User;
