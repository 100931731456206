import { useQuery } from '@tanstack/react-query';
import { FormSelect } from 'common/components/ui/FormSelect/FormSelect';
import { API_TAGS } from 'common/constants';
import { getNotificationsRequest } from 'common/services';
import { extractValueFromMetaArray } from 'common/utils';

const config = {
  queryKey: [API_TAGS.notifications, 'select'],
  queryFn: () => getNotificationsRequest({ limit: 100 }),
  initialData: () => ({ notifications: [] }),
  select: ({ notifications }) => {
    return notifications.map(({ id, meta }) => ({
      value: id,
      label: extractValueFromMetaArray(meta, 'title') ?? `${id}`,
    }));
  },
};

export const NotificationSelect = props => {
  const { isLoading, data } = useQuery(config);

  return (
    <FormSelect placeholder="Choose notification ID" loading={isLoading} data={data} {...props} />
  );
};
