export const comparePermissionObjects = (defaultObj, obj) => {
  const defaultKeys = Object.keys(defaultObj);

  // Check if all keys in obj exist in defaultKeys and have boolean values
  const objKeys = Object.keys(obj);
  if (!objKeys.every(key => defaultKeys.includes(key) && typeof obj[key] === 'boolean')) {
    return false;
  }

  return true; // All keys with boolean values exist
};
