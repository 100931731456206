import { useCallback } from 'react';

import { Accordion } from '@mantine/core';

import { DragAndDrop } from 'common/components/ui';
import { LessonItem } from '../../../LessonItem/LessonItem';

export const LessonsFields = ({ onReplace, fields, onUpdatePosition, listItemProps }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onChange = useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }

      const indexFrom = source.index;
      const indexTo = destination.index;

      const sourcePosition = fields[indexFrom].position;
      const destinationPosition = fields[indexTo].position;

      onUpdatePosition(indexFrom, destinationPosition);
      onUpdatePosition(indexTo, sourcePosition);

      const reorderedLessons = reorder(fields, indexFrom, indexTo);
      onReplace(reorderedLessons);
    },
    [onReplace, fields, onUpdatePosition],
  );

  return (
    <Accordion variant="contained" defaultValue="customization">
      <DragAndDrop
        list={fields}
        listItem={LessonItem}
        listItemProps={listItemProps}
        onChange={onChange}
        droppableId="lessonsList"
      />
    </Accordion>
  );
};
