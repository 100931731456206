import { NumberInput, Grid } from '@mantine/core';
import { FormControl } from 'common/components/ui';
import { useTranslation } from 'react-i18next';
import translations from '../../translations';

export const VideoTimeCodes = ({ fieldName }) => {
  const { t } = useTranslation();
  return (
    <Grid cols={1} spacing="md" color="red">
      <Grid.Col xs={6}>
        <FormControl
          label={t(translations.fields.timeCode.label)}
          placeholder={t(translations.fields.timeCode.placeholder)}
          name={`${fieldName}.time`}
          inputElement={NumberInput}
          withAsterisk
        />
      </Grid.Col>
      <Grid.Col xs={6}>
        <FormControl
          name={`${fieldName}.videoTimecodeMetas[0].title`}
          label={t(translations.fields.timeCodeName.label)}
          placeholder={t(translations.fields.timeCodeName.placeholder)}
          withAsterisk
        />
      </Grid.Col>
    </Grid>
  );
};
