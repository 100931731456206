export const API_TAGS = Object.freeze({
  courses: 'COURSES',
  course: 'COURSE',

  courseModules: 'COURSE_MODULES',
  courseModule: 'COURSE_MODULE',

  lessons: 'LESSONS',

  notification: 'NOTIFICATION',
  notifications: 'NOTIFICATIONS',

  templates: 'TEMPLATES',
  template: 'TEMPLATE',

  s3VideosList: 'S3_VIDEOS_LIST',

  users: 'USERS',
  user: 'USER',
  userMy: 'USER_MY',

  groups: 'GROUPS',
  group: 'GROUP',

  locales: 'LOCALES',
  locale: 'LOCALE',

  prices: 'PRICES',
  price: 'PRICE',
  pricesCourse: 'PRICES_COURSE',

  communities: 'COMMUNITIES',
  community: 'COMMUNITY',

  homeworks: 'HOMEWORKS',
  homework: 'HOMEWORK',
  polls: 'POLLS',
  questions: 'QUESTIONS',
  answers: 'ANSWERS',
  answer: 'ANSWER',

  dashboards: 'DASHBOARDS',
  blocks: 'BLOCKS',

  comments: 'COMMENTS',

  streams: 'STREAMS',
  stream: 'STREAM',
  banners: 'BANNERS',

  payments: 'PAYMENTS',

  events: 'EVENTS',
  event: 'EVENT',

  businessAccount: 'BUSINESS_ACCOUNT',
  accessRules: 'ACCESS_RULES',
  employee: 'EMPLOYEES',
  invited_users: 'INVITED_USERS',
  permissions: 'PERMISSIONS',
  roles: 'ROLES',

  categories: 'CATEGORIES',

  benefits: 'BENEFITS',

  invitation: 'INVITATION',

  earnings: 'EARNINGS',
  transactions: 'TRANSACTIONS',
  stripe: 'STRIPE',

  countries: 'COUNTRIES',
});
