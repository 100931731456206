import { useQuery } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { getPricesCommunityRequest } from 'common/services';

import { adaptPriceAPIDataForForm } from '../../../utils/prices';

export const useGetPriceCommunity = () => {
  return useQuery({
    queryKey: [API_TAGS.prices, API_TAGS.communities, API_TAGS.user],
    queryFn: () => getPricesCommunityRequest(),
    select: data => data.prices.map(adaptPriceAPIDataForForm),
  });
};
