import { useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useFieldArray } from 'react-hook-form';

import { Item } from './Item';

export const DragDrop = ({
  control,
  value,
  updateLesson,
  removeLesson,
  removeTimecode,
  removeContentMeta,
  removeLessonContent,
}) => {
  const { fields: lessons, replace } = useFieldArray({
    control,
    name: 'lessons',
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onChange = useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }

      if (destination.index === source.index) {
        return;
      }

      const indexFrom = source.index;
      const indexTo = destination.index;

      const reorderedLessons = reorder(lessons, indexFrom, indexTo);
      replace(reorderedLessons);
    },
    [lessons, replace],
  );

  return (
    <DragDropContext onDragEnd={onChange}>
      <Droppable direction="vertical" droppableId="lessonsList">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {lessons.map((lesson, index) => (
              <Item
                key={lesson.lessonID ?? index}
                index={index}
                lesson={lesson}
                isOpen={value === `${lesson.lessonID}`}
                updateLesson={updateLesson}
                removeLesson={removeLesson}
                removeLessonContent={removeLessonContent}
                removeContentMeta={removeContentMeta}
                removeTimecode={removeTimecode}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
