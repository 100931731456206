import * as yup from 'yup';

import { checkFieldForHtmlElements } from 'common/utils';

export const lessonSchema = t =>
  yup.object({
    lessonMetas: yup
      .array()
      .min(1, t('general.errors.min', { count: 1 }))
      .of(
        yup.object({
          lang: yup.string(t('general.errors.string')),
          title: yup
            .string(t('general.errors.string'))
            .test('check for html tags', t('general.errors.required'), checkFieldForHtmlElements),
          description: yup
            .string()
            .test('check for html tags', t('general.errors.required'), checkFieldForHtmlElements),
        }),
      )
      .required(t('general.errors.required')),

    lessonContents: yup
      .array()
      .min(1, t('general.errors.min', { count: 1 }))
      .of(
        yup.object({
          // type: yup.string().required(t('general.errors.required')),
          duration: yup
            .number()
            .typeError(t('general.errors.required'))
            .required(t('general.errors.required')),

          lessonContentMetas: yup
            .array()
            // .min(1)
            .of(
              yup.object({
                lang: yup.string(t('general.errors.string')),
                video: yup
                  .string(t('general.errors.string'))
                  .required(t('general.errors.required')),
              }),
            )
            .required(t('general.errors.required')),

          videoTimecodes: yup
            .array()
            .min(1, t('general.errors.min', { count: 1 }))
            .of(
              yup.object({
                time: yup
                  .number()
                  .typeError(t('general.errors.required'))
                  .required(t('general.errors.required')),
                videoTimecodeMetas: yup
                  .array()
                  .min(1, t('general.errors.min', { count: 1 }))
                  .of(
                    yup.object({
                      lang: yup.string(t('general.errors.string')),
                      title: yup
                        .string(t('general.errors.string'))
                        .required(t('general.errors.required')),
                    }),
                  ),
              }),
            )
            .required(t('general.errors.required')),
        }),
      )
      .required(t('general.errors.required')),

    delay: yup.number().required(t('general.errors.required')),
  });

export const lessonsSchema = t =>
  yup.object({
    lessons: yup.array().min(1).of(lessonSchema(t)).required(),
  });
