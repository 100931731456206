import { useController, useFormContext } from 'react-hook-form';
import { Select } from '../Select/Select';

export const FormSelect = ({ name, ...otherProps }) => {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return <Select error={error?.message} {...field} {...otherProps} />;
};
