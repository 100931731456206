export const createFormData = data => {
  const formData = new FormData();
  const entries = Object.entries(data);

  entries.forEach(([key, value]) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (value instanceof Object || value instanceof Array) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};
