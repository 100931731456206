import { Box, Flex, Button, ActionIcon, TextInput } from '@mantine/core';
import { Controller, useFieldArray } from 'react-hook-form';
import { IconPlus, IconTrash } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'common/components/ui';

import { useCreateCoursePrice, useUpdateCoursePrice } from '../hooks';
import translations from '../translations';
import { adaptPriceFormDataForAPI } from '../../../containers/Edit/components/PricesEditor/PricesEditor.utils';

export const PricingOptionsList = ({
  control,
  pricingIndex,
  watchedField,
  fieldName,
  isDirty,
  handleSubmit,
  optionFieldsLength,
  courseId,
  myBusinessAccount,
  isSupportStripeConnect,
}) => {
  const { t } = useTranslation();

  const {
    fields: optionFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${fieldName}.${pricingIndex}.listMeta`,
    keyName: 'fieldId',
  });

  const { mutate: createPrice } = useCreateCoursePrice({ t });
  const { mutate: updatePrice } = useUpdateCoursePrice({ t });

  const addOptionField = () => {
    append({ list: '', lang: 'ua' });
  };

  const onSubmit = () => {
    const dataToSend = {
      ...adaptPriceFormDataForAPI(watchedField),
      courseId,
      trialDayDuration: watchedField.trialDayDuration ? watchedField.trialDayDuration : 0,
      isActive:
        !myBusinessAccount.isChargesEnabled &&
        !myBusinessAccount.isDetailsSubmitted &&
        !myBusinessAccount.isPayoutsEnabled &&
        isSupportStripeConnect
          ? false
          : watchedField.isActive,
    };

    if (watchedField.id) {
      updatePrice({
        reqData: dataToSend,
        id: watchedField.id,
        lessons: watchedField.priceCourseLessons,
      });
    } else
      createPrice({
        requestData: dataToSend,
        lessons: watchedField.priceCourseLessons,
      });
  };

  return (
    <Box sx={{ paddingLeft: '24px', marginTop: '16px' }}>
      {optionFields.map((optionField, optionIndex) => (
        <Flex
          key={optionField.fieldId}
          justify="space-between"
          align="center"
          sx={{ marginBottom: '16px' }}
        >
          <Controller
            control={control}
            name={`${fieldName}.${pricingIndex}.listMeta.${optionIndex}.list`}
            render={({ field: inputField }) => (
              <TextInput
                label={t(translations.fields.extraOptionTitle)}
                placeholder={t(translations.fields.extraOptionTitle)}
                sx={{ width: '100%', marginRight: '16px' }}
                {...inputField}
              />
            )}
          />
          <ActionIcon
            variant="outline"
            color="red"
            sx={{ marginTop: '24px' }}
            onClick={() => remove(optionIndex)}
          >
            <IconTrash size={16} />
          </ActionIcon>
        </Flex>
      ))}

      <Flex justify="space-between" sx={{ marginTop: '16px' }}>
        <Flex align="center">
          <Button
            variant="outline"
            leftIcon={<IconPlus size={14} />}
            sx={{ border: 'none', fontSize: 12 }}
            onClick={addOptionField}
          >
            {t(translations.buttons.addExtra)}
          </Button>
          <Tooltip label={t('tooltips.prices-extra-options')} />
        </Flex>
        <Flex align="center">
          <Button
            disabled={!isDirty && optionFieldsLength === watchedField?.listMeta?.length}
            onClick={handleSubmit(onSubmit)}
          >
            {t(translations.buttons.save)}
          </Button>
          <Tooltip label={t('tooltips.prices-save-button')} />
        </Flex>
      </Flex>
    </Box>
  );
};
