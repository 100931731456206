import { COURSE_STATUS_ENUM, COURSE_TYPE_ENUM } from 'common/enums';
import { capitalize } from 'common/utils';

export const COURSE_PAYMENT_TYPE_OPTIONS = Object.values(COURSE_TYPE_ENUM).map(status => ({
  label: capitalize(status),
  value: status,
}));

export const COURSE_STATUS_OPTIONS = Object.values(COURSE_STATUS_ENUM).map(status => ({
  label: capitalize(status),
  value: status,
}));
