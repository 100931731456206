import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormCourse } from '../../ui/FormCourse/FormCourse';
import { FormLessons } from '../../ui/FormLessons/FormLessons';
import { ModulesEditor } from '../Edit/components/ModulesEditor/ModulesEditor';
import { PricesEditor } from '../Edit/components/PricesEditor/PricesEditor';

import { useCreateCourse } from './hooks/useCreateCourse';

const steps = [FormCourse, FormLessons, ModulesEditor, PricesEditor];
const valuesNames = ['courseData', 'lessonsData', 'courseModules', 'pricesData'];

export const Create = ({ activeStep, toPrevStep, toNextStep, courseID }) => {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({});

  const { mutate: createCourse } = useCreateCourse({ t });

  const CurrentStep = useMemo(() => steps[activeStep], [activeStep]);

  const formValue = useMemo(() => {
    const key = valuesNames[activeStep];
    return formValues[key];
  }, [activeStep, formValues]);

  const onChange = useCallback(
    values => {
      const isLastStep = activeStep === 1;
      const currentValue = { [valuesNames[activeStep]]: values };
      setFormValues(prevState => ({ ...prevState, ...currentValue }));

      if (!isLastStep) {
        toNextStep();
      } else {
        createCourse({ ...formValues, ...currentValue });
        toNextStep();
      }
    },
    [activeStep, toNextStep, formValues, createCourse],
  );

  return (
    <CurrentStep
      courseID={courseID}
      onSubmit={onChange}
      activeStep={activeStep}
      toPrevStep={toPrevStep}
      formValues={formValue}
    />
  );
};
