import { Box } from '@mantine/core';
import { Draggable } from 'react-beautiful-dnd';

export const DragAndDropItem = ({ item, index, draggableId, listItem: Element, listItemProps }) => (
  <Draggable index={index} draggableId={draggableId}>
    {provided => (
      <Box
        {...provided.draggableProps}
        ref={provided.innerRef}
        sx={theme => ({
          paddingTop: theme.spacing.xs,
        })}
      >
        <Element
          item={item}
          index={index}
          dragHandleProps={provided.dragHandleProps}
          {...listItemProps}
        />
      </Box>
    )}
  </Draggable>
);
