import { useMutation } from '@tanstack/react-query';

import { handleQueryError, showLoadingNotification, showUpdateNotification } from 'common/utils';
import { invalidateQueries } from 'common/helpers';
import { API_TAGS } from 'common/constants';

import { getLessonRequests } from '../LessonsEditor.utils';

const title = 'general.notifications.modules.lesson';

export const useUpdateLesson = ({ courseID, t }) => {
  const noticeID = 'lesson';

  return useMutation({
    mutationFn: lesson => {
      // eslint-disable-next-line
      const toCreate = lesson.lessonContents[0].videoTimecodes[0]._meta.toCreate;

      const lessonToCreate = {
        ...lesson,
        lessonMetas: lesson.lessonMetas.map(item => ({ ...item, lang: 'ua' })),
        lessonContents: lesson.lessonContents.map(contentItem => {
          return {
            duration: contentItem.duration,
            videoTimecodes: contentItem.videoTimecodes,
            lessonContentMetas: contentItem.lessonContentMetas.map(contentMetaItem => ({
              ...contentMetaItem,
              lang: 'ua',
            })),
            _meta: { toCreate: true },
          };
        }),
      };

      const lessonToUpdate = {
        ...lesson,
        lessonMetas: lesson.lessonMetas.map(item => ({ ...item, lang: 'ua' })),
        lessonContents: lesson.lessonContents.map(contentItem => {
          return {
            duration: contentItem.duration,
            videoTimecodes: contentItem.videoTimecodes,
            type: contentItem.type,
            // eslint-disable-next-line no-underscore-dangle
            _meta: contentItem._meta,
            lessonContentMetas: contentItem.lessonContentMetas.map(contentMetaItem => ({
              ...contentMetaItem,
              lang: 'ua',
            })),
          };
        }),
      };

      const requests = getLessonRequests({
        lesson: toCreate ? lessonToCreate : lessonToUpdate,
        courseID,
      });

      return Promise.all(requests);
    },
    onMutate: () => {
      showLoadingNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updating')}`,
      });
    },
    onSuccess: () => {
      invalidateQueries({ queryKey: [API_TAGS.course, courseID] });
      showUpdateNotification({
        id: noticeID,
        message: `${t(title)} ${t('general.notifications.actions.updated')}`,
      });
    },
    onError: error => {
      handleQueryError(error, noticeID);
    },
  });
};
