import { FormSelect } from 'common/components/ui/FormSelect/FormSelect';
import { LANGUAGES_LIST } from 'common/constants/languages/languages.list';

const options = Object.keys(LANGUAGES_LIST).map(lang => ({
  label: lang,
  value: lang.toLocaleLowerCase(),
}));

export const LanguagesSelect = ({ renderElement: Element = FormSelect, ...props }) => (
  <Element data={options} {...props} />
);
