import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { queryClient } from 'common/helpers';
import { adaptMetaAPIDataForForm } from 'common/utils';

// Components
import { PageDetails } from 'common/components/layouts';
import { GroupForm } from 'features/groups/components/GroupForm/GroupForm';

import { getGroupByIdQuery } from 'features/groups/hooks/queries';

export const groupLoader = async ({ params }) => {
  const query = getGroupByIdQuery(params.id);
  const data = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));

  return data;
};

const Group = () => {
  const { id } = useParams();

  const { isInitialLoading, data } = useQuery({
    ...getGroupByIdQuery(id),
    select: ({ group }) => ({
      group: {
        ...group,
        groupMetas: adaptMetaAPIDataForForm(group?.groupMetas),
        startDate: group?.startDate ? new Date(dayjs(group?.startDate).format('YYYY, MM, DD')) : '',
        endDate: group?.endDate ? new Date(dayjs(group?.endDate).format('YYYY, MM, DD')) : '',
      },
    }),
  });

  return (
    <PageDetails loading={isInitialLoading}>
      <GroupForm formValues={data?.group} />
    </PageDetails>
  );
};

export default Group;
