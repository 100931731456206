const translations = {
  title: 'features.pricing.title',
  warningMessage: 'features.pricing.warning-message',
  fields: {
    price: 'features.pricing.fields.price',
    title: 'features.pricing.fields.title',
    extraOptionTitle: 'features.pricing.fields.extra-option-title',
    active: 'features.pricing.fields.active',
    trial: 'features.pricing.fields.trial',
    trialDuration: 'features.pricing.fields.trial-duration',
    lessons: 'features.pricing.fields.lessons',
  },
  buttons: {
    addPrice: 'features.pricing.buttons.add-price',
    addExtra: 'features.pricing.buttons.add-extra',
    save: 'features.pricing.buttons.save',
  },
};

export default translations;
