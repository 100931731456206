import { MultiSelect } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { FormSelect } from 'common/components/ui';
import { API_TAGS } from 'common/constants';
import { getPricesCommunityRequest } from 'common/services';
import { getTitleFromMeta } from '../../../utils';

const config = {
  queryKey: [API_TAGS.prices, 'select'],
  initialData: () => ({ prices: [] }),
  queryFn: () => getPricesCommunityRequest(),
  select: ({ prices }) =>
    prices.map(({ id, meta }) => ({ value: id, label: `${getTitleFromMeta(meta)}` })),
};

export const PricesSelect = props => {
  const { isLoading, isFetching, data } = useQuery(config);

  return (
    <FormSelect
      searchable
      renderElement={MultiSelect}
      loading={isLoading || isFetching}
      data={data}
      {...props}
    />
  );
};
