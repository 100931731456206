import { showNotification } from '@mantine/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { logoutRequest, otpRequest } from 'common/services';
import { getAPIErrorMessage } from 'common/utils';
import { ReactComponent as AlertIcon } from 'common/assets/icons/alert-icon.svg';

export const sendOTPOperation = createAsyncThunk(
  'auth/SEND_OTP_REQUEST',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await otpRequest(requestData);
      return response;
    } catch (error) {
      showNotification({
        message: getAPIErrorMessage(error),
        color: 'red',
        icon: <AlertIcon />,
        styles: { icon: { background: 'none !important' } },
        closeButtonProps: { iconSize: 12 },
        sx: { backgroundColor: '#222', borderRadius: 8 },
      });

      return rejectWithValue(error);
    }
  },
);

export const sendLogoutOperation = createAsyncThunk(
  'auth/SEND_LOGOUT_REQUEST',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await logoutRequest(requestData);
      return response;
    } catch (error) {
      showNotification({
        message: getAPIErrorMessage(error),
        color: 'red',
        icon: <AlertIcon />,
        styles: { icon: { background: 'none !important' } },
        closeButtonProps: { iconSize: 12 },
        sx: { backgroundColor: '#222', borderRadius: 8 },
      });

      return rejectWithValue(error);
    }
  },
);
