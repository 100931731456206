import { useController, useFormContext } from 'react-hook-form';
import { DateTimePicker } from '../DateTimePicker/DateTimePicker';

export const FormDateTimePicker = ({ name, ...otherProps }) => {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = Array.isArray(error) ? error[0]?.message : error?.message;

  return <DateTimePicker error={errorMessage} field={field} {...otherProps} />;
};
