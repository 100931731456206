import { useMutation, useQuery } from '@tanstack/react-query';
import {
  handleQueryError,
  showLoadingNotification,
  showUpdateNotification,
  removeHtmlElementsFromString,
} from 'common/utils';
import { patchLessonScheduleRequest } from 'common/services';
import { getCourseQuery } from 'pages/course/components/containers/Edit/components/CourseEditor/hooks/useGetCourse';

export const useGetLessons = courseID => {
  return useQuery({
    ...getCourseQuery(courseID),
    select: ({ course }) => {
      return course.lessons.map(lesson => {
        const lessonTitle = lesson.lessonMetas.find(item => item.property === 'title')?.value;

        return {
          value: lesson.id,
          label: removeHtmlElementsFromString(lessonTitle),
        };
      });
    },
  });
};

const title = 'Schedule';

export const useUpdateLessonSchedule = () => {
  return useMutation({
    mutationFn: ({ courseID, lessonID, reqData }) => {
      return patchLessonScheduleRequest({ courseID, lessonID, reqData });
    },
    onMutate: () => {
      showLoadingNotification({ id: title, message: `${title} is updating.` });
    },
    onSuccess: () => {
      showUpdateNotification({
        id: title,
        message: `${title} was updated.`,
      });
    },
    onError: error => {
      handleQueryError(error, title);
    },
  });
};
