import { createSlice } from '@reduxjs/toolkit';

import { sendLogoutOperation, sendOTPOperation } from './auth.thunks';

const initialState = {
  isUserHasAccess: false,
  isReCaptchaLoading: true,
};

// TODO: Remove redux. Switch to Effector or context
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsUserHasAccess(state, { payload }) {
      state.isUserHasAccess = payload;
    },
    setIsReCaptchaLoading(state, { payload }) {
      state.isReCaptchaLoading = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(sendOTPOperation.fulfilled, state => {
      state.isUserHasAccess = true;
    });
    builder.addCase(sendLogoutOperation.fulfilled, state => {
      state.isUserHasAccess = false;
    });
  },
});

export const { setIsUserHasAccess, setIsReCaptchaLoading } = authSlice.actions;

export default authSlice.reducer;
