import { forwardRef } from 'react';
import { Accordion, ActionIcon, Grid, Group, SimpleGrid } from '@mantine/core';
import { IconGripVertical, IconSquareX } from '@tabler/icons';

import { TextEditor } from 'common/components/base';
import { FormControl } from 'common/components/ui';
import { getValueFromExtractedMetaArray } from 'common/utils';

import { useTranslation } from 'react-i18next';
import { LessonArea } from './components';
import { LessonContentItem } from './components/LessonContent/LessonContentItem';

import translations from './translations';

import 'react-quill/dist/quill.snow.css';

const LessonTextEditor = forwardRef((props, ref) => <TextEditor {...props} innerRef={ref} />);

export const LessonItem = ({ dragHandleProps, item, index, originalName, onRemove, errors }) => {
  const { t } = useTranslation();
  const title = getValueFromExtractedMetaArray(item.lessonMetas, 'title');
  const label = `${t(translations.itemLesson)}:  ${title || item.position}`;
  const fieldsName = `${originalName}.${index}`;

  const isError = !!errors?.lessons?.[index];

  const removeItem = () => onRemove(index);

  return (
    <Accordion.Item value={item.id} style={{ border: isError ? '1px solid red' : '' }}>
      <Accordion.Control
        icon={
          <Group>
            <ActionIcon
              component="div"
              variant="transparent"
              sx={{ color: '#595959' }}
              onClick={removeItem}
            >
              <IconSquareX />
            </ActionIcon>
            <ActionIcon
              component="div"
              variant="transparent"
              sx={{ color: '#595959' }}
              {...dragHandleProps}
            >
              <IconGripVertical />
            </ActionIcon>
          </Group>
        }
      >
        {label}
      </Accordion.Control>

      <Accordion.Panel>
        <SimpleGrid cols={1} spacing="md">
          <LessonArea title="Lesson meta information">
            <Grid>
              <Grid.Col xs={12}>
                <FormControl
                  label="Lesson title"
                  name={`${fieldsName}.lessonMetas[0].title`}
                  inputElement={LessonTextEditor}
                  autosize
                  minRows={4}
                  maxRows={8}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <FormControl
                  label="Lesson description"
                  name={`${fieldsName}.lessonMetas[0].description`}
                  inputElement={LessonTextEditor}
                  autosize
                  minRows={4}
                  maxRows={8}
                />
              </Grid.Col>
            </Grid>
          </LessonArea>
          <LessonArea title="Lesson content">
            <LessonContentItem fieldName={`${fieldsName}.lessonContents[0]`} />
          </LessonArea>
        </SimpleGrid>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
