import { Box } from '@mantine/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { BenefitsDragItem } from './BenefitsDragItem';

export const BenefitsDragAndDrop = ({ onChange, fields, control, removeField }) => {
  return (
    <DragDropContext onDragEnd={onChange}>
      <Droppable direction="vertical" droppableId="benefitsList">
        {provided => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map((field, index) => (
              <BenefitsDragItem
                key={field.fieldId}
                field={field}
                index={index}
                control={control}
                removeField={removeField}
              />
            ))}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
