import { forwardRef } from 'react';
import { Accordion, NumberInput, Grid } from '@mantine/core';
import { LessonArea } from 'pages/course/components/ui/LessonItem/components';
import { lessonSchema } from 'pages/course/components/ui/FormLessons/FormLessons.schemas';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormControl, LabelWithTooltip } from 'common/components/ui';
import { TextEditor } from 'common/components/base';
import { useParams } from 'react-router-dom';

import {
  createLessonItem,
  createLessonVideoItem,
} from 'pages/course/components/ui/FormLessons/FormLessons.constants';
import { LessonContentItem } from 'pages/course/components/ui/LessonItem/components/LessonContent/LessonContentItem';

import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import translations from './translations';

const LessonTextEditor = forwardRef((props, ref) => <TextEditor {...props} innerRef={ref} />);

const newVideoItemModel = {
  ...createLessonVideoItem('ua'),
  _meta: {
    toCreate: true,
  },
};

export const Panel = ({ lesson, isOpen, removeTimecode, onSubmit, removeContentMeta }) => {
  const { t } = useTranslation();
  const formMethods = useForm({
    mode: 'onChange',
    values: lesson,
    defaultValues: createLessonItem('ua'),
    resolver: yupResolver(lessonSchema(t)),
  });

  const { id } = useParams();

  const { handleSubmit } = formMethods;

  const lessonContentItem = lesson.lessonContents.find(item => item.type === 'video');

  if (!isOpen) return null;

  return (
    <Accordion.Panel>
      <Form formMethods={formMethods} onSubmit={handleSubmit(onSubmit)}>
        <LessonArea>
          <Grid>
            <Grid.Col xs={12}>
              <FormControl
                label={
                  <LabelWithTooltip
                    text={t(translations.fields.delay.label)}
                    tooltipText={t('tooltips.lesson-delay')}
                  />
                }
                placeholder={t(translations.fields.delay.placeholder)}
                name="delay"
                inputElement={NumberInput}
                sx={{ Label: { display: 'inline-flex' } }}
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <FormControl
                label={t(translations.fields.title.label)}
                placeholder={t(translations.fields.title.placeholder)}
                name="lessonMetas[0].title"
                inputElement={LessonTextEditor}
                autosize
                minRows={4}
                maxRows={8}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <FormControl
                label={t(translations.fields.description.label)}
                placeholder={t(translations.fields.description.placeholder)}
                name="lessonMetas[0].description"
                inputElement={LessonTextEditor}
                autosize
                minRows={4}
                maxRows={8}
              />
            </Grid.Col>
          </Grid>
        </LessonArea>
        <LessonArea title={t(translations.sections.content.title)}>
          <LessonContentItem
            fieldName="lessonContents[0]"
            newVideoItemModel={newVideoItemModel}
            removeTimecode={removeTimecode}
            removeContentMeta={removeContentMeta}
            lessonContentItem={lessonContentItem}
            courseId={id}
            lessonId={lesson.lessonID}
            // eslint-disable-next-line no-underscore-dangle
            lessonContentId={lessonContentItem?._meta?.contentID}
          />
        </LessonArea>
      </Form>
    </Accordion.Panel>
  );
};
