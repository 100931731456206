import * as yup from 'yup';

export const localeSchema = yup.object({
  meta: yup
    .array()
    .min(1)
    .of(
      yup.object({
        lang: yup.string().label('Language').required(),
        title: yup.string().label('Title').required(),
        description: yup.string().label('Description').required(),
      }),
    )
    .label('Localizations')
    .required(),

  parameterId: yup.number().typeError('Required field!').required('Required field!'),
});
