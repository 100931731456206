import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavLink, Box, Avatar } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getReadAccessKeysSelector } from '../../../../app/store/user/user.selectors';

export const LinksGroup = ({
  icon: Icon,
  label,
  link,
  links,
  onClick,
  classes,
  logo = '',
  businessAccountLink = false,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const permissions = useSelector(getReadAccessKeysSelector);

  const [isActiveSubLink, setIsActiveSubLink] = useState(false);

  const hasLinks = Array.isArray(links);

  const isActiveLink = location.pathname.includes(link);

  const items = useMemo(() => {
    const linksItems = hasLinks ? links : [];

    return (
      <Box sx={{ marginTop: '8px' }}>
        {linksItems.map(
          ({ label: linkItemLabel, link: linkItemHref, icon: LinkItemIcon, permissionId }) => {
            const isActive = location.pathname.includes(linkItemHref);

            if (isActive) setIsActiveSubLink(isActive);
            if (permissionId && !permissions.includes(permissionId)) return null;
            return (
              <NavLink
                key={t(linkItemLabel)}
                to={linkItemHref}
                component={Link}
                label={t(linkItemLabel)}
                active={isActive}
                icon={<LinkItemIcon />}
                className={classes.link}
              />
            );
          },
        )}
      </Box>
    );
  }, [hasLinks, links, location.pathname, permissions, t, classes.link]);

  const Component = link && !hasLinks ? Link : null;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <NavLink
      color="gray"
      to={link}
      component={Component}
      onClick={handleClick}
      label={t(label)}
      active={isActiveLink}
      defaultOpened={isActiveSubLink}
      icon={businessAccountLink ? <Avatar src={logo} size="sm" radius="xl" /> : <Icon />}
      className={classes.link}
    >
      {hasLinks && items}
    </NavLink>
  );
};
