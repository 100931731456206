import {
  Accordion,
  Flex,
  TextInput,
  NumberInput,
  Divider,
  Grid,
  Switch,
  MultiSelect,
} from '@mantine/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconCurrencyDollar, IconBrandDaysCounter } from '@tabler/icons';

import { Tooltip } from 'common/components/ui';

import { PricingOptionsList } from './PricingOptionsList';
import translations from '../translations';

export const PricingItem = ({
  field,
  index,
  fieldName,
  control,
  watch,
  getFieldState,
  handleSubmit,
  pricingList,
  courseId,
  lessons,
  myBusinessAccount,
  isSupportStripeConnect,
}) => {
  const { t } = useTranslation();

  const { isDirty, error } = getFieldState(`${fieldName}.${index}`);

  const watchedField = watch(`${fieldName}.${index}`);

  return (
    <Accordion.Item value={field.fieldId}>
      <Accordion.Control sx={{ padding: '16px' }}>
        <Flex justify="space-between">
          <Controller
            control={control}
            name={`${fieldName}.${index}.title`}
            rules={{ required: t('general.errors.required') }}
            render={({ field: inputField }) => (
              <TextInput
                placeholder={`${t(translations.fields.title)}*`}
                onClick={e => e.stopPropagation()}
                error={error?.title?.message}
                sx={{ width: '20%' }}
                {...inputField}
              />
            )}
          />
          <Controller
            control={control}
            name={`${fieldName}.${index}.price`}
            rules={{ required: !watchedField.isFree && t('general.errors.required') }}
            render={({ field: inputField }) => (
              <Flex sx={{ width: '20%' }}>
                <NumberInput
                  placeholder={`${t(translations.fields.price)}*`}
                  onClick={e => e.stopPropagation()}
                  rightSection={<IconCurrencyDollar size={16} />}
                  error={error?.price?.message}
                  disabled={!!field.id}
                  hideControls
                  {...inputField}
                />
                <Tooltip label={t('tooltips.prices-price')} />
              </Flex>
            )}
          />
          <Controller
            control={control}
            name={`${fieldName}.${index}.priceCourseLessons`}
            // rules={{ required: t('general.errors.required') }}
            render={({ field: lessonField }) => (
              <MultiSelect
                placeholder={`${t(translations.fields.lessons)}*`}
                data={lessons}
                value={lessonField.value}
                onChange={lessonField.onChange}
                onClick={e => e.stopPropagation()}
                // error={error?.lessons?.message}
                sx={{ width: '55%' }}
                searchable
                {...lessonField}
              />
            )}
          />
        </Flex>
      </Accordion.Control>
      <Accordion.Panel>
        <Divider />

        <Grid sx={{ padding: '8px 0' }}>
          <Grid.Col xs={4}>
            <Controller
              control={control}
              name={`${fieldName}.${index}.isActive`}
              render={({ field: checkboxField }) => (
                <Switch
                  checked={checkboxField.value}
                  onChange={value => checkboxField.onChange(!value)}
                  label={t(translations.fields.active)}
                  disabled={
                    !myBusinessAccount?.isChargesEnabled &&
                    !myBusinessAccount?.isDetailsSubmitted &&
                    !myBusinessAccount?.isPayoutsEnabled &&
                    isSupportStripeConnect
                  }
                  sx={{ height: '24px', marginTop: '7px' }}
                  {...checkboxField}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <Controller
              control={control}
              name={`${fieldName}.${index}.isTrialEnabled`}
              render={({ field: checkboxField }) => (
                <Switch
                  checked={checkboxField.value}
                  onChange={value => checkboxField.onChange(!value)}
                  label={t(translations.fields.trial)}
                  sx={{ height: '24px', marginTop: '7px' }}
                  {...checkboxField}
                />
              )}
            />
          </Grid.Col>

          <Grid.Col xs={4}>
            <Controller
              control={control}
              name={`${fieldName}.${index}.trialDayDuration`}
              render={({ field: inputField }) => (
                <NumberInput
                  placeholder={t(translations.fields.trialDuration)}
                  rightSection={<IconBrandDaysCounter size={16} />}
                  disabled={!watchedField.isTrialEnabled}
                  sx={{ width: '120px' }}
                  hideControls
                  {...inputField}
                />
              )}
            />
          </Grid.Col>
        </Grid>

        <Divider />

        <PricingOptionsList
          control={control}
          pricingIndex={index}
          watchedField={watchedField}
          fieldName={fieldName}
          isDirty={isDirty}
          categoriesToAdd={watchedField.communityCategories}
          handleSubmit={handleSubmit}
          optionFieldsLength={pricingList?.[index]?.listMeta?.length}
          courseId={courseId}
          myBusinessAccount={myBusinessAccount}
          isSupportStripeConnect={isSupportStripeConnect}
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
};
