import { useController, useFormContext } from 'react-hook-form';
import { Rating } from '@mantine/core';

export const FormRating = ({ name, ...otherProps }) => {
  const { control } = useFormContext();

  const { field } = useController({
    name,
    control,
  });

  return <Rating color="green" {...field} {...otherProps} />;
};
