import { useTranslation } from 'react-i18next';
import { FormCourse } from '../../../../ui/FormCourse/FormCourse';
import { useGetCourse, useUpdateCourse } from './hooks';

export const CourseEditor = ({ courseID, toNextStep, ...otherProps }) => {
  const { data } = useGetCourse(courseID);
  const { t } = useTranslation();

  const adaptedData = { ...data, courseMetas: data.courseMetas.filter(item => item.lang === 'ua') };

  const { isLoading, mutate } = useUpdateCourse({
    courseID,
    t,
  });

  return (
    <FormCourse
      loading={isLoading}
      onSubmit={mutate}
      formValues={adaptedData}
      courseId={courseID}
      {...otherProps}
    />
  );
};
