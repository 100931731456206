import { useQuery } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { getCategoriesRequest } from 'common/services';

export const useGetCategories = params => {
  return useQuery({
    queryKey: [API_TAGS.categories, params],
    queryFn: () => getCategoriesRequest(params),
  });
};
