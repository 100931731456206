import { API_LIST } from 'common/constants';
import { destroy, get, patch, post, put } from 'common/helpers';

const path = API_LIST.COURSES.GENERAL;

// Course
export const getCoursesRequest = async (params = { page: 1, limit: 10 }) => {
  const { data } = await get(path, { params });

  return data;
};

export const createCourseRequest = async reqData => {
  const { data } = await post(path, reqData);

  return data;
};

export const createCourseLandingImageRequest = async ({ courseID, file }) => {
  const { data } = await post(`/attachment/lms/images/courses/${courseID}/landing-image`, file, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};

export const createCourseLandingBgImageRequest = async ({ courseID, file }) => {
  const { data } = await post(
    `/attachment/lms/images/courses/${courseID}/landing-backgrounds`,
    file,
    {
      baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
    },
  );

  return data;
};

export const createCourseImageRequest = async ({ courseID, file }) => {
  const { data } = await post(`/attachment/lms/images/courses/${courseID}/general-image`, file, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};

export const deleteCourseByIDRequest = async courseID => {
  const { data } = await destroy(`${path}/${courseID}`);

  return data;
};

export const getCourseRequest = async courseID => {
  const { data } = await get(`${path}/${courseID}`);

  return data;
};

export const updateCourseRequest = async ({ reqData, courseID }) => {
  const { data } = await patch(`${path}/${courseID}`, reqData);

  return data;
};

export const updateCourseMetaRequest = async ({ reqData, courseID }) => {
  const { data } = await patch(`${path}/${courseID}/meta`, reqData);

  return data;
};

export const putCourseMetaRequest = async ({ reqData, courseID }) => {
  const { data } = await put(`${path}/${courseID}/meta`, reqData);

  return data;
};

// Lessons
export const postCourseLessonRequest = async ({ reqData, courseID }) => {
  const { data } = await post(`${path}/${courseID}/lessons`, reqData);

  return data;
};

export const putCourseLessonsRequest = async ({ reqData, courseID }) => {
  const { data } = await put(`${path}/${courseID}/lessons`, reqData);

  return data;
};

export const patchCourseLessonsRequest = async ({ reqData, courseID }) => {
  const { data } = await patch(`${path}/${courseID}/lessons`, reqData);

  return data;
};

export const deleteCourseLessonRequest = async ({ courseID, lessonID }) => {
  const { data } = await destroy(`${path}/${courseID}/lessons/${lessonID}`);

  return data;
};

// Lesson meta
export const putCourseLessonMetaRequest = async ({ reqData, courseID, lessonID }) => {
  const { data } = await put(`${path}/${courseID}/lessons/${lessonID}/meta`, reqData);

  return data;
};

// Lesson content
export const postCourseLessonContentRequest = async ({ reqData, courseID, lessonID }) => {
  const { data } = await post(`${path}/${courseID}/lessons/${lessonID}/lesson-contents`, reqData);

  return data;
};

export const deleteCourseLessonContentRequest = async ({ courseID, lessonID, contentID }) => {
  const { data } = await destroy(
    `${path}/${courseID}/lessons/${lessonID}/lesson-contents/${contentID}`,
  );

  return data;
};

export const patchCourseLessonContentRequest = async ({
  reqData,
  courseID,
  lessonID,
  contentID,
}) => {
  const { data } = await patch(
    `${path}/${courseID}/lessons/${lessonID}/lesson-contents/${contentID}`,
    reqData,
  );

  return data;
};

export const attachCourseLessonContentVideoRequest = async ({
  reqData,
  courseID,
  lessonsID,
  contentID,
}) => {
  const { data } = await post(
    `${path}/${courseID}/lessons/${lessonsID}/lesson-contents/${contentID}/attach-video`,
    reqData,
  );

  return data;
};

export const detachCourseLessonContentVideoRequest = async ({
  reqData,
  courseID,
  lessonsID,
  contentID,
}) => {
  const { data } = await destroy(
    `${path}/${courseID}/lessons/${lessonsID}/lesson-contents/${contentID}/attach-video`,
    reqData,
  );

  return data;
};

// Lesson content meta
export const putCourseLessonContentMetaRequest = async ({
  reqData,
  courseID,
  lessonID,
  contentID,
}) => {
  const { data } = await put(
    `${path}/${courseID}/lessons/${lessonID}/lesson-contents/${contentID}/meta`,
    reqData,
  );

  return data;
};

export const deleteCourseLessonContentMetaRequest = async ({
  courseID,
  lessonID,
  contentID,
  params,
}) => {
  const { data } = await post(
    `${path}/${courseID}/lessons/${lessonID}/lesson-contents/${contentID}/detach-video`,
    params,
  );

  return data;
};

// Lesson timecodes
export const postCourseLessonTimecodeRequest = async ({
  reqData,
  courseID,
  lessonID,
  contentID,
}) => {
  const { data } = await post(
    `${path}/${courseID}/lessons/${lessonID}/lesson-contents/${contentID}/video-timecodes`,
    reqData,
  );

  return data;
};

export const patchCourseLessonTimecodeRequest = async ({
  reqData,
  courseID,
  lessonID,
  contentID,
  timecodeID,
}) => {
  const { data } = await patch(
    `${path}/${courseID}/lessons/${lessonID}/lesson-contents/${contentID}/video-timecodes/${timecodeID}`,
    reqData,
  );

  return data;
};

export const deleteCourseLessonTimecodeRequest = async ({
  courseID,
  lessonsID,
  contentID,
  timecodeID,
}) => {
  const { data } = await destroy(
    `${path}/${courseID}/lessons/${lessonsID}/lesson-contents/${contentID}/video-timecodes/${timecodeID}`,
  );

  return data;
};

export const putCourseLessonTimecodeMetaRequest = async ({
  reqData,
  courseID,
  lessonID,
  contentID,
  timecodeID,
}) => {
  const { data } = await put(
    `${path}/${courseID}/lessons/${lessonID}/lesson-contents/${contentID}/video-timecodes/${timecodeID}/meta`,
    reqData,
  );

  return data;
};

export const patchLessonDelayRequest = async ({ reqData, courseID, lessonID }) => {
  const { data } = await patch(`${path}/${courseID}/lessons/${lessonID}`, reqData);

  return data;
};

export const patchLessonScheduleRequest = async ({ reqData, courseID, lessonID }) => {
  const { data } = await patch(`${path}/${courseID}/lessons/${lessonID}/schedule`, reqData);

  return data;
};

// Course modules
export const getCourseModulesRequest = async ({ courseID, params }) => {
  const { data } = await get(`${path}/${courseID}/modules`, { params });

  return data;
};

export const createCourseModuleRequest = async ({ requestData }) => {
  const { data } = await post(`${path}/${requestData.courseId}/modules`, requestData);

  return data;
};

export const updateCourseModuleRequest = async ({ courseId, moduleId, requestData }) => {
  const { data } = await patch(`${path}/${courseId}/modules/${moduleId}`, requestData);

  return data;
};

export const deleteCourseModuleRequest = async ({ courseId, moduleId }) => {
  const { data } = await destroy(`${path}/${courseId}/modules/${moduleId}`);

  return data;
};
