// Components
import { lazy } from 'react';

import { AuthLayout } from 'common/components/layouts';

// Constants
import { ROUTER_BOOK } from './routerBook';

// Pages
const Login = lazy(() => import('pages/auth/nestedRoutes/login/Login'));
const Registration = lazy(() => import('pages/auth/nestedRoutes/registration/Registration'));
const ForgotPassword = lazy(() => import('pages/auth/nestedRoutes/forgotPassword/ForgotPassword'));
const NewPassword = lazy(() => import('pages/auth/nestedRoutes/newPassword/NewPassword'));

const AcceptInvitation = lazy(() => import('pages/acceptInvitation/AcceptInvitation'));

export const publicRoutes = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: ROUTER_BOOK.public.AUTH.SIGN_IN,
        element: <Login />,
      },
      {
        path: ROUTER_BOOK.public.AUTH.SIGN_UP,
        element: <Registration />,
      },
      {
        path: ROUTER_BOOK.public.AUTH.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: ROUTER_BOOK.public.AUTH.NEW_PASSWORD,
        element: <NewPassword />,
      },
    ],
  },
  {
    element: <AcceptInvitation />,
    path: ROUTER_BOOK.public.INVITATION.ACCEPT_INVITATION,
  },
];
