import { API_LIST } from 'common/constants';
import { destroy, get } from 'common/helpers';

const pathCourses = API_LIST.COURSES.GENERAL;

export const getCommentsRequest = async ({ courseID, lessonID }) => {
  const { data } = await get(`${pathCourses}/${courseID}/lessons/${lessonID}/comments`);

  return data;
};
export const deleteCommentRequest = async ({ courseID, lessonID, commentID }) => {
  const { data } = await destroy(
    `${pathCourses}/${courseID}/lessons/${lessonID}/comments/${commentID}`,
  );

  return data;
};
