import { Accordion } from '@mantine/core';

import { removeHtmlElementsFromString } from 'common/utils/htmlValidation';

import { PricingItem } from './PricingItem';
import { useGetLessons } from '../../../containers/Edit/components/LessonsEditor/hooks';

const adaptLessonsForSelect = lessons => {
  if (lessons) {
    return lessons.map(item => ({
      value: item.lessonID,
      label: removeHtmlElementsFromString(item.lessonMetas[0].title),
    }));
  }

  return [];
};

export const PricingList = ({
  fields,
  fieldName,
  control,
  watch,
  getFieldState,
  handleSubmit,
  errors,
  pricingList,
  courseId,
  myBusinessAccount,
  isSupportStripeConnect,
}) => {
  const { data } = useGetLessons(courseId, 'ua');

  return (
    <Accordion variant="contained">
      {fields.map((field, index) => (
        <PricingItem
          key={field.fieldId}
          field={field}
          index={index}
          fieldName={fieldName}
          control={control}
          watch={watch}
          getFieldState={getFieldState}
          handleSubmit={handleSubmit}
          errors={errors}
          pricingList={pricingList}
          courseId={courseId}
          lessons={adaptLessonsForSelect(data?.lessons)}
          myBusinessAccount={myBusinessAccount}
          isSupportStripeConnect={isSupportStripeConnect}
        />
      ))}
    </Accordion>
  );
};
