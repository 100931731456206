import { adaptMetaAPIDataForForm } from 'common/utils';

export const adaptLocaleFormDataForAPI = ({ meta, ...rest }) => {
  const adaptedFormValues = {
    ...rest,
    meta: [],
  };

  meta.forEach(item => {
    const lang = item.lang.toLowerCase();

    if (item.title) {
      adaptedFormValues.meta.push({
        lang,
        property: 'title',
        value: item.title,
      });
    }

    if (item.description) {
      adaptedFormValues.meta.push({
        lang,
        property: 'description',
        value: item.description,
      });
    }
  });

  return adaptedFormValues;
};

export const adaptLocaleAPIDataForForm = localeAPIData => ({
  parameterId: localeAPIData?.parameter.id,
  priority: localeAPIData?.priority,
  meta: adaptMetaAPIDataForForm(localeAPIData.meta),
});
