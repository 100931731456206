import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Accordion, Button, Group } from '@mantine/core';

import { Loader } from 'common/components/ui';
import { createLessonItemWithEmptyMeta } from 'pages/course/components/ui/FormLessons/FormLessons.constants';
import { StepFooter } from 'pages/course/components/ui/StepFooter/StepFooter';

import { useTranslation } from 'react-i18next';
import { DragDrop } from './components/DragDrop';
import {
  useGetLessons,
  useRemoveLesson,
  useCreateLesson,
  useRemoveTimecode,
  useRemoveContentMeta,
  useRemoveLessonContent,
} from './hooks';

import { useUpdateLesson } from './hooks/useUpdateLesson';
import { useUpdateLessons } from './hooks/useUpdateLessons';

import translations from './translations';

export const LessonsEditor = ({ courseID, toNextStep, toPrevStep, activeStep }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  const {
    isLoading: isGetLessons,
    data: { lessons },
  } = useGetLessons(courseID, 'ua');

  const { mutate: updateLesson } = useUpdateLesson({
    courseID,
    t,
  });

  const { mutate: updateLessons } = useUpdateLessons({
    courseID,
    t,
  });

  const { mutate: createLesson } = useCreateLesson({
    courseID,
    t,
  });

  const { mutate: removeLesson } = useRemoveLesson({
    courseID,
    t,
  });

  const { mutate: removeTimecode } = useRemoveTimecode({
    courseID,
    t,
  });

  const { mutate: removeContentMeta } = useRemoveContentMeta({
    courseID,
    t,
  });

  const { mutate: removeLessonContent } = useRemoveLessonContent({
    courseID,
    t,
  });

  const addNewLesson = () => {
    createLesson({ ...createLessonItemWithEmptyMeta(), position: lessons.length + 1 });
  };

  const {
    control,
    handleSubmit,
    setValue: updatePosition,
    formState: { isDirty },
  } = useForm({
    values: { lessons },
    defaultValues: { lessons: [] },
  });

  const onSubmit = formValues => {
    const reqData = { lessons: formValues.lessons };
    updateLessons(reqData);
  };

  if (isGetLessons) {
    return <Loader />;
  }

  return (
    <>
      <Accordion variant="contained" transitionDuration={0} multiple={false} onChange={setValue}>
        <DragDrop
          value={value}
          control={control}
          updateLesson={updateLesson}
          removeLesson={removeLesson}
          updateLessons={updateLessons}
          onUpdatePosition={updatePosition}
          removeLessonContent={removeLessonContent}
          removeContentMeta={removeContentMeta}
          removeTimecode={removeTimecode}
        />
      </Accordion>

      <Group position="apart">
        <Button variant="filled" onClick={addNewLesson}>
          {t(translations.buttons.newLesson)}
        </Button>

        {isDirty && (
          <Button variant="filled" color="blue" onClick={handleSubmit(onSubmit)}>
            {t(translations.buttons.saveLessonsOrder)}
          </Button>
        )}
      </Group>

      <StepFooter onBackClick={toPrevStep} onForwardClick={toNextStep} activeStep={activeStep} />
    </>
  );
};
