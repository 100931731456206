import { useQuery } from '@tanstack/react-query';

import { API_TAGS } from 'common/constants';
import { getMyBusinessAccountRequest } from 'common/services';

import { adaptMyBusinessAccountDataForForm } from 'common/utils/businessAccount';

export const useGetMyBusinessAccount = businessAccountId => {
  return useQuery({
    queryKey: [API_TAGS.businessAccount],
    queryFn: () => getMyBusinessAccountRequest(),
    select: ({ businessAccount, country }) => ({
      businessAccount: adaptMyBusinessAccountDataForForm(businessAccount),
      country,
    }),
    enabled: !!businessAccountId,
    cacheTime: 0,
  });
};
