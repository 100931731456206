import { Text } from '@mantine/core';

import { Tooltip } from '../Tooltip/Tooltip';

export const LabelWithTooltip = ({ text, tooltipText }) => (
  <Text sx={{ display: 'flex', alignItems: 'center', marginRight: '2px' }}>
    <span>{text}</span>
    <Tooltip label={tooltipText} />
  </Text>
);
