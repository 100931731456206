import { useTranslation } from 'react-i18next';

export const useLangForDatePicker = () => {
  const { i18n } = useTranslation();

  if (i18n.language === 'ua') {
    return 'uk';
  }

  return i18n.language;
};
