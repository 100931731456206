import { DataTable } from 'mantine-datatable';
import { useTranslation } from 'react-i18next';

import translations from './translations';

export const Table = ({ columns, records = [], ...otherProps }) => {
  const { t } = useTranslation();

  return (
    <DataTable
      shadow="sm"
      highlightOnHover
      verticalSpacing="sm"
      minHeight={250}
      columns={columns}
      records={records}
      recordsPerPage={10}
      {...otherProps}
      loaderColor="#B17CE1"
      noRecordsText={t(translations.noRecords)}
      style={{ backgroundColor: 'transparent', height: '60vh' }}
      // styles={{ header: { background: 'red' } }}
      rowStyle={{ color: '#c8c8c8', backgroundColor: 'transparent' }}
    />
  );
};
