import { Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Loader } from 'common/components/ui';

import { useGetCourseModules } from './hooks';
import translations from './translations';
import { ModulesForm } from '../../../../ui/ModulesForm/ModulesForm';

export const ModulesEditor = ({ courseID }) => {
  const { t } = useTranslation();

  // const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isFetching } = useGetCourseModules({
    courseID,
    params: { page: 1, limit: 100 },
  });

  // const totalPagesCount = data && Math.ceil(data.pagination.count / data.pagination.limit);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Text size={14} color="#C1C2C5" sx={{ marginBottom: '8px' }}>
        {t(translations.title)}
      </Text>
      <ModulesForm courseID={courseID} modules={data.modules} />
      {/* <Pagination page={currentPage} onChange={setCurrentPage} total={totalPagesCount} /> */}
    </>
  );
};
