import { useMemo } from 'react';

import { isDef } from 'common/utils';
import { useSearchParams } from './useSearchParams';

export const getCurrentPage = page => {
  const parsedPage = parseInt(page);

  if (Number.isNaN(parsedPage)) {
    return 1;
  }

  return parsedPage ?? 1;
};

export const useTableParams = (params = { page: 1 }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    if (!params) return null;

    const keys = Object.keys(params).reduce((acc, curr) => {
      const key = searchParams.get(curr);

      if (isDef(key)) {
        acc[curr] = searchParams.get(curr);
      }

      return acc;
    }, {});

    return { ...params, ...keys };
  }, [searchParams, params]);

  return [queryParams, setSearchParams];
};
