const translations = {
  fields: {
    title: 'features.modules.fields.title',
    description: 'features.modules.fields.description',
    lessons: 'features.modules.fields.lessons',
    unusedLessons: 'features.modules.fields.unused-lessons',
  },
  buttons: {
    addPrice: 'features.modules.buttons.add-price',
    save: 'features.modules.buttons.save',
  },
  modals: {
    delete: {
      title: 'features.benefits.modals.delete.title',
      caption: 'features.benefits.modals.delete.caption',
      confirm: 'features.benefits.modals.delete.buttons.confirm',
      cancel: 'features.benefits.modals.delete.buttons.cancel',
    },
  },
};

export default translations;
