export const API_LIST = Object.freeze({
  AUTH: {
    SIGN_IN: '/auth/sign-in',
    VERIFY: '/auth/verify',
    LOGOUT: '/auth/logout',
    SIGN_IN_APPLE: '/auth/sign-in-with-apple',
    SIGN_IN_GOOGLE: '/auth/sign-in-with-google',
    SIGN_UP: '/auth/sign-up',
    FORGOT_PASSWORD_FIRST_STEP: '/auth/forgot-password/first-step',
    FORGOT_PASSWORD_SECOND_STEP: '/auth/forgot-password/second-step',
  },
  COURSES: {
    GENERAL: '/courses',
    LESSONS: '/lessons',
    COMMENTS: '/comments',
  },
  NOTIFICATIONS: {
    NOTIFICATIONS: '/notifications',
    TEMPLATES: '/notifications/parameter',
  },
  USERS: {
    GENERAL: '/users',
    ME: '/users/me',
  },
  GROUP: {
    GENERAL: '/group',
  },
  LOCALES: {
    GENERAL: '/locales',
  },
  PRICES: {
    COURSE: {
      GENERAL: '/price/course',
    },
    COMMUNITY: {
      GENERAL: '/price/community',
    },
  },
  HOMEWORK: {
    GENERAL: '/homework',
  },
  DASHBOARD: {
    GENERAL: '/dashboard',
    BLOCKS: '/dashboard/blocks',
  },
  STREAMS: {
    GENERAL: '/streams',
  },
  PAYMENTS: {
    GENERAL: '/payments',
  },
  EVENTS: {
    GENERAL: '/calendar',
  },
  BUSINESS_ACCOUNT: {
    GENERAL: '/business-account',
  },
  CATEGORIES: {
    GENERAL: '/categories',
  },
  BENEFITS: {
    GENERAL: '/benefits',
  },
  ADMIN_INVITATION: {
    GENERAL: '/admin-invitation',
  },
  STRIPE: {
    GENERAL: '/stripe',
  },
});
