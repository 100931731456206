import { Accordion, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { removeHtmlElementsFromString } from 'common/utils/htmlValidation';

import { ModulesItem } from './ModulesItem';
import translations from '../translations';
import { useGetLessons } from '../../../containers/Edit/components/LessonsEditor/hooks';

const adaptLessonsForSelect = lessons => {
  if (lessons) {
    return lessons.map(item => ({
      value: item.lessonID,
      label: removeHtmlElementsFromString(item.lessonMetas[0].title),
      moduleId: item.moduleId,
    }));
  }

  return [];
};

export const ModulesList = ({
  fields,
  control,
  watch,
  errors,
  getFieldState,
  handleSubmit,
  courseID,
  fieldName,
  removeField,
}) => {
  const { t } = useTranslation();

  const { data } = useGetLessons(courseID, 'ua');

  const lessons = adaptLessonsForSelect(data?.lessons);

  const unusedLessonsLength = lessons.filter(lesson => !lesson.moduleId).length;

  return (
    <>
      {!!unusedLessonsLength && (
        <Text size={12} color="#e03131">
          {t(translations.fields.unusedLessons)}: {unusedLessonsLength}
        </Text>
      )}
      <Accordion variant="contained">
        {fields.map((field, index) => (
          <ModulesItem
            key={field.fieldId}
            field={field}
            index={index}
            control={control}
            watch={watch}
            errors={errors}
            getFieldState={getFieldState}
            handleSubmit={handleSubmit}
            fieldName={fieldName}
            lessons={lessons}
            courseId={courseID}
            removeField={removeField}
          />
        ))}
      </Accordion>
    </>
  );
};
