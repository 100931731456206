import { useState } from 'react';
import { createStyles, Group, Image, Menu, UnstyledButton } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { languages } from './LanguagePicker.config';
import { getLanguage } from '../../../utils/language';

const useStyles = createStyles((theme, { opened }) => ({
  control: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 10px',
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      // eslint-disable-next-line no-nested-ternary
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}));

// const getDefaultLanguage = () => {
//  const storedLanguage = localStorage.getItem('language')
//   const defaultLanguage = storedLanguage || 'en'
//
// }

function LanguagePicker(props) {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  const [selected, setSelected] = useState(getLanguage());
  const { t, i18n } = useTranslation();

  // Function to handle language change
  const handleLanguageChange = language => {
    i18n.changeLanguage(language).then(() => {
      localStorage.setItem('language', language);
      setOpened(false);
      setSelected(getLanguage());
    });
  };

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      size="md"
      width="target"
      withinPortal
      {...props}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            <Image src={selected.image} radius={50} width={22} height={22} />
            <span className={classes.label}>{t(selected.label)}</span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <>
          {languages.map(item => (
            <Menu.Item
              icon={<Image radius={50} src={item.image} width={18} height={18} />}
              onClick={() => handleLanguageChange(item.label.split('.')[2])} // Extract the language code from the label
              key={t(item.label)}
            >
              {t(item.label)}
            </Menu.Item>
          ))}
        </>
      </Menu.Dropdown>
    </Menu>
  );
}

export default LanguagePicker;
