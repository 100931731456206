import { Button, Flex } from '@mantine/core';
import { useForm, useFieldArray } from 'react-hook-form';
import { IconPlus } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { ModulesList } from './components/ModulesList';
import translations from './translations';

export const ModulesForm = ({ modules, courseID }) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    getFieldState,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      modules,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'modules',
    keyName: 'fieldId',
  });

  const lastField = fields.at(-1);
  const lastPosition = lastField?.position ?? 0;

  const addModuleFieldHandler = () => {
    append({
      title: '',
      description: '',
      position: lastPosition + 1,
    });
  };

  const newFieldsLength = fields.filter(field => !('id' in field)).length;

  return (
    <form>
      <ModulesList
        fields={fields}
        control={control}
        watch={watch}
        errors={errors}
        getFieldState={getFieldState}
        handleSubmit={handleSubmit}
        courseID={courseID}
        fieldName="modules"
        removeField={remove}
      />

      <Flex justify="flex-start" sx={{ marginTop: '16px', padding: '0 16px' }}>
        <Button
          variant="outline"
          leftIcon={<IconPlus size={18} />}
          onClick={addModuleFieldHandler}
          sx={{ border: 'none' }}
          disabled={newFieldsLength === 1}
        >
          {t(translations.buttons.addPrice)}
        </Button>
      </Flex>
    </form>
  );
};
