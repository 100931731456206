const translations = {
  fields: {
    search: {
      label: 'pages.groups.fields.search.label',
      placeholder: 'pages.groups.fields.search.placeholder',
    },
    course: 'pages.create-homework.fields.course',
    selectCourse: 'pages.create-homework.fields.select-course',
    create: 'pages.create-homework.buttons.create',
  },
  children: {
    edit: {
      fields: {
        search: {
          label: 'pages.groups.children.edit.fields.search.label',
          placeholder: 'pages.groups.children.edit.fields.search.placeholder',
        },
        groupId: {
          label: 'pages.groups.children.edit.fields.group-id.label',
          placeholder: 'pages.groups.children.edit.fields.group-id.placeholder',
        },
        courseId: {
          label: 'pages.groups.children.edit.fields.course-id.label',
          placeholder: 'pages.groups.children.edit.fields.course-id.placeholder',
        },
        courseName: {
          label: 'pages.groups.children.edit.fields.course-name.label',
          placeholder: 'pages.groups.children.edit.fields.course-name.placeholder',
        },
        startDate: {
          label: 'pages.groups.children.edit.fields.start-date.label',
          placeholder: 'pages.groups.children.edit.fields.start-date.placeholder',
        },
        endDate: {
          label: 'pages.groups.children.edit.fields.end-date.label',
          placeholder: 'pages.groups.children.edit.fields.end-date.placeholder',
        },
        title: {
          label: 'pages.groups.children.edit.fields.title.label',
          placeholder: 'pages.groups.children.edit.fields.title.placeholder',
        },
        pickDate: {
          label: 'pages.groups.children.edit.fields.pick-date.label',
          placeholder: 'pages.groups.children.edit.fields.pick-date.placeholder',
        },
        pickLesson: {
          label: 'pages.groups.children.edit.fields.pick-lesson.label',
          placeholder: 'pages.groups.children.edit.fields.pick-lesson.placeholder',
        },
        price: {
          label: 'pages.groups.children.edit.fields.price.label',
          placeholder: 'pages.groups.children.edit.fields.price.placeholder',
        },
        lessonSchedule: 'pages.groups.children.edit.fields.lesson-schedule',
      },
      buttons: {
        updateLesson: 'pages.groups.children.edit.buttons.update-lesson',
      },
      tabs: {
        users: 'pages.groups.children.edit.tabs.users',
        group: 'pages.groups.children.edit.tabs.group',
      },
      usersList: {
        fields: {
          id: {
            label: 'pages.groups.children.edit.users-list.fields.id.label',
            placeholder: 'pages.groups.children.edit.users-list.fields.id.placeholder',
          },
          name: {
            label: 'pages.groups.children.edit.users-list.fields.name.label',
            placeholder: 'pages.groups.children.edit.users-list.fields.name.placeholder',
          },
          email: {
            label: 'pages.groups.children.edit.users-list.fields.email.label',
            placeholder: 'pages.groups.children.edit.users-list.fields.email.placeholder',
          },
        },
        user: 'pages.groups.children.edit.users-list.user',
        phone: 'pages.groups.children.edit.users-list.phone',
        phoneNotFound: 'pages.groups.children.edit.users-list.phone-not-found',
        modal: {
          title: 'pages.groups.children.edit.users-list.modal.title',
          caption: 'pages.groups.children.edit.users-list.modal.caption',
          buttons: {
            cancel: 'pages.groups.children.edit.users-list.modal.buttons.cancel',
            confirm: 'pages.groups.children.edit.users-list.modal.buttons.confirm',
          },
        },
      },
    },
  },
};

export default translations;
