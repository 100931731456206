import { API_LIST } from 'common/constants';
import { GROUP_FILTERS } from 'common/enums';
import { get, post, destroy, put } from 'common/helpers';

const path = API_LIST.GROUP.GENERAL;

export const getGroupsRequest = async (
  params = { page: 1, limit: 10, lang: 'ua', groupFilter: GROUP_FILTERS.ALL },
) => {
  const { data } = await get(path, { params });

  return data;
};

export const createGroupRequest = async ({ requestData }) => {
  const { data } = await post(path, requestData);

  return data;
};

export const getGroupByIdRequest = async groupID => {
  const { data } = await get(`${path}/${groupID}?lang=ua`);

  return data;
};

export const updateGroupRequest = async ({ groupId, requestData }) => {
  const { data } = await put(`${path}/${groupId}`, requestData);

  return data;
};

export const addUserToGroupRequest = async ({ groupId, userId }) => {
  const { data } = await post(`${path}/${groupId}/users`, { userId });

  return data;
};

export const removeUserFromGroupRequest = async ({ groupId, userId }) => {
  const { data } = await destroy(`${path}/${groupId}/users`, { data: { userId } });

  return data;
};
