export const BLOCK_TYPES_ENUM = Object.freeze({
  HORIZONTAL: 'horizontal',
  GRID: 'grid',
});

export const BLOCK_CONTENT_TYPES_ENUM = Object.freeze({
  ALL: 'all',
  BANNER: 'banner',
  POPULAR: 'popular',
  LAST_VIEWED: 'lastViewed',
  YOUR_CHOICE: 'yourChoice',
});

export const BANNER_META_PROPERTY_ENUM = Object.freeze({
  TITLE: 'title',
  DESCRIPTION: 'description',
  BUTTON_TEXT: 'buttonText',
  BUTTON_LINK: 'buttonLink',
});
